import React from 'react';
import { useTranslation } from 'react-i18next';

import _1pyeong from '../../assets/img/team/1pyeong.png';
import bin from '../../assets/img/team/bin.webp';
import jumi from '../../assets/img/team/jumi.webp';
import _2pa from '../../assets/img/team/2pa.webp';
import spear from '../../assets/img/team/spear.webp';

export default function Teams() {
  const { t } = useTranslation();
  return (
    <section className="team-member-section ptb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-8">
            <div className="section-heading text-center mb-4">
              <h2>{t('우리 팀을 소개합니다!')}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
              <div className="team-image">
                <img src={_1pyeong} alt="Team Members" className="img-fluid rounded" />
              </div>
              <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                <h5 className="mb-0">1Pyeong</h5>
                <h6>CTO</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
              <div className="team-image">
                <img src={spear} alt="Team Members" className="img-fluid rounded" />
              </div>
              <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                <h5 className="mb-0">Spear</h5>
                <h6>Back-End Leader</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
              <div className="team-image">
                <img src={bin} alt="Team Members" className="img-fluid rounded" />
              </div>
              <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                <h5 className="mb-0">Bean</h5>
                <h6>Front-End Leader</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
              <div className="team-image">
                <img src={_2pa} alt="Team Members" className="img-fluid rounded" />
              </div>
              <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                <h5 className="mb-0">PaPa</h5>
                <h6>Front-End & iOS Enginear</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
              <div className="team-image">
                <img src={jumi} alt="Team Members" className="img-fluid rounded" />
              </div>
              <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                <h5 className="mb-0">Sojumi</h5>
                <h6>Front-End Enginear</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
