import i18next from 'i18next';
import { useCookies } from 'react-cookie';
import { create } from 'zustand';

export const languageStore = create((set, get) => ({
  state: false,
  lang: 'ko',
  list: [
    { lang: 'ko', mark: '🇰🇷 한국어', selected: false },
    { lang: 'en', mark: '🇺🇸 English', selected: false },
    // { lang: "ja", mark: "🇯🇵 日本語", selected: false },
    // { lang: "cn", mark: "🇨🇳 简体", selected: false },
    // { lang: "tw", mark: "🇹🇼 繁體", selected: false },
    // { lang: "id", mark: "🇮🇩 bahasa Indonesia", selected: false },
    // { lang: "fr", mark: "🇫🇷 Français", selected: false },
    // { lang: "it", mark: "🇮🇹 Italiano", selected: false },
    // { lang: "es", mark: "🇪🇸 español", selected: false },
    // { lang: "de", mark: "🇩🇪 Deutsch", selected: false },
    // { lang: "ms", mark: "🇲🇾 Melayu", selected: false },
    // { lang: "ru", mark: "🇷🇺 Русский", selected: false },
    // { lang: "vi", mark: "🇻🇳 Tiếng Việt", selected: false },
    // { lang: "th", mark: "🇹🇭 ภาษาไทย", selected: false },
  ],
  setModal: state => set({ state, list: get().list }),
  setLang: lang =>
    set({
      state: get().state,
      lang: lang,
      list: get().list.map(item => (item.lang === lang ? { ...item, selected: true } : { ...item, selected: false })),
    }),
}));

export function useLanguage() {
  const [, setCookie] = useCookies(['xclass-lang']);
  const { setLang } = languageStore();

  const changeLanguage = e => {
    setCookie('xclass-lang', e, {
      domain: process.env.NODE_ENV === 'development' ? '.xcast.co.kr' : 'xclass.co.kr',
      path: '/',
    });
    i18next.changeLanguage(e || 'en');
    setLang(e);
  };

  return { changeLanguage };
}
