/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-else-return */
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

import { useSubscribeInfo, subscribeInfoStore } from '../../stores/mypage/subscribeInfoStore';
import { useLogout } from '../../stores/user/loginStore';

import NoSubscribe from './NoSubscribe';

import styles from '../../assets/scss/mypage/amountused.module.scss';

function AmountUsed() {
  const { t } = useTranslation();
  const { trafficState, trafficInfo, setTrafficInfo } = subscribeInfoStore();

  const { getTrafficInfo } = useSubscribeInfo();
  const { logout } = useLogout();
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);

  const KBToGB = v => {
    return v / 1024 / 1024;
  };

  const percentFormat = v => {
    // .00이 포함되어있으면 뒤에 소숫점 날려버림
    return String(v).replace('.00', '');
  };

  useEffect(() => {
    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }

    getTrafficInfo(dayjs(new Date()).format('YYYYMM'));

    // eslint-disable-next-line consistent-return
    return () => {
      setTrafficInfo(null);
    };
  }, []);

  return (
    <>
      {trafficInfo ? (
        <div className={styles.container}>
          <div className={styles.head}>
            <div className={styles.wrapper}>
              <div className={styles.title}>{t('사용량 조회')}</div>
            </div>
          </div>
          <div className={styles.contentsContainer}>
            <div className={styles.contents}>
              <div className={styles.items}>
                <div className={styles.itemContainer}>
                  <div className={styles.item}>
                    <div className={styles.name}>{t('저장공간')}</div>
                    <div className={styles.chart}>
                      <div className={styles.bar}>
                        <div
                          className={styles.realbar}
                          style={{
                            width: `${
                              trafficInfo.storage.storageLimit === 0
                                ? 0
                                : // 계산 값이 100보다 작거나 같으면 계산값 , 100보다 크면 100으로 고정
                                (KBToGB(trafficInfo.storage.storage) / trafficInfo.storage.storageLimit) * 100 <= 100
                                ? (KBToGB(trafficInfo.storage.storage) / trafficInfo.storage.storageLimit) * 100
                                : 100
                            }%`,
                            backgroundColor: ['#aa96da'],
                          }}
                        ></div>
                      </div>
                      <div className={styles.labels}>
                        <div className={styles.labelContainer}>
                          <div className={styles.label} style={{ backgroundColor: '#aa96da' }}></div>
                          {t('저장공간')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.percentage}>
                      {percentFormat(KBToGB(trafficInfo.storage.storage).toFixed(2))}GB /{' '}
                      {trafficInfo.storage.storageLimit}GB (
                      {trafficInfo.storage.storage === 0 || trafficInfo.storage.storageLimit === 0
                        ? 0
                        : ((KBToGB(trafficInfo.storage.storage) / trafficInfo.storage.storageLimit) * 100).toFixed(2) <=
                          100
                        ? percentFormat(
                            ((KBToGB(trafficInfo.storage.storage) / trafficInfo.storage.storageLimit) * 100).toFixed(2),
                          )
                        : 100}
                      %)
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.items}>
                <div className={styles.itemContainer}>
                  <div className={styles.item}>
                    <div className={styles.name}>{t('트래픽')}</div>
                    <div className={styles.chart}>
                      <div className={styles.bar}>
                        <div
                          className={styles.realbar_traffic}
                          style={{
                            width:
                              `${
                                trafficInfo.traffic.trafficLimit === 0
                                  ? 0
                                  : // 계산 값이 100보다 작거나 같으면 계산값 , 100보다 크면 100으로 고정
                                  (KBToGB(trafficInfo.traffic.traffic.live) / trafficInfo.traffic.trafficLimit) * 100 <=
                                    100
                                  ? (KBToGB(trafficInfo.traffic.traffic.live) / trafficInfo.traffic.trafficLimit) * 100
                                  : 100
                              }%` || 0,
                            backgroundColor: '#98da96',
                          }}
                        ></div>
                        <div
                          className={styles.realbar_traffic}
                          style={{
                            width:
                              `${
                                trafficInfo.traffic.trafficLimit === 0
                                  ? 0
                                  : // 계산 값이 100보다 작거나 같으면 계산값 , 100보다 크면 100으로 고정
                                  (KBToGB(trafficInfo.traffic.traffic.meet) / trafficInfo.traffic.trafficLimit) * 100 <=
                                    100
                                  ? (KBToGB(trafficInfo.traffic.traffic.meet) / trafficInfo.traffic.trafficLimit) * 100
                                  : 100
                              }%` || 0,
                            backgroundColor: '#B8E8FC',
                          }}
                        ></div>
                        <div
                          className={styles.realbar_traffic}
                          style={{
                            width:
                              `${
                                trafficInfo.traffic.trafficLimit === 0
                                  ? 0
                                  : // 계산 값이 100보다 작거나 같으면 계산값 , 100보다 크면 100으로 고정
                                  (KBToGB(trafficInfo.traffic.traffic.vod) / trafficInfo.traffic.trafficLimit) * 100 <=
                                    100
                                  ? (KBToGB(trafficInfo.traffic.traffic.vod) / trafficInfo.traffic.trafficLimit) * 100
                                  : 100
                              }%` || 0,
                            backgroundColor: '#fff1a9',
                          }}
                        ></div>
                      </div>
                      <div className={styles.labels}>
                        <div className={styles.labelContainer}>
                          <div className={styles.label} style={{ backgroundColor: '#98da96' }}></div>
                          {t('라이브')}
                        </div>
                        <div className={styles.labelContainer}>
                          <div className={styles.label} style={{ backgroundColor: '#B8E8FC' }}></div>
                          {t('회의')}
                        </div>
                        <div className={styles.labelContainer}>
                          <div className={styles.label} style={{ backgroundColor: '#fff1a9' }}></div>
                          {t('VOD')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.percentage}>
                      {percentFormat(KBToGB(trafficInfo.traffic.traffic.total).toFixed(2))}GB /{' '}
                      {trafficInfo.traffic.trafficLimit}GB (
                      {trafficInfo.traffic.traffic.total === 0 || trafficInfo.traffic.trafficLimit === 0
                        ? 0
                        : (
                            (KBToGB(trafficInfo.traffic.traffic.total) / trafficInfo.traffic.trafficLimit) *
                            100
                          ).toFixed(2) <= 100
                        ? percentFormat(
                            (
                              (KBToGB(trafficInfo.traffic.traffic.total) / trafficInfo.traffic.trafficLimit) *
                              100
                            ).toFixed(2),
                          )
                        : 100}
                      %)
                    </div>
                  </div>
                  <div className={styles.vodInfo}>
                    <div>※ {t('트래픽 사용량은 매월 1일에 초기화됩니다.')}</div>{' '}
                    <div>
                      ※{' '}
                      {t(
                        'VOD의 경우 라이브 다시보기 및 러닝톡톡, 일반 VOD 사용량이 모두 포함되어 있습니다. 사용량 조회 시 참고해 주세요.',
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : /* {data && (
        <div className={styles.etc}>
          <div className={styles.text}>저장공간을 80%이상 사용하셨네요!</div>
          <Link to="/pay">
            <div>지금 바로 저장공간을 추가구매해보세요!</div>
          </Link>
        </div>
      )} */
      trafficState === 'code' ? (
        <NoSubscribe
          title={t('기업 정보 등록이 필요합니다!')}
          subtitle={t('기업 정보를 등록하시려면?')}
          link="/mypage/account"
        />
      ) : (
        <NoSubscribe title={t('구독 정보가 없습니다.')} subtitle={t('엑스클래스를 구독하시려면?')} link="/pay" />
      )}
    </>
  );
}

export default AmountUsed;
