/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';

import { useSubscribeInfo, subscribeInfoStore } from '../../../stores/mypage/subscribeInfoStore';
import { planStore, usePay } from '../../../stores/mypage/planStore';
import { alertStore } from '../../../stores/modal/alertStore';
import { useLogout } from '../../../stores/user/loginStore';
import { useCard } from '../../../stores/pay/cardStore';

import NoSubscribe from '../NoSubscribe';
import Contents from './Contents';
import Sidebar from './Sidebar';

import styles from '../../../assets/scss/mypage/plan.module.scss';

export default function Pay() {
  const { subscribeInfo } = subscribeInfoStore();
  const { getSubscribeInfo } = useSubscribeInfo();
  const { initSubscribeInfo, reset } = usePay();
  const { getCardInfo } = useCard();
  const { tab, setTab } = planStore();
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);
  const { logout } = useLogout();
  const getPriceCheck = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      await getSubscribeInfo();
      await getCardInfo();
    };

    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    setTab('now');
    fetchData();

    // eslint-disable-next-line consistent-return
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (getPriceCheck.current) return;

    if (subscribeInfo) {
      getPriceCheck.current = true;
      initSubscribeInfo();
    }
  }, [subscribeInfo]);

  return (
    <div className={styles.container}>
      {subscribeInfo ? (
        <>
          <div className={styles.tab}>
            <div
              className={classNames(styles.inputs, tab === 'now' && styles.active)}
              onClick={() => {
                setTab('now');
                initSubscribeInfo('now');
              }}
            >
              {t('플랜 업그레이드')}
            </div>
            <div
              className={classNames(styles.inputs, tab === 'next' && styles.active)}
              onClick={() => {
                setTab('next');
                initSubscribeInfo('next');
              }}
            >
              {t('플랜 변경')}
            </div>
          </div>
          <div className={styles.wrapper}>
            <Contents />
            <Sidebar />
          </div>
        </>
      ) : (
        <NoSubscribe title={t('구독 정보가 없습니다.')} subtitle={t('엑스클래스를 구독하시려면?')} link="/pay" />
      )}
    </div>
  );
}
