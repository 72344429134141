import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/layout/footer/Footer';
import Layout from '../components/layout/Layout';
import PageHeader from '../components/team/PageHeader';
import EmailRefuseConponent from '../components/email';
function EmailRefuse() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('이메일무단수집 거부')} Parent="Pages" PageTitle="Email Refuse" />
      <EmailRefuseConponent />
      <Footer space />
    </Layout>
  );
}

export default EmailRefuse;
