import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { agreeStore } from '../stores/modal/agreeStore';

// components
import Modal from './modal';

// style
import './Agreement.scss';

function Agreement({ children }) {
  const { state, agreeClose } = agreeStore();

  return (
    <AnimatePresence>
      {state && (
        <Modal close={() => agreeClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            // className={classNames(styles.modal, styles.lang)}
          >
            <div className="agreement">
              <div className="message">{children}</div>
              <div className="btns">
                <button className="btn primary" onClick={() => agreeClose()}>
                  확인
                </button>
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Agreement;
