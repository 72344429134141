import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { confirmStore } from '../../stores/modal/confirmStore';
import { alertStore } from '../../stores/modal/alertStore';
import { useWithdrawal } from '../../stores/mypage/withdrawalStore';

import styles from '../../assets/scss/mypage/withdrawal.module.scss';

function Withdrawal() {
  const { t } = useTranslation();
  const { confirmShow } = confirmStore();
  const { alertShow } = alertStore();
  const { onWithdrawal } = useWithdrawal();

  const [password, setPassword] = useState('');
  const changePassword = e => {
    setPassword(e.target.value);
  };
  // 동의
  const [agree, setAgree] = useState(false);

  const onClickWithdrawal = () => {
    if (password.length === 0) {
      alertShow(t('비밀번호를 입력해주세요.'), null);
      return;
    }
    if (!agree) {
      alertShow(t('주의사항을 확인해주세요.'), null);
      return;
    }
    confirmShow(t('회원 탈퇴 시, 구독정보가 모두 사라집니다.\n정말 탈퇴하시겠습니까?'), () => onWithdrawal(password));
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{t('회원 탈퇴')}</span>
      </div>
      <form>
        <div className={styles.form}>
          <div className={styles.item}>
            <div className={styles.subject}>
              <span>{t('비밀번호')}</span>
            </div>
            <div className={styles.input}>
              <input
                type="password"
                placeholder={t('비밀번호 입력')}
                className={styles.responsive}
                value={password}
                onChange={changePassword}
                autoComplete="off"
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.warning} onClick={() => setAgree(!agree)}>
              <div className={classNames(styles.checkbox, agree && styles.selected)}></div>
              <div className={styles.text}>
                <span>{t('삭제된 데이터는 복구되지 않습니다. 필요한 데이터는 미리 백업을 해주세요.')}</span>
              </div>
            </div>
          </div>
          <div className={styles.submit}>
            <div onClick={onClickWithdrawal}>{t('탈퇴하기')}</div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default Withdrawal;
