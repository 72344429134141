import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { confirmStore } from '../stores/modal/confirmStore';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

import icConfirm from '../assets/img/icons/ic_confirm.svg';

function Confirm() {
  const { t } = useTranslation();
  const { state, confirmClose, message, confirmCheck, whiteSpace } = confirmStore();

  return (
    <AnimatePresence>
      {state && (
        <Modal close={() => confirmClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={classNames(styles.modal, styles.lang)}
          >
            <div className={styles.circle}>
              <img src={icConfirm} alt="img" />
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div
              className={classNames(styles.message, whiteSpace === 'unset' && styles.whiteSpaceUnset)}
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
            <div className={styles.btns}>
              <div className={styles.btn} onClick={() => confirmCheck()}>
                {t('확인')}
              </div>
              <div className={styles.btn} onClick={() => confirmClose()}>
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Confirm;
