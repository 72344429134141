/* eslint-disable no-useless-catch */
/* eslint-disable no-else-return */
import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { confirmStore } from '../modal/confirmStore';
import { alertStore } from '../modal/alertStore';
import { loadingStore } from '../loadingStore';
import { subscribeInfoStore } from './subscribeInfoStore';

import CookieManager from '../../assets/js/CookieManager';
import { useLogout } from '../user/loginStore';

const subscribeApi = process.env.REACT_APP_SUBSCRIBE_API;

export const planStore = create((set, get) => ({
  base: null, // 기본 요금
  setBase: v => {
    set({
      base: v,
    });
  },

  tab: 'now', // now(즉시) or next(다음결제일부터)
  setTab: v => {
    set({
      tab: v,
    });
  },

  type: 'year', // 결제 기간
  setType: v => {
    set({
      type: v,
    });
  },

  number: 10, // 사용인원수
  setNumber: v => {
    set({ number: v });
  },

  discount: [], // 할인율
  setDiscount: v => {
    set({ discount: v });
  },

  allBasicOptions: false,
  setAllBasicOptions: v => {
    set({ allBasicOptions: v });
  },

  basicOptions: [],
  setBasicOptions: v =>
    set({
      basicOptions: v,
    }),

  addOptions: [],
  setAddOptions: v => set({ addOptions: v }),

  requiredTraffic: false,
  setRequiredTraffic: v => set({ requiredTraffic: v }),

  requiredStorage: false,
  setRequiredStorage: v => set({ requiredStorage: v }),
}));

export const usePay = () => {
  // 단순 값 관리
  const {
    number,
    allBasicOptions,
    setAllBasicOptions,
    basicOptions,
    setBasicOptions,
    discount,
    setDiscount,
    addOptions,
    setAddOptions,
    type,
    setType,
    setNumber,
    base,
    setBase,
    requiredStorage,
    setRequiredStorage,
    requiredTraffic,
    setRequiredTraffic,
    tab,
  } = planStore();
  const { subscribeInfo } = subscribeInfoStore();
  const { alertShow } = alertStore();
  const { t } = useTranslation();

  const initSubscribeInfo = async planTab => {
    try {
      setNumber(subscribeInfo.subscribe.sb_user_limit);
      setType(subscribeInfo.subscribe.sb_period);

      const { data } = await axios.get(`${subscribeApi}/subscribe/price`);
      // , {
      //   withCredentials: true,
      //   headers: {
      //     Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
      //   },
      // }

      const basics = [];
      const adds = [];

      let checkTraffic = false;
      let checkStorage = false;

      data.data.price.forEach(v => {
        if (v.p_type === 'base') {
          setBase({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            checked: true,
            price: v.p_base_amount,
            traffic: v.p_essential_traffic,
            storage: v.p_essential_storage,
          });
          return;
        }

        setAllBasicOptions(false);

        if (v.p_type === 'module') {
          let check = false;
          subscribeInfo.subscribe.sb_have_modules.split(',').forEach(value => {
            if (v.p_name === value) {
              check = true;
              if (v.p_essential_traffic) {
                checkTraffic = true;
              }
              if (v.p_essential_storage) {
                checkStorage = true;
              }
              basics.push({
                p_key: v.p_key,
                item: v.p_name,
                name: v.p_name,
                name_kor: v.p_name_kor,
                type: v.p_type,
                unit: v.p_unit,
                checked: true,
                price: v.p_base_amount,
                traffic: v.p_essential_traffic,
                storage: v.p_essential_storage,
                readOnly: planTab === 'now',
              });
            }
          });

          if (!check)
            basics.push({
              p_key: v.p_key,
              item: v.p_name,
              name: v.p_name,
              name_kor: v.p_name_kor,
              type: v.p_type,
              unit: v.p_unit,
              checked: false,
              price: v.p_base_amount,
              traffic: v.p_essential_traffic,
              storage: v.p_essential_storage,
            });
        }

        if (v.p_name === 'traffic') {
          setRequiredTraffic(checkTraffic);
          basics.push({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            checked: checkTraffic,
            price: v.p_base_amount,
            traffic: v.p_essential_traffic,
            storage: v.p_essential_storage,
          });

          adds.push({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            price: v.p_base_amount,
            data: subscribeInfo.subscribe.sb_traffic_limit / 100 || 1,
            minData: subscribeInfo.subscribe.sb_traffic_limit / 100 || 0,
          });
        }

        if (v.p_name === 'storage') {
          setRequiredStorage(checkStorage);
          basics.push({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            checked: checkStorage,
            price: v.p_base_amount,
            traffic: v.p_essential_traffic,
            storage: v.p_essential_storage,
          });

          adds.push({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            price: v.p_base_amount,
            data: subscribeInfo.subscribe.sb_storage_limit / 100 || 1,
            minData: subscribeInfo.subscribe.sb_storage_limit / 100 || 0,
          });
        }
      });

      setDiscount([
        { number: 10, discount: 0 },
        ...Object.entries(data.data.discount).map(v => ({
          number: v[0],
          discount: v[1],
        })),
      ]);

      setBasicOptions(basics);
      setAddOptions(adds);
    } catch (e) {
      alertShow(e.response?.data?.message || t('데이터를 불러오지 못했습니다. 다시 시도해주세요.'));
    }
  };

  const allSelect = () => {
    if (tab === 'now') {
      if (!subscribeInfo.subscribe.sb_storage_limit) {
        setRequiredStorage(!allBasicOptions);
      }
      if (!subscribeInfo.subscribe.sb_traffic_limit) {
        setRequiredTraffic(!allBasicOptions);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      setRequiredStorage(!allBasicOptions);
      setRequiredTraffic(!allBasicOptions);
    }
    setAllBasicOptions(!allBasicOptions);
    setBasicOptions(
      basicOptions.map(v => {
        if (tab === 'now' && v.readOnly) return v;
        return { ...v, checked: !allBasicOptions };
      }),
    );
  };

  const getPriceNoDiscount = v => {
    return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 12 : 1);
  };

  const getPrice = v => {
    if (number < 100) {
      return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 500) {
      return ((v * (100 - discount[1].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 1000) {
      return ((v * (100 - discount[2].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 5000) {
      return ((v * (100 - discount[3].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    return ((v * (100 - discount[4].discount)) / 100) * number * (type === 'year' ? 10 : 1);
  };

  const getAllPriceNoDiscount = () => {
    let data = getPriceNoDiscount(base.price); // 기본요금
    basicOptions.forEach(v => {
      if (v.checked && v.type === 'module') data += getPriceNoDiscount(v.price);
    });
    addOptions.forEach(v => {
      if (v.data > 0 && ((v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage)))
        data += Number(v.price * v.data * (type === 'year' ? 12 : 1));
    });
    return data;
  };

  const getAllPrice = () => {
    let data = getPrice(base.price);
    basicOptions.forEach(v => {
      if (v.checked && v.type === 'module') data += getPrice(v.price);
    });
    addOptions.forEach(v => {
      if (v.data > 0 && ((v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage)))
        data += Number(v.price * v.data * (type === 'year' ? 10 : 1));
    });
    return data;
  };

  const reset = () => {
    setBase(null);
    setType('year');
    setNumber(10);
    setAllBasicOptions(false);
    setBasicOptions(
      basicOptions.map(v => {
        return { ...v, checked: false };
      }),
    );
    setAddOptions([]);
    setRequiredStorage(false);
    setRequiredTraffic(false);
  };

  return {
    allSelect,
    getPriceNoDiscount,
    getPrice,
    getAllPriceNoDiscount,
    getAllPrice,
    reset,
    initSubscribeInfo,
  };
};

export const usePlan = () => {
  // 결제 진행 로직
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const { number, type, basicOptions, addOptions, requiredTraffic, requiredStorage } = planStore();
  const { getAllPrice } = usePay();
  const { setLoading } = loadingStore();
  const navigate = useNavigate();
  const { setTab, setNextPlanInfo } = subscribeInfoStore();

  const { logout } = useLogout();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const onUpgrade = async () => {
    try {
      const fnc = async (trafficLimit, storageLimit, userLimit, modules) => {
        // eslint-disable-next-line no-useless-catch
        try {
          setLoading(true);
          const res = await axios.post(
            `${subscribeApi}/subscribe/upgradeplan`,
            {
              trafficLimit,
              storageLimit,
              userLimit,
              modules,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
              },
            },
          );
          setLoading(false);
          if (res?.status === 201) {
            alertShow(t('플랜 변경이 완료되었습니다. 구독 정보 페이지로 이동합니다.'), () =>
              navigate('/mypage/subscribe'),
            );
          }
        } catch (e) {
          setLoading(false);
          // PayFailed, PLAN_UPGRADE_FAILED_ERR_229
          // if (error?.response?.data?.message === 'PayFailed') {
          //   alertShow(t('결제에 실패하였습니다.'));
          // }
          console.log(e);
          if (
            e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE' ||
            e?.response?.data?.message === 'NO_SUBSCRIBE_USER'
          ) {
            alertShow(
              t('구독이 만료되어 플랜 업그레이드에 실패하였습니다.\n결제페이지로 이동하여 결제를 진행해 주세요.'),
            );
          } else alertShow(t('결제에 실패하였습니다. 관리자에게 문의해주세요.'));
        }
      };

      let trafficLimit = 0;
      let storageLimit = 0;
      const modules = [];
      basicOptions.forEach(v => {
        if (v.type === 'module' && v.checked) {
          modules.push(v.name);
        }
      });
      addOptions.forEach(v => {
        if (v.name === 'traffic' && requiredTraffic) {
          trafficLimit = v.unit * v.data;
        } else if (v.name === 'storage' && requiredStorage) storageLimit = v.unit * v.data;
      });

      const price = await onGetUpgradePrice(trafficLimit, storageLimit, number, modules);

      if (price === false) {
        return;
      }
      if (price === 0) {
        alertShow(t('기존 구독정보와 동일합니다.\n플랜을 변경하신 후 결제하기 버튼을 눌러주세요.'));
        return;
      }

      const div = `
        <div style="display:flex; flex-direction:column; gap:20px;">
        <div style="display:flex; flex-direction:column; color:var(--greyish2); padding:5px 10px;">
          <div style="display:flex; padding: 2px 5px; justify-content:space-between; flex:1; flex-wrap:wrap;">
              <div>${t('총 결제금액')}</div>
              <div>${getAllPrice().toLocaleString('ko')}${t('원')}</div>
          </div>
          <div style="display:flex; padding: 2px 5px 4px 5px; border-bottom:1px solid var(--greyish7);  justify-content:space-between; flex:1; flex-wrap:wrap; color:#ff0000;">
              <div>${t('기존 결제금액')}</div>
              <div>-${(getAllPrice() - price).toLocaleString('ko')}${t('원')}</div>
          </div>
          <div style="display:flex; padding: 4px 5px 2px 5px; justify-content:space-between; flex:1; flex-wrap:wrap; font-weight:600">
              <div>${t('최종 결제금액')}</div>
              <div>${price.toLocaleString('ko')}${t('원')}</div>
          </div>
        </div>
        <div style="display:flex; justify-content:center;">
          ${t('<span>l원을 결제하시겠습니까?</span>', { l: price.toLocaleString('ko') })}
        </div>
        </div>
      `;

      confirmShow(
        div,
        () => {
          fnc(trafficLimit, storageLimit, number, modules);
        },
        'unset',
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onGetUpgradePrice = async (trafficLimit, storageLimit, userLimit, modules) => {
    let module = '';

    modules.forEach((v, i) => {
      module += `&modules[${i}]=${v}`;
    });
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${subscribeApi}/subscribe/price/upgradeplan?trafficLimit=${trafficLimit}&storageLimit=${storageLimit}&userLimit=${userLimit}${module}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      setLoading(false);
      if (data?.data) return data?.data?.price || 0;
      return false;
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e?.response?.data?.message === 'CANT_PLAN_DOWN') {
        alertShow(t('플랜 업그레이드에서는 인원 및 기능, 옵션 추가만 가능합니다.'));
      } else if (
        e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE' ||
        e?.response?.data?.message === 'NO_SUBSCRIBE_USER'
      ) {
        alertShow(t('구독이 만료되어 플랜 변경에 실패하였습니다.\n결제페이지로 이동하여 결제를 진행해 주세요.'));
      } else alertShow(t('결제에 실패하였습니다. 관리자에게 문의해주세요.'));
      return false;
    }
  };

  const onChangeplan = async () => {
    try {
      const fnc = async (trafficLimit, storageLimit, number, modules) => {
        // eslint-disable-next-line no-useless-catch
        try {
          setLoading(true);

          const res = await axios.post(
            `${subscribeApi}/subscribe/change/nextplan`,
            {
              trafficLimit,
              storageLimit,
              userLimit: number,
              modules,
              month: type,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
              },
            },
          );
          setLoading(false);
          if (res?.status === 201) {
            alertShow(t('플랜 변경 신청이 완료되었습니다. 구독 정보 페이지로 이동합니다.'), () => {
              navigate('/mypage/subscribe');

              // todo check
              setTab('next');
            });
          }
        } catch (e) {
          setLoading(false);
          if (e?.response?.data?.message === 'ALREADY_HAVE_NEXTPLAN') {
            alertShow(
              t(
                '이미 플랜 변경을 신청하셨습니다. 다음 결제일 이후에 시도하시거나, 기존 변경 내역을 취소하신 후에 다시 신청해주세요.',
              ),
            );
          } else if (
            e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE' ||
            e?.response?.data?.message === 'NO_SUBSCRIBE_USER'
          ) {
            alertShow(t('구독이 만료되어 플랜 변경에 실패하였습니다.\n결제페이지로 이동하여 결제를 진행해 주세요.'));
          } else alertShow(t('결제에 실패하였습니다. 관리자에게 문의해주세요.'));
          console.log(e);
        }
      };

      let trafficLimit = 0;
      let storageLimit = 0;
      const modules = [];
      basicOptions.forEach(v => {
        if (v.type === 'module' && v.checked) {
          modules.push(v.name);
        }
      });
      addOptions.forEach(v => {
        if (v.name === 'traffic' && requiredTraffic) {
          trafficLimit = v.unit * v.data;
        } else if (v.name === 'storage' && requiredStorage) storageLimit = v.unit * v.data;
      });

      const price = await onGetUpgradePrice(trafficLimit, storageLimit, number, modules);

      if (price === false) {
        return;
      }
      if (price === 0) {
        alertShow(t('기존 구독정보와 동일합니다.\n플랜을 수정하신 후 변경하기 버튼을 눌러주세요.'));
        return;
      }

      confirmShow(
        `${t('다음 결제일부터 n원이 결제되며, 플랜이 변경됩니다.\n진행하시겠습니까?', {
          n: getAllPrice().toLocaleString('ko'),
        })}`,
        () => {
          fnc(trafficLimit, storageLimit, number, modules);
        },
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onCancelNextPlan = async () => {
    try {
      const fnc = async () => {
        // eslint-disable-next-line no-useless-catch
        try {
          setLoading(true);

          const res = await axios.delete(`${subscribeApi}/subscribe/change/nextplan`, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
            },
          });
          setLoading(false);
          if (res?.status === 200) {
            alertShow(t('플랜 변경이 취소되었습니다.'), () => {
              setNextPlanInfo(null);
              setTab('now');
              const container = document.getElementsByClassName('root__container')[0];
              if (container) container.scrollTo(0, 0);
            });
          }
        } catch (e) {
          setLoading(false);
          alertShow(t('플랜 변경 취소에 실패하였습니다. 관리자에게 문의해주세요.'));
          console.log(e);
        }
      };
      confirmShow(t('이전에 신청하신 플랜 변경 내역이 초기화됩니다.\n정말 플랜 변경을 취소하시겠습니까?'), () => fnc());
    } catch (e) {
      console.log(e);
    }
  };

  return { onUpgrade, onChangeplan, onCancelNextPlan };
};
