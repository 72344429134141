import React from 'react';
import Layout from '../components/layout/Layout';
import Signup from '../components/others/Signup';

export default function SignupPage() {
  return (
    <Layout>
      <Signup />
    </Layout>
  );
}
