import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { alertStore } from '../../stores/modal/alertStore';
import { confirmStore } from '../../stores/modal/confirmStore';
import { useInfo } from '../../stores/mypage/infoStore';

import styles from '../../assets/scss/mypage/info.module.scss';

// img
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';
import icCloseWrong from '../../assets/img/icons/ic_close_wrong.svg';

function FormPw() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const navigate = useNavigate();
  const { editPassword } = useInfo();

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordView, setOldPasswordView] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmView, setPasswordConfirmView] = useState(false);
  const [waring, setWarning] = useState(false);

  useEffect(() => {
    return () => {
      setOldPassword('');
      setOldPasswordView(false);
      setPassword('');
      setPasswordView(false);
      setPasswordConfirm('');
      setPasswordConfirmView(false);
      setWarning(false);
    };
  }, []);

  const onSubmit = () => {
    if (!oldPassword) {
      alertShow(t('이전 비밀번호를 입력해주세요.'), null);
      return;
    }
    if (!password) {
      alertShow(t('새 비밀번호를 입력해주세요.'), null);
      return;
    }
    if (password && waring) {
      alertShow(t('비밀번호 주의사항을 확인해주세요.'), null);
      return;
    }
    if (!passwordConfirm) {
      alertShow(t('새 비밀번호 확인을 입력해주세요.'), null);
      return;
    }
    if (password !== passwordConfirm) {
      alertShow(t('비밀번호가 일치하지 않습니다.'), null);
      return;
    }
    confirmShow(t('수정하시겠습니까?'), async () => {
      let check = await editPassword(oldPassword, password);
      if (check) {
        setOldPassword('');
        setOldPasswordView(false);
        setPassword('');
        setPasswordView(false);
        setPasswordConfirm('');
        setPasswordConfirmView(false);
        setWarning(false);
      }
    });
  };
  return (
    <>
      <div className={styles.form}>
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('이전 비밀번호')}</span>
          </div>
          <div className={styles.input}>
            <input
              type={oldPasswordView ? 'text' : 'password'}
              autoComplete="off"
              placeholder={t('이전 비밀번호 입력') || ''}
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
            <img
              src={oldPasswordView ? icVisibilityOff : icVisibility}
              alt="visibility"
              onClick={() => setOldPasswordView(prev => !prev)}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('새 비밀번호')}</span>
          </div>
          <div className={styles.input}>
            <input
              type={passwordView ? 'text' : 'password'}
              placeholder={t('새 비밀번호 입력') || ''}
              value={password}
              autoComplete="off"
              onChange={e => {
                setPassword(e.target.value);
                const numberCheck = /[0-9]/.test(e.target.value);
                const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                const spaceCheck = e.target.value.search(/\s/) === -1;
                const double =
                  (numberCheck && alphabetCheck) || (numberCheck && specialCheck) || (alphabetCheck && specialCheck);
                const triple = numberCheck && alphabetCheck && specialCheck;
                setWarning(
                  !spaceCheck ||
                    e.target.value.length < 8 ||
                    (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                    (e.target.value.length > 9 && !double),
                );
              }}
            />
            <img
              src={passwordView ? icVisibilityOff : icVisibility}
              alt="visibility"
              onClick={() => setPasswordView(prev => !prev)}
            />
          </div>
          {waring && (
            <div className={styles.guide}>
              <div className={styles.guideItem}>
                <img src={icCloseWrong} alt="icon" className="wrong" />
                <span>
                  {t(
                    '비밀번호는 공백 없이 영문, 숫자, 특수문자를 모두 포함하여 8자 이상 또는 영문, 숫자, 특수문자 중 2가지를 조합하여 10자리 이상으로 입력해주세요.',
                  )}
                </span>
              </div>
            </div>
          )}
          <div className={styles.input}>
            <input
              type={passwordConfirmView ? 'text' : 'password'}
              placeholder={t('새 비밀번호 확인 입력') || ''}
              value={passwordConfirm}
              autoComplete="off"
              onChange={e => {
                setPasswordConfirm(e.target.value);
              }}
            />
            <img
              src={passwordConfirmView ? icVisibilityOff : icVisibility}
              alt="visibility"
              onClick={() => setPasswordConfirmView(prev => !prev)}
            />
          </div>
          <div className={styles.guide}>
            {password.length > 0 && passwordConfirm.length > 0 && password !== passwordConfirm && (
              <div className={styles.guideItem}>
                <img src={icCloseWrong} alt="icon" className="wrong" />
                <span>{t('비밀번호가 일치하지 않습니다.')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <button type="button" className={styles.btn} onClick={onSubmit}>
          {t('수정')}
        </button>
      </div>
    </>
  );
}

export default FormPw;
