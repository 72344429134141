import React from 'react';
import hero_bg from '../../assets/public_img/hero-bg-1.jpg';

export default function PageHeader({ PageTitle, HeaderTitle, Parent }) {
  return (
    <section
      className="hero-section ptb-60 background-img"
      style={{
        background: `url(${hero_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-lg-7">
            <div className="page-header-content text-white text-center  pt-lg-0">
              <h1 className="text-white mb-0">{HeaderTitle}</h1>
              <div className="custom-breadcrumb"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
