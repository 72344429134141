import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export const chatStore = create((set, get) => ({
  list: [],
  focus: false,
  viewHeight: 0,
  keyboardHeight: 0,
  typing: false,
  setTyping: typing => set(() => ({ typing })),
  setList: list => set(() => ({ list })),
  setFocus: focus => set(() => ({ focus })),
  setViewHeight: viewHeight => set(() => ({ viewHeight })),
  setKeyboardHeight: keyboardHeight => set(() => ({ keyboardHeight })),
  write: (type, message, key = '') => set(() => ({ list: get().list.concat({ key: key || uuidv4(), type, message }) })),
}));

export const useChat = () => {
  const { write, setTyping } = chatStore();
  const { t } = useTranslation();

  const onQuestion = async question => {
    const key = uuidv4();
    await write('admin', '', key);

    const textEle = document.getElementById(`ai__answer__${key}`);

    const inp = document.getElementById('ai__chat__input');
    inp?.blur();
    inp?.setAttribute('disabled', true);
    inp?.setAttribute('placeholder', t('질문 입력 불가'));
    setTyping(key);

    try {
      const response = await fetch(`${process.env.REACT_APP_AI_QUESTION}/quizai/question_xclass?question=${question}`);
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

      let fullMessage = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let data = value.toString('utf-8');
        data = data.replace('\\\\', '\\');
        textEle.innerHTML = textEle.innerHTML.replaceAll('\\n', '<br>');
        textEle.innerHTML = textEle.innerHTML + data;
        fullMessage += data;
        if (textEle.innerHTML.split('')[0] === '\n' || textEle.innerHTML.split('')[0] === ' ') {
          textEle.innerHTML = textEle.innerHTML.substr(1);
          fullMessage = fullMessage.substr(1);
        }

        const scrollContainer = document.getElementById('ai_chat_scroll_container');
        scrollContainer?.scroll({ top: scrollContainer.scrollHeight, left: 0, behavior: 'smooth' });
      }
      inp?.removeAttribute('disabled');
      inp?.setAttribute('placeholder', t('질문을 입력해주세요.'));
      setTyping(false);
    } catch (e) {
      // todo text
      textEle.innerHTML = t('오류로 인해 답변이 불가능합니다. 나중에 다시 시도해주세요.');
      inp?.removeAttribute('disabled');
      inp?.setAttribute('placeholder', t('질문을 입력해주세요.'));
      setTyping(false);

      const scrollContainer = document.getElementById('ai_chat_scroll_container');
      scrollContainer?.scroll({ top: scrollContainer.scrollHeight, left: 0, behavior: 'smooth' });
      console.log(e);
    }
  };
  return { onQuestion };
};
