import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { useLogout } from '../user/loginStore';

const api = process.env.REACT_APP_API;

export const inquiryStore = create((set, get) => ({
  data: { name: '', email: '', phone: '', customer: '', employee: '', business: '', text: '' },
  setData: v =>
    set({
      data: v,
    }),

  complete: false,
  setComplete: complete => set({ complete }),
}));

export function useInquiry() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setData, setComplete } = inquiryStore();
  const { logout } = useLogout();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const postInquiry = async inquiryData => {
    try {
      setLoading(true);
      await axios.post(`${api}/user/enquiry`, inquiryData, {
        withCredentials: true,
      });

      setData({
        name: '',
        email: '',
        phone: '',
        customer: '',
        employee: '',
        business: '',
        text: '',
      });
      setComplete(true);
      navigate('/contact/complete');
      // alertShow(t('문의가 완료되었습니다.'), () => {
      //   setData({
      //     name: '',
      //     email: '',
      //     phone: '',
      //     customer: '',
      //     employee: '',
      //     business: '',
      //     text: '',
      //   });
      // });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alertShow(e?.response?.data?.message_ko || e?.response?.data?.message);
    }
  };

  return { postInquiry };
}
