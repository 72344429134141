import { useTranslation } from 'react-i18next';
import { subscribeInfoStore } from '../../../stores/mypage/subscribeInfoStore';
import { subscribeStore } from '../../../stores/pay/subscribeStore';
import { usePlan } from '../../../stores/mypage/planStore';

import styles from '../../../assets/scss/mypage/subscribe.module.scss';

function NextForm() {
  const { t } = useTranslation();

  const { subscribeInfo, nextPlanInfo } = subscribeInfoStore();
  const { basicOptions } = subscribeStore();
  const { onCancelNextPlan } = usePlan();

  return (
    <div className={styles.plan}>
      {nextPlanInfo && (
        <>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('결제 예정 금액')}</div>
            <div>
              {Number(subscribeInfo.price).toLocaleString('ko-KR')}
              {t('원')} / {nextPlanInfo.sbnp_period === 'month' ? t('월') : t('연')}
            </div>
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('구독 예정 기능')}</div>
            <div>
              {nextPlanInfo.sbnp_have_modules?.split(',').map((v, i) => {
                let name = '';
                basicOptions?.forEach(value => {
                  if (value.name === v) name = value.name_kor;
                });
                if (i === nextPlanInfo.sbnp_have_modules.split(',').length - 1) {
                  return <span key={name || i}>{name}</span>;
                }
                return <span key={name || i}>{name}, </span>;
              })}
            </div>
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('사용 인원')}</div>
            {nextPlanInfo.sbnp_user_limit}
            {t('명')}
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('트래픽 제한')}</div>
            <div className={styles.explanationContainer}>
              {nextPlanInfo.sbnp_traffic_limit || 0}
              GB
            </div>
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('저장공간')}</div>
            {nextPlanInfo.sbnp_storage_limit || 0}GB
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemBtns}>
              <div onClick={onCancelNextPlan}>{t('플랜 변경 취소')}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default NextForm;
