import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import app_hero_bg from '../../assets/public_img/app-hero-bg.jpg';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
        background: `url(${app_hero_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-lg-7">
            <div className="error-content text-center text-white">
              <div className="notfound-404">
                <h1 className="text-white">404</h1>
              </div>
              <h3 className="text-white">{t('요청하신 페이지를 찾을 수 없습니다.')}</h3>
              <p>
                {t(
                  '찾으려는 페이지의 주소가 잘못 입력 되었거나, 주소의 변경 혹은 삭제로 인해 이용하실 수 없습니다. 올바른 URL 주소를 입력하셨는지 확인해 주시기 바랍니다.',
                )}
              </p>
              <Link className="btn app-store-btn" to="/">
                {t('홈페이지로 돌아가기')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
