/* eslint-disable no-useless-catch */
import { create } from 'zustand';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { infoStore } from './infoStore';
import { useLogout } from '../user/loginStore';

const subscribeApi = process.env.REACT_APP_SUBSCRIBE_API;
const api = process.env.REACT_APP_API;

export const accountStore = create((set, get) => ({
  code: '',
  setCode: v => {
    set({
      code: v,
    });
  },
  codeCheck: false,
  setCodeCheck: v => {
    set({ codeCheck: v });
  },
  name: '',
  setName: v => {
    set({
      name: v,
    });
  },
  address: {
    postCode: '',
    address: '',
    addressDetail: '',
  },
  setAddress: v => {
    set({
      address: v,
    });
  },
  addressState: false,
  setAddressState: v => {
    set({
      addressState: v,
    });
  },
  adminName: '',
  setAdminName: v => {
    set({
      adminName: v,
    });
  },
  adminEmail: '',
  setAdminEmail: v => {
    set({
      adminEmail: v,
    });
  },
  adminPhone: '',
  setAdminPhone: v => {
    set({
      adminPhone: v,
    });
  },
}));

export function useAccount() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setCodeCheck, setCode, setName, setAddress, setAddressState, setAdminName, setAdminEmail, setAdminPhone } =
    accountStore();
  const naviagate = useNavigate();
  const { logout } = useLogout();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const onCheckCode = async v => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${api}/user/customer/check/${v}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      if (data.data === 1) {
        // 중복
        alertShow(t('기업코드가 중복되었습니다.'));
        setCodeCheck(false);
        return;
      }
      alertShow(t('사용 가능한 기업코드입니다.'));
      setCodeCheck(true);
    } catch (e) {
      setLoading(false);
      setCodeCheck(false);
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  const onCustomerMake = async v => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${subscribeApi}/subscribe/customer/make`, v, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      if (!data) {
        alertShow(t('기업계정 등록에 실패하였습니다.\n다시 시도해주세요.'));
        return;
      }
      alertShow(
        t(
          '기업계정 등록에 성공하였습니다.\n가입하신 이메일로 관리자 계정 초기 아이디와 비밀번호를 발송해드렸으니 확인 바랍니다.',
        ),
        () => naviagate('/mypage/subscribe'),
      );
    } catch (e) {
      setLoading(false);
      alertShow(e.response.data.message);
    }
  };

  const reset = () => {
    setCodeCheck(false);
    setCode('');
    setName('');
    setAddress({
      postCode: '',
      address: '',
      addressDetail: '',
    });
    setAddressState(false);
    setAdminName('');
    setAdminEmail('');
    setAdminPhone('');
  };

  return { onCheckCode, onCustomerMake, reset };
}
