import "./email.css";
function EmailRefuseConponent() {
  return (
    <>
      <div className="etc-section">
        <div className="etc-section-inner">
          <div className="etc-article">
            <div className="etc-article-text">
              <span>
                본 웹사이트는 게시된 이메일 주소가 전자우편 수집 프로그램이나 그
                밖의 기술적 장치를 이용하여 무단 수집되는 것을 거부합니다.
                <br />
                이를 위반시 『정보통신망 이용 촉진 및 정보보호 등에 관한
                법률』등에 의해 처벌 받을 수 있습니다.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="etc-text last">
        <div className="etc-text-title">
          정보통신망 이용촉진 및 정보보호 등에 관한 법률
        </div>
        <div className="etc-text-sub">
          <div className="etc-text-sub-title">
            제50조의2 (전자우편주소의 무단 수집행위 등 금지)
          </div>
          <div className="etc-text-sub-content">
            <div className="etc-text-sub-order">
              <div className="etc-text-sub-order-text">
                누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이
                인터넷 홈페이지에서 자동으로 전자우편주소를 수집 하는 프로그램
                그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는
                아니된다.
              </div>
            </div>
            <div className="etc-text-sub-order">
              <div className="etc-text-sub-order-text">
                누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를
                판매ㆍ유통하여서는 아니된다.
              </div>
            </div>
            <div className="etc-text-sub-order">
              <div className="etc-text-sub-order-text">
                누구든지 제1항 및 제2항의 규정에 의하여 수집ㆍ판매 및 유통이
                금지된 전자우편주소임을 알고 이를 정보 전송에 이용하여서는
                아니된다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailRefuseConponent;
