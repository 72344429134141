/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Layout from '../../components/layout/Layout';

import styles from './iframe.module.scss';

function IframeContainer() {
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [url, setUrl] = useState(`${process.env.REACT_APP_IFRAME_URL}/public/learningtalktalk`);
  const prevLink = useRef('/');

  useEffect(() => {
    prevLink.current = location.pathname;
  }, [location]);

  // todo 뒤로가기 했을때는 URL 바꿔야함!
  const setUrlFunction = () => {
    if (params.secondKey) {
      setUrl(
        `${process.env.REACT_APP_IFRAME_URL}/public/learningtalktalk/${params.param}/${params.key}/${params.secondKey}`,
      );
    } else if (params.key) {
      setUrl(`${process.env.REACT_APP_IFRAME_URL}/public/learningtalktalk/${params.param}/${params.key}`);
    } else if (params.param) {
      setUrl(`${process.env.REACT_APP_IFRAME_URL}/public/learningtalktalk/${params.param}`);
    }
  };

  useEffect(() => {
    setUrlFunction(); // 첫 시작 시 실행

    const messageHandler = e => {
      if (e.data.event === 'pathname') {
        const link = e.data.data.replace('/public', '');
        if (link !== prevLink.current) navigate(link, { replace: true });
      }
    };

    window.addEventListener('message', messageHandler);
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.contents}>
          <iframe src={url} id="ifr" width="100%" ref={iframeRef}></iframe>
        </div>
      </div>
    </Layout>
  );
}

export default IframeContainer;
