import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { planStore, usePay, usePlan } from '../../../stores/mypage/planStore';
import { subscribeInfoStore } from '../../../stores/mypage/subscribeInfoStore';
import { confirmStore } from '../../../stores/modal/confirmStore';
import { alertStore } from '../../../stores/modal/alertStore';
import { cardStore } from '../../../stores/pay/cardStore';

import styles from '../../../assets/scss/mypage/plan.module.scss';

export default function Sidebar() {
  const { t } = useTranslation();
  const { number, basicOptions, type, addOptions, base, requiredTraffic, requiredStorage, tab } = planStore();
  const { getPriceNoDiscount, getPrice, getAllPriceNoDiscount, getAllPrice } = usePay();
  const { onUpgrade, onChangeplan } = usePlan();
  const { confirmShow } = confirmStore();
  const { alertShow } = alertStore();
  const navigate = useNavigate();
  const { subscribeInfo } = subscribeInfoStore();
  const { cardInfo } = cardStore();

  const [cookies] = useCookies(['XHOME']);

  const onClickPayment = () => {
    if (!cookies.XHOME) {
      confirmShow(t('로그인 후 결제가 가능합니다.\n로그인하시겠습니까?'), () => {
        navigate('/login');
      });
      return;
    }

    if (subscribeInfo?.subscribe?.sb_demo) {
      alertShow(
        '데모체험 중에는 플랜 변경이 불가능합니다.\n데모체험 기간 종료 후 결제 페이지에서 처음부터 결제 및 구독을 진행해주세요.',
      );
      return;
    }

    if (!subscribeInfo?.subscribe?.sb_enable) {
      alertShow(
        '구독 기간이 만료되어 플랜 변경이 불가능합니다.\n결제 페이지에서 처음부터 결제 및 구독을 진행해주세요.',
      );
      return;
    }

    if (tab === 'now') {
      if (!cardInfo) {
        alertShow(
          t(
            '등록된 카드가 없어서 결제가 불가능합니다.\n구독 만료 후 결제 페이지에서 처음부터 결제 및 구독을 진행해주세요.',
          ),
        );
        return;
      }
      if (number < subscribeInfo.subscribe.sb_user_limit) {
        alertShow(
          t('최소 n명부터 이용 가능합니다.\n사용자 수를 다시 입력해주세요.', {
            n: subscribeInfo.subscribe.sb_user_limit,
          }),
        );
        return;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (number < 10) {
        alertShow(t('최소 10명부터 이용 가능합니다.\n사용자 수를 다시 입력해주세요.'));
        return;
      }
    }

    if (number % 10 !== 0) {
      alertShow(t('사용자 수는 10명 단위로만 입력이 가능합니다.\n다시 입력해주세요.'));
      return;
    }

    let basicCheck = false;
    basicOptions.forEach(v => {
      if (v.type === 'module' && v.checked) {
        // type이 모듈인데 하나라도 체크된게 있으면
        basicCheck = true;
      }
    });
    if (!basicCheck) {
      alertShow(t('기능을 하나 이상 선택해주세요.'));
      return;
    }

    let check = false;
    addOptions.forEach(v => {
      if (check) return;

      if (v.name === 'traffic' && v.data < 1 && requiredTraffic) {
        alertShow(t('트래픽은 최소 1개 구매하셔야 합니다.'));
        check = true;
      }
      if (v.name === 'storage' && v.data < 1 && requiredStorage) {
        alertShow(t('저장공간은 최소 1개 구매하셔야 합니다.'));
        check = true;
      }
    });

    if (check) return;

    if (tab === 'now') onUpgrade();
    else onChangeplan();
  };

  if (!base) return null;

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebar}>
        <div className={styles.title}>
          <div>Total</div>
          <div className={styles.items}>
            <div className={styles.item}>{type === 'year' ? t('연 결제') : t('월 결제')}</div>
            <div className={styles.item}>
              {t('사용자')} {number}
              {t('명')}
            </div>
          </div>
        </div>
        <div className={styles.hr}></div>
        <div className={styles.datas}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              {type === 'year' ? t('연 결제') : t('월 결제')} {t('기본요금')}
            </div>

            <div className={styles.itemPrice}>
              {getPriceNoDiscount(base.price) > getPrice(base.price) && (
                <div className={styles.itemEtc}>
                  {getPriceNoDiscount(base.price).toLocaleString('ko')}
                  {t('원')}
                </div>
              )}
              <div>
                {getPrice(base.price).toLocaleString('ko')}
                {t('원')}
              </div>
            </div>
          </div>
          {basicOptions.map(v => {
            if (v.type !== 'module') return null;
            if (v.checked)
              return (
                <div className={styles.item} key={v.p_key}>
                  <div className={styles.itemTitle}>{t(v.name_kor)}</div>
                  <div className={styles.itemPrice}>
                    {getPriceNoDiscount(v.price) > getPrice(v.price) && (
                      <div className={styles.itemEtc}>
                        {getPriceNoDiscount(v.price).toLocaleString('ko')}
                        {t('원')}
                      </div>
                    )}
                    <div>
                      {getPrice(v.price).toLocaleString('ko')}
                      {t('원')}
                    </div>
                  </div>
                </div>
              );
            return null;
          })}
        </div>

        {(requiredStorage || requiredTraffic) && (
          <>
            <div className={styles.hr}></div>
            <div className={styles.datas}>
              {addOptions.map(v => {
                if ((v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage))
                  return (
                    <div className={styles.item} key={v.name}>
                      <div className={styles.itemTitle}>
                        {t(v.name_kor)} {v.unit}GB (x{v.data})
                      </div>
                      <div className={styles.itemPrice}>
                        {type === 'year' && (
                          <div className={styles.itemEtc}>
                            {Number(v.price * v.data * 12).toLocaleString('ko')}
                            {t('원')}
                          </div>
                        )}
                        <div>
                          {Number(v.price * v.data * (type === 'year' ? 10 : 1)).toLocaleString('ko')}
                          {t('원')}
                        </div>
                      </div>
                    </div>
                  );
                return null;
              })}
            </div>
          </>
        )}

        <div className={styles.hr}></div>
        <div className={styles.totalContainer}>
          <div className={styles.total}>
            <div className={styles.totalTitle}>{t('총 결제금액')}</div>
            <div className={styles.totalPrice}>
              {getAllPriceNoDiscount() > getAllPrice() && (
                <div className={styles.totalEtc}>
                  {getAllPriceNoDiscount().toLocaleString('ko')}
                  {t('원')}
                </div>
              )}
              <div>
                {getAllPrice().toLocaleString('ko')}
                {t('원')}
              </div>
            </div>
          </div>
          <div className={styles.etc}>
            {tab === 'now'
              ? `* ${t('총 결제금액에서 기존 결제금액을 제외한 차액만 결제됩니다.')}`
              : `* ${t('다음 결제일부터 결제 후 적용됩니다.')}`}
          </div>
        </div>

        <div className={styles.btn}>
          <div onClick={onClickPayment}>{tab === 'now' ? t('결제하기') : t('변경하기')}</div>
        </div>
      </div>
    </div>
  );
}
