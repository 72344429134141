import React from 'react';
import Layout from '../components/layout/Layout';
import FindPw from '../components/others/FindPw';

export default function FindPwPage() {
  return (
    <Layout>
      <FindPw />
    </Layout>
  );
}
