import { useState, useEffect } from "react";

function Delay({ delay, children }) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const showTimer = setTimeout(() => setDone(true), delay);
    return () => clearTimeout(showTimer);
  });

  if (!done) return null;
  return <>{children}</>;
}

export default Delay;
