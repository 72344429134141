import { create } from "zustand";

export const alertStore = create((set, get) => ({
  state: false,
  message: "",
  func: null,
  messageList: [],

  alertShow: (msg, fnc = null) =>
    set({
      state: true,
      message: msg,
      func: fnc,
      messageList: [],
    }),

  alertTransShow: (msg, fnc = null) =>
    set({
      state: true,
      message: "",
      func: fnc,
      messageList: msg,
    }),

  alertClose: () => {
    if (get().func) get().func();
    set({ state: false, message: "", func: null, messageList: [] });
  },
}));
