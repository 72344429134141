import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { alertStore } from '../../stores/modal/alertStore';
import { useSignUp, signUpStore } from '../../stores/user/signupStore';
import { confirmStore } from '../../stores/modal/confirmStore';
import { agreeStore } from '../../stores/modal/agreeStore';

import Agreement from '../../modal/Agreement';
import Agree1 from '../../modal/membership/Agree1';
import Agree2 from '../../modal/membership/Agree2';
import Agree3 from '../../modal/membership/Agree3';
import Agree4 from '../../modal/membership/Agree4';

import '../../assets/scss/customForm.scss';

import logo from '../../assets/img/logo.svg';
import icCloseWrong from '../../assets/img/icons/ic_close_wrong.svg';
import icCheckRight from '../../assets/img/icons/ic_check_right.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';
import here_bg from '../../assets/public_img/hero-bg-1.jpg';
import wave from '../../assets/public_img/wave-shap.svg';

export default function Signup() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const { onIdCheck, onNicknameCheck, join, onAuth, onCodeCheck } = useSignUp();
  const { agreeShow } = agreeStore();
  const { tryAuth, setTryAuth } = signUpStore();

  const [id, setId] = useState('');
  const [idCheckState, setIdCheckState] = useState(false); // id check guide 보이게
  const [idCheck1, setIdCheck1] = useState(false); // id 형식에 맞는지
  const [idCheck2, setIdCheck2] = useState(false); // id 중복확인 여부
  const [idCheck3, setIdCheck3] = useState(false); // id 문자열 확인

  const onClickIdCheck = async () => {
    if (!idCheck1 || idCheck3 || idCheck2) return;
    const v = await onIdCheck(id);
    setIdCheck2(v);
  };

  const [nicknameCheckState, setNicknameCheckState] = useState(false);
  const [nickname, setNickname] = useState('');
  const [nameLengthCheck, setNameLengthCheck] = useState('');
  const [nicknameCheck1, setNicknameCheck1] = useState(false);
  const [nicknameCheck2, setNicknameCheck2] = useState(false);

  const changeNickname = e => {
    const minByte = 4;
    const maxByte = 16;
    const inputValue = e.target.value;
    const inputValueLength = e.target.value.length;

    let totalByte = 0;
    for (let i = 0; i < inputValueLength; i++) {
      const eachChar = inputValue.charAt(i);
      const uniChar = escape(eachChar);
      if (uniChar.length > 4) {
        totalByte += 2;
      } else {
        totalByte += 1;
      }
    }

    if (totalByte < minByte) {
      setNameLengthCheck('short');
    } else if (totalByte > maxByte) {
      setNameLengthCheck('long');
    } else {
      setNameLengthCheck('pass');
    }

    // setNicknameInit(true);
    setNickname(e.target.value);
    setNicknameCheck1(!/([^_가-힣0-9a-zA-Z\x20])/i.test(e.target.value) && !e.target.value.includes(' '));
    setNicknameCheck2(false);
  };

  const onClickNicknameCheck = async () => {
    if (!nicknameCheck1 || nicknameCheck2 || nameLengthCheck !== 'pass') return;
    const v = await onNicknameCheck(nickname);
    setNicknameCheck2(v);
  };

  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState('');
  const [emailCheck2, setEmailCheck2] = useState(false);
  const [emailCheckState, setEmailCheckState] = useState(false);
  const changeEmail = e => {
    setEmailCheckState(e.target.value.length > 0);
    setEmailCheck(
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(e.target.value),
    );
    setEmail(e.target.value);
    setEmailCheck2(false);
    setTryAuth(false);
    setCode('');
  };

  const onClickEmailCheck = () => {
    if (tryAuth || emailCheck2) return;
    onAuth({ email });
  };

  const [code, setCode] = useState(''); // email code
  const onClickCodeCheck = async () => {
    if (emailCheck2) return;
    const v = await onCodeCheck({ email, code });
    setEmailCheck2(v);
  };

  const [phone, setPhone] = useState('');

  const [pw, setPw] = useState('');
  const [pwView, setPwView] = useState(false);
  const [pwConfrim, setPwConfirm] = useState('');
  const [pwConfrimView, setPwConfirmView] = useState(false);
  const [waring, setWarning] = useState(false);

  // 동의
  const [agree, setAgree] = useState({
    agree1: false,
    agree2: false,
    agree3: false,
  });
  const { agree1, agree2, agree3 } = agree;
  const [agree4, setAgree4] = useState(false);
  const onAgreeAll = () => {
    if (agree1 && agree2 && agree3 && agree4) {
      setAgree({
        agree1: false,
        agree2: false,
        agree3: false,
      });
      setAgree4(false);
    } else {
      setAgree({
        agree1: true,
        agree2: true,
        agree3: true,
      });
      setAgree4(true);
    }
  };
  const [agreeType, setAgreeType] = useState(1);

  const onSignUp = () => {
    const fnc = () => {
      // signup
      join(
        id,
        nickname,
        pw,
        agree4 ? new Date().toISOString().slice(0, 10) : null,
        phone,
        email,
        null,
        null,
        null,
        null,
      );
    };

    if (!agree1 || !agree2 || !agree3) {
      alertShow(t('서비스 약관에 동의해주세요.'), null);
      return;
    }

    if (
      idCheck1 &&
      idCheck2 &&
      !idCheck3 &&
      pw &&
      pwConfrim &&
      pw === pwConfrim &&
      !waring &&
      nicknameCheck1 &&
      nicknameCheck2 &&
      emailCheck &&
      emailCheck2 &&
      phone
    ) {
      if (nameLengthCheck !== 'pass') {
        alertShow(t('적절한 닉네임이 사용되었는지 확인해주세요.'), null);
        return;
      }
      confirmShow(t('회원가입 하시겠습니까?'), fnc);
    } else {
      alertShow(t('올바른 정보를 입력하였는지 확인해주세요.'), null);
    }
  };

  return (
    <>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          background: `url(${here_bg})no-repeat center center / cover`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            {/* <div className="col-md-7 col-lg-6 customFormTitle ">
              <div className="hero-content-left text-white">
                <h1 className="text-white">{t("계정을 만들어보세요!")}</h1>
                <p className="lead">
                  {t(
                    "엑스클래스에 회원가입하시고 회의 및 라이브, 교육 자료 공유 등 다양한 기능을 이용해 보세요!"
                  )}
                </p>
              </div>
            </div> */}
            <div className="col-md-5 col-lg-5 customForm signup">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <div className="mb-5 text-center">
                    <Link to="/login">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <form className="login-signup-form" autoComplete="false">
                    <input type="text" aria-hidden style={{ display: 'none' }} />
                    <input type="password" aria-hidden style={{ display: 'none' }} autoComplete="off" />
                    <div className="form-group">
                      <label className="pb-1">{t('아이디')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control isCheck"
                          placeholder={t('아이디 입력')}
                          value={id}
                          onFocus={() => setIdCheckState(true)}
                          onChange={e => {
                            setId(e.target.value);
                            setIdCheck1(/[a-z]/.test(e.target.value) && /^[a-z0-9_]{4,20}$/.test(e.target.value));
                            setIdCheck2(false);
                            setIdCheck3(
                              e.target.value.includes('xclass') ||
                                /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(e.target.value) ||
                                /[~!@#$%^&*()+|<>?:{}]/.test(e.target.value),
                            );
                          }}
                        />
                        <div
                          className={`check${idCheck2 ? ' checked' : ''}${
                            !idCheck1 || idCheck3 ? ' disabled' : ' active'
                          }`}
                          onClick={onClickIdCheck}
                        >
                          {t('중복확인')}
                        </div>
                      </div>
                      {idCheckState && (
                        <div className="guide">
                          {!idCheck1 && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('4~20 글자의 소문자와 숫자 조합')}</span>
                            </div>
                          )}
                          {!idCheck2 && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('아이디 중복확인')}</span>
                            </div>
                          )}
                          {idCheck3 && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('사용할 수 없는 아이디 입니다.')}</span>
                            </div>
                          )}
                          {idCheck1 && idCheck2 && !idCheck3 && (
                            <div className="guideItem active">
                              <img src={icCheckRight} alt="icon" className="right" />
                              <span>{t('사용 가능한 아이디 입니다.')}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="pb-1">{t('닉네임')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control isCheck"
                          placeholder={t('닉네임 입력')}
                          value={nickname}
                          onFocus={() => setNicknameCheckState(true)}
                          onChange={changeNickname}
                        />
                        <div
                          className={`check${nicknameCheck2 ? ' checked' : ''}${
                            nameLengthCheck !== 'pass' || !nicknameCheck1 ? ' disabled' : ' active'
                          }`}
                          onClick={onClickNicknameCheck}
                        >
                          {t('중복확인')}
                        </div>
                      </div>
                      {nicknameCheckState && (
                        <div className="guide">
                          {(nameLengthCheck === 'short' || nameLengthCheck === 'long') && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>
                                {t(`닉네임의 길이가 너무 ${nameLengthCheck === 'short' ? '짧습니다.' : '깁니다.'}`)}
                              </span>
                            </div>
                          )}
                          {!nicknameCheck1 && (
                            <div className={`guideItem${nicknameCheck1 ? ' active' : ''}`}>
                              {nickname.length === 0 && <img src={icCloseWrong} alt="icon" className="wrong" />}
                              {nickname.length > 0 && (
                                <img
                                  src={nicknameCheck1 ? icCheckRight : icCloseWrong}
                                  alt="icon"
                                  className={`${nicknameCheck1 ? 'right' : 'wrong'}`}
                                />
                              )}
                              <span>{t('한글 자음/모음, 특수문자, 공백 사용 불가능')}</span>
                            </div>
                          )}
                          {!nicknameCheck2 && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('닉네임 중복확인')}</span>
                            </div>
                          )}
                          {nicknameCheck1 && nicknameCheck2 && (
                            <div className="guideItem active">
                              <img src={icCheckRight} alt="icon" className="right" />
                              <span>{t('사용 가능한 닉네임 입니다.')}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="pb-1">{t('이메일')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control isCheck"
                          placeholder={t('이메일 입력')}
                          value={email}
                          onChange={changeEmail}
                        />

                        <div
                          className={`check${tryAuth ? ' checked' : ''}${!emailCheck ? ' disabled' : ' active'}`}
                          onClick={onClickEmailCheck}
                        >
                          {t('본인인증')}
                        </div>
                      </div>
                      {emailCheckState && (
                        <div className="guide">
                          {!emailCheck && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('올바른 이메일 주소를 입력하세요.')}</span>
                            </div>
                          )}
                          {!tryAuth && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('이메일 인증')}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {tryAuth && (
                      <div className="form-group">
                        <label className="pb-1">{t('이메일 인증')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control isCheck"
                            placeholder={t('인증 코드 입력')}
                            value={code}
                            onChange={e => setCode(e.target.value)}
                          />
                          <div
                            className={`check${emailCheck2 ? ' checked' : ''}${!code ? ' disabled' : ' active'}`}
                            onClick={onClickCodeCheck}
                          >
                            {t('인증하기')}
                          </div>
                        </div>

                        <div className="guide">
                          {!emailCheck2 ? (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <span>{t('이메일 인증을 완료해 주세요.')}</span>
                            </div>
                          ) : (
                            <div className="guideItem active">
                              <img src={icCheckRight} alt="icon" className="right" />
                              <span>{t('이메일 인증이 완료되었습니다.')}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label className="pb-1">{t('연락처')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('연락처 입력')}
                          value={phone}
                          onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pb-1">{t('비밀번호')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          // type={pwView ? 'text' : 'password'}
                          type="text"
                          className="form-control"
                          placeholder={t('비밀번호 입력')}
                          value={pw}
                          style={{ WebkitTextSecurity: pwView ? 'unset' : 'disc' }}
                          autoComplete="new-password"
                          onChange={e => {
                            setPw(e.target.value);
                            const numberCheck = /[0-9]/.test(e.target.value);
                            const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                            const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                            const spaceCheck = e.target.value.search(/\s/) === -1;
                            const double =
                              (numberCheck && alphabetCheck) ||
                              (numberCheck && specialCheck) ||
                              (alphabetCheck && specialCheck);
                            const triple = numberCheck && alphabetCheck && specialCheck;

                            setWarning(
                              !spaceCheck ||
                                e.target.value.length < 8 ||
                                (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                                (e.target.value.length > 9 && !double),
                            );
                          }}
                        />
                        <img
                          src={pwView ? icVisibilityOff : icVisibility}
                          alt="visibility"
                          onClick={() => setPwView(prev => !prev)}
                        />
                      </div>
                      <div className="guide">
                        {waring && (
                          <div className="guideItem">
                            <img src={icCloseWrong} alt="icon" className="wrong" />
                            <span>
                              {t(
                                '비밀번호는 공백 없이 영문, 숫자, 특수문자를 모두 포함하여 8자 이상 또는 영문, 숫자, 특수문자 중 2가지를 조합하여 10자리 이상으로 입력해주세요.',
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pb-1">{t('비밀번호 확인')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          // type={pwConfrimView ? 'text' : 'password'}
                          type="text"
                          style={{ WebkitTextSecurity: pwConfrimView ? 'unset' : 'disc' }}
                          className="form-control"
                          placeholder={t('비밀번호 확인 입력')}
                          value={pwConfrim}
                          autoComplete="new-password"
                          onChange={e => {
                            setPwConfirm(e.target.value);
                          }}
                        />
                        <img
                          src={pwConfrimView ? icVisibilityOff : icVisibility}
                          alt="visibility"
                          onClick={() => setPwConfirmView(prev => !prev)}
                        />
                      </div>
                      {pw.length > 0 && pwConfrim.length > 0 && (
                        <div className="guide">
                          {pw !== pwConfrim && (
                            <div className="guideItem">
                              <img src={icCloseWrong} alt="icon" className="wrong" />{' '}
                              <span>{t('비밀번호가 일치하지 않습니다.')}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="form">
                      <div className="subject">
                        <span>{t('서비스 약관 동의')}</span>
                      </div>
                      <div className="agree-all">
                        <div className="label" onClick={onAgreeAll}>
                          <div className={`checkbox${agree1 && agree2 && agree3 && agree4 ? ' selected' : ''}`}></div>
                          <div className="text">
                            <span>{t('모두 동의합니다.')}</span>
                          </div>
                        </div>
                        <div className="info">
                          <div className="text">
                            {t(
                              '동의에는 필수 및 선택 목적(광고성 정보 수신 포함)에 대한 동의가 포함되어 있으며, 선택 목적의 동의를 거부하시는 경우에도 서비스 이용이 가능합니다.',
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="agree">
                        <div className="item">
                          <div className="item__inner">
                            <div
                              className={`checkbox${agree1 ? ' selected' : ''}`}
                              onClick={() => {
                                setAgree({ ...agree, agree1: !agree1 });
                              }}
                            ></div>
                            <div className="text">
                              <span
                                onClick={() => {
                                  setAgree({ ...agree, agree1: !agree1 });
                                }}
                              >
                                [{t('필수')}] {t('엑스클래스 이용약관 동의')}
                              </span>
                              <span
                                className="detail"
                                onClick={() => {
                                  agreeShow();
                                  setAgreeType(1);
                                }}
                              >
                                {t('자세히 보기')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="item__inner">
                            <div
                              className={`checkbox${agree2 ? ' selected' : ''}`}
                              onClick={() => {
                                setAgree({ ...agree, agree2: !agree2 });
                              }}
                            ></div>
                            <div className="text">
                              <span
                                onClick={() => {
                                  setAgree({ ...agree, agree2: !agree2 });
                                }}
                              >
                                [{t('필수')}] {t('엑스클래스 개인정보처리방침 동의')}
                              </span>
                              <span
                                className="detail"
                                onClick={() => {
                                  agreeShow();
                                  setAgreeType(2);
                                }}
                              >
                                {t('자세히 보기')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="item__inner">
                            <div
                              className={`checkbox${agree3 ? ' selected' : ''}`}
                              onClick={() => {
                                setAgree({ ...agree, agree3: !agree3 });
                              }}
                            ></div>
                            <div className="text">
                              <span
                                onClick={() => {
                                  setAgree({ ...agree, agree3: !agree3 });
                                }}
                              >
                                [{t('필수')}] {t('전자 금융거래 이용약관 동의')}
                              </span>
                              <span
                                className="detail"
                                onClick={() => {
                                  agreeShow();
                                  setAgreeType(3);
                                }}
                              >
                                {t('자세히 보기')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="item__inner">
                            <div
                              className={`checkbox${agree4 ? ' selected' : ''}`}
                              onClick={() => {
                                setAgree4(!agree4);
                              }}
                            ></div>
                            <div className="text">
                              <span
                                onClick={() => {
                                  setAgree4(!agree4);
                                }}
                              >
                                [{t('선택')}] {t('마케팅 활용 동의')}
                              </span>
                              <span
                                className="detail"
                                onClick={() => {
                                  agreeShow();
                                  setAgreeType(4);
                                }}
                              >
                                {t('자세히 보기')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="my-4">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check-terms"
                        />
                        <label
                          className="custom-control-label"
                          for="check-terms"
                        >
                          I agree to the <a href="#/">terms and conditions</a>
                        </label>
                      </div>
                    </div> */}
                    <button
                      type="button"
                      className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                      onClick={onSignUp}
                    >
                      {t('회원가입')}
                    </button>
                    <div className="links">
                      <Link to="/login" className="small">
                        {t('로그인')}
                      </Link>
                      <div className="find content">
                        <Link to="/find/id" className="form-text small ">
                          {t('아이디 찾기')}
                        </Link>
                        <Link to="/find/pw" className="form-text small ">
                          {t('비밀번호 찾기')}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-img-absolute">
          <img src={wave} alt="wave shape" className="img-fluid" />
        </div>
      </section>

      <Agreement>
        {agreeType === 1 && <Agree1 />}
        {agreeType === 2 && <Agree2 />}
        {agreeType === 3 && <Agree3 />}
        {agreeType === 4 && <Agree4 />}
      </Agreement>
    </>
  );
}
