import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_AES_SECRETKEY;
const iv = process.env.REACT_APP_AES_IV; // 16자리 iv
let decrpytData = null;

// 암호화
export const encrypt = text => {
  decrpytData = null;
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(text), CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return cipher.toString();
};

// 복호화
export const decrypt = (encryptedText = null) => {
  if (!encryptedText) return {};
  if (decrpytData) return decrpytData;
  try {
    const decipher = CryptoJS.AES.decrypt(encryptedText.toString(), CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    decrpytData = JSON.parse(decipher.toString(CryptoJS.enc.Utf8));

    return JSON.parse(decipher.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    decrpytData = null;
    return {};
  }
};

export const randomString = (leng = 0) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  const stringLength = leng ?? 20;
  let randomstring = '';
  for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
};

export const formatDate = (date = null, lang, options) => {
  if (!date) return '';
  const dt = new Date(date);
  const dtf = new Intl.DateTimeFormat(lang, options);
  try {
    return dtf.format(dt);
  } catch (e) {
    return date;
  }
};

/**
 * db date를 year-month-day로 전처리
 * @param dateString
 * @returns
 */
export const preprocessDate = (dateString, delimiter = '') => {
  const delimiterString = delimiter ?? '-';
  const date = new Date(dateString);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  const result = `${year}${delimiterString}${month}${delimiterString}${day}`;
  return result ?? '';
};

export const preprocessFullDate = dateString => {
  let date = dateString;
  if (date?.includes('.')) date = date?.split('.')[0];
  if (date?.includes('T')) date = date?.replace('T', ' ');
  return date ?? '';
};

export const preprocessImageUrls = urls => {
  if (!urls) return [];

  return urls?.split(',');
};

export const preprocessTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemain = seconds % 60;
  return { minutes, secondsRemain };
  const result = `${minutes}분 ${secondsRemain}초`;
  return result ?? '';
};

export const preprocessParameters = (params, key, value = 0) => {
  let result = params;
  if (value) {
    if (result) result += `&${key}=${value}`;
    else result += `?${key}=${value}`;
  }
  return result;
};

export const preprocessMonthToNumber = month => {
  if (month.toLocaleLowerCase() === 'January'.toLocaleLowerCase()) return 1;
  if (month.toLocaleLowerCase() === 'February'.toLocaleLowerCase()) return 2;
  if (month.toLocaleLowerCase() === 'March'.toLocaleLowerCase()) return 3;
  if (month.toLocaleLowerCase() === 'April'.toLocaleLowerCase()) return 4;
  if (month.toLocaleLowerCase() === 'May'.toLocaleLowerCase()) return 5;
  if (month.toLocaleLowerCase() === 'June'.toLocaleLowerCase()) return 6;
  if (month.toLocaleLowerCase() === 'July'.toLocaleLowerCase()) return 7;
  if (month.toLocaleLowerCase() === 'August'.toLocaleLowerCase()) return 8;
  if (month.toLocaleLowerCase() === 'September'.toLocaleLowerCase()) return 9;
  if (month.toLocaleLowerCase() === 'October'.toLocaleLowerCase()) return 10;
  if (month.toLocaleLowerCase() === 'November'.toLocaleLowerCase()) return 11;
  return 12;
};

export const preprocessVodTime = secondsStr => {
  if (!secondsStr) return '';
  try {
    // eslint-disable-next-line radix
    const seconds = parseInt(secondsStr.replace('000', ''));

    let hour = `${Math.floor(seconds / 3600)}`;
    let min = `${Math.floor((seconds % 3600) / 60)}`;
    let sec = `${Math.floor(seconds % 60)}`;
    hour = hour.length === 1 ? `0${hour}` : hour;
    hour = hour === '00' ? '' : `${hour}:`;
    min = min.length === 1 && hour ? `0${min}` : min;
    min = min === '00' ? '' : `${min}:`;
    sec = sec.length === 1 && min ? `0${sec}` : sec;
    return `${hour}${min}${sec}`;
  } catch (e) {
    return '';
  }
};

export const checkEmail = email => {
  let regemail =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/i;
  if (!regemail.test(email)) return false;
  return true;
};

export const checkName = name => {
  let regname = /^[가-힣a-zA-Z]{2,}$/;
  if (!regname.test(name)) return false;
  return true;
};

export const checkId = name => {
  let regname = /^[a-zA-Z]{2,}$/;
  if (!regname.test(name)) return false;
  return true;
};

// $ 데이터                                                                                                            //
export const createVodItem = item => {
  return {
    unique: randomString(),
    key: item.vod ?? item.key,
    originalKey: item.originVodKey,
    title: item.title,
    status: item.status,
    thumbnail: item.thumbnail,
    description: item.description,
    url: item.url,
    duration: item.duration,
    progress: item?.progress,
    tags: item.tags,
    commentCount: item.commentCount,
    likeCount: item.likeCount,
    viewCount: item.viewCount,
    writerName: item.creatorName,
    writerKey: item.creator,
    startAt: item.startDate,
    endAt: item.endDate,
    createdAt: item.createdAt,
  };
};

export const createVodDetail = item => {
  return {
    unique: randomString(),
    key: item.vod,
    originalKey: item.originVodKey,
    title: item.title,
    status: item.status,
    thumbnail: item.thumbnail,
    description: item.description,
    url: item.url,
    duration: item.duration,
    progress: item.progress,
    commentCount: item.commentCount,
    likeCount: item.likeCount,
    viewCount: item.viewCount,
    writerName: item.creatorName,
    writerKey: item.creator,
    startAt: item.startDate,
    endAt: item.endDate,
    createdAt: item.createdAt,
    liked: item.like,
  };
};

export const createVodConmment = item => {
  return {
    unique: randomString(),
    key: item.key,
    writerName: item.writerName,
    writerKey: item.writer,
    comment: item.comment,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  };
};

export const createVodHistory = item => {
  return {
    unique: randomString(),
    key: item.key,
    progress: item?.progress,
    vod: createVodItem(item.vod),
  };
};

export const createReplayItem = item => {
  return {
    unique: randomString(),
    key: item.key,
    originalKey: item.originReplayKey,
    title: item.title,
    status: item.status,
    thumbnail: item.thumbnail,
    preview: item.preview,
    description: item.description,
    url: item.url,
    duration: item.duration,
    progress: item.progress,
    commentCount: item.commentCount,
    likeCount: item.likeCount,
    viewCount: item.viewCount,
    writerName: item.creatorName,
    writerKey: item.creator,
    startAt: item.startDate,
    endAt: item.endDate,
    createdAt: item.createdAt,
  };
};

export const createReplayDetail = item => {
  return {
    unique: randomString(),
    key: item.key,
    originalKey: item.originVodKey,
    title: item.title,
    status: item.status,
    thumbnail: item.thumbnail,
    description: item.description,
    url: item.url,
    duration: item.duration,
    progress: item.progress,
    commentCount: item.commentCount,
    likeCount: item.likeCount,
    viewCount: item.viewCount,
    writerName: item.creatorName,
    writerKey: item.creator,
    startAt: item.startDate,
    endAt: item.endDate,
    createdAt: item.createdAt,
    liked: item.like,
  };
};

export const createReplayHistory = item => {
  return {
    unique: randomString(),
    key: item.key,
    progress: item?.progress,
    vod: createReplayItem(item.vod),
  };
};

export const createReplayConmment = item => {
  return {
    unique: randomString(),
    key: item.key,
    writerName: item.writerName,
    writerKey: item.writer,
    comment: item.comment,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  };
};

export const createHomeNoticeCommunity = item => {
  return {
    unique: randomString(),
    key: item.bn_key,
    title: item.bn_title,
    content: item.bn_contents,
    categoryName: item.bc_name,
    type: 'notice',

    writerName: item.m_name,
    writerKey: item.m_key,
  };
};

export const createHomeFreeCommunity = item => {
  return {
    unique: randomString(),
    key: item.bc_key,
    title: item.bf_title,
    content: item.bf_contents,
    categoryName: item.bc_name,
    type: 'free',

    writerName: item.m_name,
    writerKey: item.m_key,
  };
};

export const autoHyphenPhone = value => {
  return value
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(\-{1,2})$/g, '');
};
