import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { TfiMenu } from 'react-icons/tfi';

import icLanguage from '../../../assets/img/icons/ic_language_color.svg';

import { languageStore } from '../../../stores/modal/languageStore';
import { loginStore, useLogout } from '../../../stores/user/loginStore';

import logo_white from '../../../assets/public_img/logo-white-xclass.svg';
import logo from '../../../assets/public_img/logo.svg';

import styles from './header.scss';

function Header({ darkBg, classOption }) {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const location = useLocation();
  const menuRef = useRef();
  const { xHome } = loginStore();
  const { logout } = useLogout();
  const headerRef = useRef();

  useEffect(() => {
    setHeaderTop(headerRef.current.offsetTop);
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
    const container = document.getElementsByClassName('root__container')[0];
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // TODO affix 처리하기(스크롤 후 메뉴 컬러주려면)
  // scss background color important 풀고 , 로고, 컬러, border 처리

  const handleScroll = () => {
    const container = document.getElementsByClassName('root__container')[0];
    setScroll(container.scrollTop);
  };

  const { setModal } = languageStore();
  return (
    <header className={`header__container header`}>
      <nav
        ref={headerRef}
        className={`navbar navbar-expand-lg fixed-top ${darkBg ? 'bg-transparent' : 'custom-nav white-bg'} ${
          scroll > headerTop ? 'affix' : ''
        }`}
      >
        <div className="container">
          <Link
            to="/"
            className="navbar-brand"
            onClick={() => {
              const container = document.getElementsByClassName('root__container')[0];
              if (container) container.scrollTo(0, 0);
            }}
          >
            <img src={logo} width="120" alt="logo" className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={menuRef}
          >
            <TfiMenu color="white" />
          </button>
          <div className="collapse navbar-collapse main-menu" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#promo">
                  {t('소개')}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#pricing">
                  {t('가격')}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#contact">
                  {t('도입문의')}
                </HashLink>
              </li>
              {xHome && (
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/demo">
                    {t('데모체험')}
                  </HashLink>
                </li>
              )}
              {/* <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#case">
                  {t('고객사례')}
                </HashLink>
              </li> */}
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/learningtalktalk">
                  {t('러닝톡톡')}
                </HashLink>
              </li>

              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/notice">
                  {t('공지사항')}
                </HashLink>
              </li>

              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  to="https://royal-cowl-d68.notion.site/ab4c22d8e29740a5a1a93dc1319fd0e7?pvs=4"
                  target="_blank"
                >
                  {t('가이드북')}
                </HashLink>
              </li>
              {!xHome ? (
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/login">
                    {t('로그인')}
                  </HashLink>
                </li>
              ) : (
                <>
                  {/* {!subscribeInfo && ( */}
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/pay">
                      {t('결제')}
                    </HashLink>
                  </li>
                  {/* )} */}
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/mypage">
                      {t('마이페이지')}
                    </HashLink>
                  </li>
                  <li className="nav-item" onClick={logout}>
                    <div className="logoutBtn">{t('로그아웃')}</div>
                  </li>
                </>
              )}
              {/* <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/blog">
                    {t("블로그")}
                  </HashLink>
                </li> */}
              <li className="nav-item" onClick={() => setModal(true)}>
                <img src={icLanguage} alt="language" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
