import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { planStore } from '../../../stores/mypage/planStore';

import styles from '../../../assets/scss/pay/plans.module.scss';

export default function Plans() {
  const { t } = useTranslation();
  const { discount, basicOptions, base } = planStore();

  if (!base) return null;

  return (
    <div className={styles.container}>
      <div className={styles.etc}>
        <div>({t('월')})</div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.table}>
          <div className={styles.tr}>
            <div className={styles.th}>{t('인원수')}</div>
            {discount?.map(v => (
              <div className={classNames(styles.td, styles.first)} key={v.number}>
                {v.number.toLocaleString('ko')}
                {t('명')}
              </div>
            ))}
          </div>
          <div className={styles.tr}>
            <div className={styles.th}>{t(base?.name_kor)}</div>
            {discount.map(value => (
              <div className={styles.td} key={base.p_key + value.number}>
                {Number((base.price * (100 - value.discount)) / 100).toLocaleString('ko')}
                {t('원')}
              </div>
            ))}
          </div>
          {basicOptions.map((v, i) => (
            <div className={styles.tr} key={v.p_key}>
              <div className={styles.th}>{t(v.name_kor)}</div>
              {v.type === 'module' ? (
                discount.map(value => (
                  <div className={styles.td} key={v.p_key + value.number}>
                    {Number((v.price * (100 - value.discount)) / 100).toLocaleString('ko')}
                    {t('원')}
                  </div>
                ))
              ) : (
                <div
                  className={classNames(styles.td, styles.merge, i === basicOptions.length - 1 && styles.last)}
                  key={v.p_key + v.type}
                >
                  {v.unit.toLocaleString('ko')}
                  GB {t('당')} {v.price.toLocaleString('ko')}
                  {t('원')}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
