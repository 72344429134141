import { useTranslation } from 'react-i18next';

import animation01 from '../../assets/public_img/hero-animation-01.svg';
import animation02 from '../../assets/public_img/hero-animation-02.svg';
import animation03 from '../../assets/public_img/hero-animation-03.svg';
import animation04 from '../../assets/public_img/hero-animation-04.svg';

export default function HeroFour() {
  const { t } = useTranslation();
  return (
    <section className="hero-section hero-section-2 ptb-100">
      <div className="circles">
        <div className="point animated-point-1"></div>
        <div className="point animated-point-2"></div>
        <div className="point animated-point-3"></div>
        <div className="point animated-point-4"></div>
        <div className="point animated-point-5"></div>
        <div className="point animated-point-6"></div>
        <div className="point animated-point-7"></div>
        <div className="point animated-point-8"></div>
        <div className="point animated-point-9"></div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6 col-lg-6">
            <div className="hero-content-left ptb-100 text-white">
              <h1 className="text-white">
                <span>{t('기업의 성장을 위한 교육 서비스')}</span>
              </h1>
              <p className="lead">
                {t(
                  '라이브 방송, VOD를 통해 직원 교육을 진행하고 스터디와 업무별 팁을 통한 학습 성취도 향상과 피드백까지! 엑스클래스와 함께 커뮤니케이션하며 여러 오프라인 매장을 교육, 관리, 점검, 평가하고 성장해 보세요.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="hero-animation-img">
              <img className="img-fluid d-block animation-one" src={animation04} alt="animation " />
              <img
                className="img-fluid d-none d-lg-block animation-two"
                src={animation01}
                alt="animation "
                width="120"
              />
              <img
                className="img-fluid d-none d-lg-block animation-three"
                src={animation02}
                alt="animation "
                width="120"
              />
              <img
                className="img-fluid d-none d-lg-block animation-four"
                src={animation03}
                alt="animation "
                width="230"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
