import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Footer from '../../components/layout/footer/Footer';
import Layout from '../../components/layout/Layout';

import styles from '../../assets/scss/mypage/index.module.scss';

function MyPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{t('마이페이지')}</div>
          <div className={styles.tab}>
            <div
              className={classNames(styles.item, pathname === '/mypage' && styles.active)}
              onClick={() => navigate('/mypage')}
            >
              {t('결제 관리')}
            </div>
            <div
              className={classNames(styles.item, pathname.includes('amount-used') && styles.active)}
              onClick={() => navigate('/mypage/amount-used')}
            >
              {t('사용량 조회')}
            </div>
            <div
              className={classNames(styles.item, pathname.includes('subscribe') && styles.active)}
              onClick={() => navigate('/mypage/subscribe')}
            >
              {t('구독 정보')}
            </div>
            <div
              className={classNames(styles.item, pathname.includes('plan') && styles.active)}
              onClick={() => navigate('/mypage/plan')}
            >
              {t('플랜 변경')}
            </div>
            <div
              className={classNames(
                styles.item,
                (pathname.includes('info') || pathname.includes('withdrawal')) && styles.active,
              )}
              onClick={() => navigate('/mypage/info/basic')}
            >
              {t('계정 관리')}
            </div>
          </div>
        </div>
        {pathname.includes('plan') ? (
          <Outlet />
        ) : (
          <div className={styles.contents}>
            <Outlet />
          </div>
        )}
      </div>
      <Footer space />
    </Layout>
  );
}

export default MyPage;
