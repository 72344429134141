import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import icPrev from '../../assets/img/icons/ic_arrow_prev_primary.svg';
import icNext from '../../assets/img/icons/ic_arrow_next_primary.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function TestimonialTwo({ hasBg }) {
  const { t } = useTranslation();

  const swiperRef = useRef();

  const data = [
    {
      d1: '영업 역량 강화를 통한 매출 확대',
      d2: '고객 L사는 다양한 영업 분야 중에서도 경쟁이 가장 치열한 가전 분야에 엑스클래스 솔루션을 도입하여 좋은 성과를 거두고 있습니다. 하나의 솔루션에 포함된 다양한 기능을 적극 활용하여, 지속적인 영업 매출 증가가 실현되고 있습니다.',
    },
    {
      d1: '실시간 방송을 통한 업무 방식의 변화',
      d2: '고객 U사는 2020년 코로나 바이러스 발생으로 인해 대부분의 업무가 온라인으로 전환되면서 실시간 방송을 다양한 업무 분야에 적용하고 있습니다. 실시간 방송과 업무를 결합한 새로운 시도로 좋은 반응을 얻고 있으며, 새로운 업무 분야에도 이를 적용하고 있습니다.',
    },
    {
      d1: '오프라인 과정의 온라인 변신',
      d2: '고객 K사는 지금까지의 교육 방식에서 벗어나 급변하는 경영 환경에 부응하는 새로운 교육 환경으로의 변화를 시도하고 있습니다. 모바일 및 PC 등 다양한 기종을 지원하며, 언제, 어디서나 학습이 가능한 새로운 교육 플랫폼을 적용하고 있습니다.',
    },
  ];
  return (
    <section id="case" className={`testimonial-section ptb-100 ${hasBg ? 'gray-light-bg ' : ''}`}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6">
            <div className="section-heading mb-5">
              {hasBg ? (
                <span className="text-uppercase color-secondary sub-title">{t('신뢰할 수 있는 고객')}</span>
              ) : (
                ''
              )}
              <h2>{t('고객 인터뷰')}</h2>
              <p className="lead">
                {t(
                  '엑스클래스의 강력한 솔루션을 성공적으로 활용한 사례를 확인하세요! 매출 증대, 영업 역량 강화, 인재 성장, 실시간 방송과 회의를 통한 업무 방식의 변화 등 다양한 사례를 확인할 수 있습니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <Swiper
              onBeforeInit={swiper => {
                swiperRef.current = swiper;
              }}
              loop
            >
              <div className="swiper-nav-btns">
                <div onClick={() => swiperRef.current?.slidePrev()} className="swiper-nav-btn prev">
                  <img src={icPrev} alt="img" />
                </div>
                <div onClick={() => swiperRef.current?.slideNext()} className="swiper-nav-btn next">
                  <img src={icNext} alt="img" />
                </div>
              </div>
              {data.map((v, i) => (
                <SwiperSlide key={i}>
                  <div className="item">
                    <div className="testimonial-quote-wrap">
                      <div className="media author-info mb-3">
                        <div className="media-body">
                          <h5 className="mb-0">{t(v.d1)}</h5>
                        </div>
                      </div>
                      <div className="client-say">
                        <p>{t(v.d2)}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
