import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TfiMenuAlt,
  TfiVideoCamera,
  TfiVideoClapper,
  TfiBookmarkAlt,
  TfiPencilAlt,
  TfiComments,
} from 'react-icons/tfi';

export default function FeatureImg({ ImgSource }) {
  const { t } = useTranslation();
  return (
    <div id="features" className="feature-section ptb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center mb-5">
              <span className="text-uppercase color-secondary sub-title">{t('특장점')}</span>
              <h2>{t('최고의 기능을 갖춘 간편한 프로세스')}</h2>
              <p>
                {t('다양한 경험을 통해 전문적인 기능을 제공합니다. 기업 교육에 필요한 모든 기능을 갖추고 있습니다.')}
                <br />
                {t(
                  '캘린더, 회의, 라이브, 체크리스트, 설문조사, 평가, 세일즈팁, VOD, 커뮤니티 등 다양한 기능과 함께 기업의 목표를 달성하세요!',
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="row row-grid align-items-center" style={{ rowGap: '10px' }}>
          <div className="col-lg-4">
            <div className="d-flex align-items-start mb-5">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-1 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiVideoClapper color="b17cc6" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('VOD')}</h5>
                <p className="mb-0">
                  {t(
                    'VOD 강의를 통해 언제 어디서든 학습하세요. 라이브 다시보기와 이어보기까지 다양한 기능을 제공합니다.',
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-2 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiVideoCamera color="#63b0e3" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('라이브')}</h5>
                <p className="mb-0">
                  {t(
                    '라이브 강의를 통해 실시간으로 강사와 소통하며 학습하세요. 스터디룸과 Q&A 기능을 통해 더욱 효과적인 학습을 경험하세요.',
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-3 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiBookmarkAlt color="#ed7265" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('세일즈팁')}</h5>
                <p className="mb-0">
                  {t(
                    '카테고리별 필수교육 자료를 확인하고 학습하세요. 영상, 문서, PPT 등 다양한 형태의 자료를 제공합니다.',
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="position-relative" style={{ zindex: 10, textAlign: 'center' }}>
              <img alt="placeholder" src={ImgSource} className="img-center img-fluid" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-start mb-5">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-4 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiMenuAlt color="#45d7ba" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('체크리스트')}</h5>
                <p className="mb-0">
                  {t(
                    '오프라인 매장 점검 사항을 체크리스트로 확인하세요. 매장별 점검 항목을 설정하여 매장별로 다르게 관리할 수 있습니다.',
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-5 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiPencilAlt color="#bd2130" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('평가')}</h5>
                <p className="mb-0">
                  {t('객관식, OX, 주관식 등 다양한 유형의 문제를 출제하고 문제와 보기를 섞어 부정행위를 방지합니다.')}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="pr-4">
                <div className="icon icon-shape icon-color-6 rounded-circle">
                  <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiComments color="#6582ed" />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5>{t('커뮤니티')}</h5>
                <p className="mb-0">
                  {t('기업별 커뮤니티 공간을 제공합니다. 기업 특성에 맞는 커뮤니티 공간을 만들어보세요.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
