import React from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/contact/Contact';
import Footer from '../components/layout/footer/Footer';
import Layout from '../components/layout/Layout';
import Subsribe from '../components/newsletter/Subsribe';
import PageHeader from '../components/team/PageHeader';
import Teams from '../components/team/Teams';
import BrandCarousel from '../components/testimonial/BrandCarousel';

export default function OurTeam() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('팀 소개')} Parent="Pages" PageTitle="Our Team" />
      <Teams />
      {/* <Contact bgColor="gray-light-bg" /> */}
      <BrandCarousel />
      {/* <Subsribe /> */}
      <Footer space />
    </Layout>
  );
}
