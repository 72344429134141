import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { confirmStore } from '../../stores/modal/confirmStore';

import Plans from '../pay/Plans';
import { Link } from 'react-router-dom';

export default function Price({ hasBg }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['XHOME']);
  const navigate = useNavigate();
  const { confirmShow } = confirmStore();

  const onNavPay = () => {
    if (!cookies.XHOME) {
      confirmShow(t('로그인 후 이용가능합니다.\n로그인하시겠습니까?'), () => {
        navigate('/login');
      });
    } else {
      navigate('/pay');
    }
  };

  const onClickDemo = () => {
    if (!cookies.XHOME) {
      confirmShow(t('로그인 후 이용가능합니다.\n로그인하시겠습니까?'), () => {
        navigate('/login');
      });
    } else {
      navigate('/demo');
    }
  };
  return (
    <div className="overflow-hidden">
      <section id="pricing" className={`package-section ${hasBg ? 'gray-light-bg' : 'background-shape-right'} ptb-100`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>{t('가장 합리적인 가격으로 만나보세요.')}</h2>
                <p className="lead">
                  {t('다양한 기능이 준비되어 있습니다. 사용 목적에 맞춰 원하는 기능을 선택하세요.')}
                  <br />
                  {t('아직 망설여 지신다면')}{' '}
                  <span onClick={onClickDemo} className="color-primary cursor-pointer border-bottom border-primary">
                    {t('데모체험')}
                  </span>
                  {t('을 해보세요.')}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Plans />
            </div>
          </div>
          <div className="mt-5 text-center">
            <p className="mb-2">
              {t('서비스를 바로 이용하시려면?')}{' '}
              <span className="color-secondary cursor-pointer" onClick={onNavPay}>
                {t('결제하러 가기')}
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
