import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';

import partner1 from '../../assets/img/partner/partner1.png';
import partner2 from '../../assets/img/partner/partner2.png';
import partner3 from '../../assets/img/partner/partner3.png';
import partner4 from '../../assets/img/partner/partner4.png';
import partner5 from '../../assets/img/partner/partner5.png';
import partner6 from '../../assets/img/partner/partner6.png';
import partner7 from '../../assets/img/partner/partner7.png';
import partner8 from '../../assets/img/partner/partner8.png';
import partner9 from '../../assets/img/partner/partner9.png';
import partner10 from '../../assets/img/partner/partner10.png';
import partner11 from '../../assets/img/partner/partner11.png';
import partner12 from '../../assets/img/partner/partner12.png';
import partner13 from '../../assets/img/partner/partner13.png';
import partner14 from '../../assets/img/partner/partner14.png';
import partner15 from '../../assets/img/partner/partner15.png';

const partners = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
];

export default function BrandCarousel({ hasBg }) {
  const { t } = useTranslation();
  const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: true,
    slidetransition: 'linear',
    autoplayTimeout: 4500,
    autoplayHoverPause: true,
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <section className={`client-section ptb-100 ${hasBg ? 'gray-light-bg' : ''}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center mb-5">
              <h2>
                {t('20년 노하우로')}
                <br />
                {t('스타트업에서 대기업까지')}
              </h2>
              <p className="lead">
                {t('엑스캐스트를 경험한 수 많은 기업들의 지지로 더욱 성장하고 있습니다.')}
                <br />
                {t('성장에 기여해주신 모든 고객님들께 감사드립니다.')}
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12">
            <OwlCarousel className="owl-carousel owl-theme clients-carousel dot-indicator" {...options}>
              {partners.map((v, idx) => (
                <div className="item single-client" key={idx}>
                  <img src={v} alt="client logo" className="client-img" />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}
