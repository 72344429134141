import { loadingStore } from "../../stores/loadingStore";

import logoSymbol1 from "../../assets/img/loading/logo_symbol1.svg";
import logoSymbol2 from "../../assets/img/loading/logo_symbol2.svg";
import logoSymbol3 from "../../assets/img/loading/logo_symbol3.svg";

import styles from "./loading.module.scss";

export default function Loading({ fixed }) {
  const { loading } = loadingStore();
  if (!loading) return null;
  return (
    <div
      className={styles.container}
      style={{ position: fixed ? "fixed" : "" }}
    >
      <div className={styles.space}>
        <img
          src={logoSymbol1}
          alt="icon"
          className={`${styles.loading} ${styles.loading1}`}
        />
        <img
          src={logoSymbol2}
          alt="icon"
          className={`${styles.loading} ${styles.loading2}`}
        />
        <img
          src={logoSymbol3}
          alt="icon"
          className={`${styles.loading} ${styles.loading3}`}
        />
      </div>
    </div>
  );
}
