import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '../../stores/user/loginStore';
import { useDemo, demoStore } from '../../stores/demo/demoStore';
import { alertStore } from '../../stores/modal/alertStore';
import { useSubscribeInfo, subscribeInfoStore } from '../../stores/mypage/subscribeInfoStore';

import PageHeader from '../../components/team/PageHeader';
import Footer from '../../components/layout/footer/Footer';
import Layout from '../../components/layout/Layout';
import styles from '../../assets/scss/demo/demo.module.scss';

function DemoPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { logout } = useLogout();
  const { data, setData } = demoStore();
  const { onDemoExperience } = useDemo();
  const { alertShow } = alertStore();
  const { getSubscribeInfo } = useSubscribeInfo();
  const { subscribeInfo } = subscribeInfoStore();

  const onChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    if (!data.company) {
      alertShow(t('회사명을 입력해주세요.'), null);
      return;
    }
    if (!data.name) {
      alertShow(t('담당자명을 입력해주세요.'), null);
      return;
    }
    if (!data.tel) {
      alertShow(t('연락처를 입력해주세요.'), null);
      return;
    }
    if (!data.email) {
      alertShow(t('이메일을 입력해주세요.'), null);
      return;
    }
    if (!data.empCount) {
      alertShow(t('직원수를 입력해주세요.'), null);
      return;
    }
    if (!data.sector) {
      alertShow(t('업종을 입력해주세요.'), null);
      return;
    }
    // if (!data.question) {
    //   alertShow(t('문의사항을 입력해주세요.'), null);
    //   return;
    // }
    const emailReg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!emailReg.test(data.email)) {
      alertShow(t('올바른 이메일 주소를 입력해주세요.'));
      return;
    }

    onDemoExperience(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSubscribeInfo();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (subscribeInfo && subscribeInfo?.subscribe?.sb_enable) {
      alertShow(t('이미 구독중입니다.\n구독 정보 페이지로 이동합니다.'), () => navigate('/mypage/subscribe'));
    }
  }, [subscribeInfo]);

  return (
    <Layout>
      <PageHeader HeaderTitle={t('데모체험')} Parent="Pages" PageTitle="Notice" />
      <div className={styles.container}>
        <div className={styles.explanation}>
          <div className={styles.title}>{t('엑스클래스를 7일간 무료로 체험해 볼 수 있는 기회!')}</div>
          <div className={styles.ex}>
            {t('데모체험을 원하신다면 아래의 정보를 모두 입력하신 후 체험하기 버튼을 눌러주세요.')}
          </div>
          {/* <div>{t("엑스클래스에 관한 더 자세한 사항이 궁금하시다면?")}
                          <span onClick={()=>navigate('/#contact')}>{t("문의하러 가기")}</span>
                      </div> */}
        </div>
        <div className={styles.form}>
          <form
            // action="#"
            method="POST"
            id="contactForm"
            className="contact-us-form"
          >
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    id="company"
                    placeholder={t('회사명')}
                    value={data.company}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder={t('담당자명')}
                    value={data.name}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="tel"
                    className="form-control"
                    id="tel"
                    placeholder={t('연락처')}
                    value={data.tel}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    size="40"
                    className="form-control"
                    id="email"
                    placeholder={t('이메일')}
                    value={data.email}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="number"
                    name="empCount"
                    className="form-control"
                    id="empCount"
                    placeholder={t('직원수')}
                    value={data.empCount}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="sector"
                    size="40"
                    className="form-control"
                    id="sector"
                    placeholder={t('업종')}
                    value={data.sector}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    name="question"
                    id="question"
                    className="form-control"
                    rows="7"
                    cols="25"
                    placeholder={t('문의사항')}
                    value={data.question}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center">
            <button type="button" className="btn solid-btn" id="btnContactUs" onClick={onSubmit}>
              {t('체험하기')}
            </button>
          </div>
        </div>
      </div>
      <Footer space />
    </Layout>
  );
}

export default DemoPage;
