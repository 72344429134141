import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import { TfiLocationPin, TfiMobile, TfiEmail, TfiInfoAlt } from 'react-icons/tfi';

import Subsribe from '../../newsletter/Subsribe';
import footer_bg from '../../../assets/public_img/footer-bg.png';
import logo_white from '../../../assets/public_img/logo-white-xclass.svg';

export default function Footer({ space }) {
  const { t } = useTranslation();
  return (
    <>
      <Subsribe />
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? 'pt-150' : 'pt-60'}`}
          style={{
            background: `url(${footer_bg}) no-repeat center top / cover`,
          }}
        >
          <div className="container">
            <div className="row justify-content-center" style={{ rowGap: '10px' }}>
              <div className="col-md-12 col-lg-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img src={logo_white} alt="footer logo" width="120" className="img-fluid mb-3" />
                  <p>
                    {t(
                      '엑스클래스를 사용하는 많은 고객사들과 함께 공동의 목표를 향해 노력하겠습니다. 이를 통해 엑스클래스 팀은 새로운 기능을 더 빠르고 강력하게 개발할 수 있습니다.',
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <HashLink smooth to="/#promo">
                            {t('소개')}
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink smooth to="/#about">
                            {t('특징')}
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink smooth to="/#features">
                            {t('기능')}
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink smooth to="/#pricing">
                            {t('가격')}
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink smooth to="/#screenshots">
                            {t('스크린샷')}
                          </HashLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="https://xcast.co.kr" target="_blank" rel="noreferrer">
                            {t('회사 소개')}
                          </a>
                        </li>
                        <li className="mb-2">
                          <HashLink smooth to="/our-team">
                            {t('팀 소개')}
                          </HashLink>
                        </li>
                        {/* <li
                          className="mb-2"
                        >
                          <HashLink
                            smooth
                            to="/blog"
                          >
                            블로그
                          </HashLink>
                        </li> */}
                        <li className="mb-2">
                          <HashLink smooth to="/#contact">
                            {t('도입문의')}
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <a
                            href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/dynamicmedia"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('채용안내')}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="mr-2">
                            <TfiInfoAlt color="white" />
                          </span>
                          <div>
                            {t('법인명 : 엑스캐스트 주식회사')}
                            <br />
                            {t('대표이사 : 신은철')}
                            <br />
                            {t('사업자등록번호 : 475-87-01882')}
                          </div>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="mr-2">
                            <TfiLocationPin color="white" />
                          </span>
                          <div>
                            {t('서울특별시 영등포구 국제금융로 8길 25, 807호(여의도동, 주택건설회관) (우)07332')}
                          </div>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="mr-2">
                            <TfiMobile color="white" />
                          </span>
                          <a href="tel:027176302">02-717-6302</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="mr-2">
                            <TfiEmail color="white" />
                          </span>
                          <a href="mailto:sec@xcast.kr">sec@xcast.kr</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">ⓒ 2024 xCast, All rights reserved</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <HashLink smooth to="/term-of-use" className="small-text">
                          {t('이용 약관')}
                        </HashLink>
                      </li>
                      <li className="list-inline-item">
                        <HashLink smooth to="/private" className="small-text">
                          {t('개인정보 처리방침')}
                        </HashLink>
                      </li>
                      {/* <li className="list-inline-item">
                        <HashLink smooth to="/copyright" className="small-text">
                          {t('저작권 정책')}
                        </HashLink>
                      </li> */}
                      <li className="list-inline-item">
                        <HashLink smooth to="/email-refuse" className="small-text">
                          {t('이메일무단수집 거부')}
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
