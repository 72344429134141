import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FaqStatic({ space }) {
  const { t } = useTranslation();
  return (
    <section className={`gray-light-bg  ${space ? 'ptb-100' : 'pb-60'}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('가격 책정은 어떻게 되나요?')}</h5>
              <p>
                {t('각 기능의 가격은 1인 기준의 월 비용입니다. 부가세는 별도이며 최소 계약 기간은 1개월입니다.')}
                <br />
                {t(
                  '인원수가 증가할수록 1인당 비용이 내려갑니다. 또한, 저장공간과 트래픽은 100GB당 가격이며, 추가 가능합니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('어떻게 지불하면 되나요?')}</h5>
              <p>{t('선 납부 형식이며 카드 등록 후 결제가 가능합니다. 결제는 1개월 혹은 12개월 단위로 진행됩니다.')}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('사용 중 기능을 추가하게 되면 요금은 어떻게 산정되나요?')}</h5>
              <p>
                {t(
                  '기능 추가 시 변경된 요금으로 즉시 결제하셔야 되며, 기능 추가도 바로 진행됩니다. 자동 결제일과 가격 또한 업그레이드 일자로 변경됩니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('세금계산서를 발급할 수 있나요?')}</h5>
              <p>
                {t(
                  '세금계산서 발급은 결제 후 당일 발급해드립니다. 세금계산서 발급이 필요하신 고객사 분들은 엑스캐스트 대표메일로 사업자 등록증을 보내주시면 발급해드립니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('원하는 기능만 결제할 수 있나요?')}</h5>
              <p>
                {t(
                  '플랜을 선택하여 모든 기능을 일괄 결제하는 기존의 LMS 방식과 다르게 원하는 기능만 선택하여 결제할 수 있습니다. 다만, 1인당 기본 요금이 존재합니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-faq">
              <h5>{t('추가 기능을 개발할 수 있나요?')}</h5>
              <p>
                {t(
                  '기존의 기능 이외에 추가 기능을 개발할 수 있습니다. 추가 기능 개발 시 추가 비용이 발생할 수 있습니다.',
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
