import { create } from "zustand";

export const agreeStore = create((set, get) => ({
  state: false,

  agreeShow: () =>
    set({
      state: true,
    }),
  agreeClose: () =>
    set({
      state: false,
    }),
}));
