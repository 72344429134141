import classNames from 'classnames';
import { useEffect, useState } from 'react';

// style
import styles from './pagination.module.scss';

// img
import icArrowFirst from '../../assets/img/icons/ic_arrow_first.svg';
import icArrowPrev from '../../assets/img/icons/ic_arrow_prev.svg';
import icArrowNext from '../../assets/img/icons/ic_arrow_next.svg';
import icArrowLast from '../../assets/img/icons/ic_arrow_last.svg';

function Pagination({ total, movePage }) {
  const [crrPage, setCrrPage] = useState(1);
  const [maxPage, setMaxPage] = useState(5);
  const onPage = e => {
    setCrrPage(e);
    setMaxPage(e === 0 ? 5 : e % 5 === 0 ? e : Math.floor(e / 5) * 5 + 5);
    movePage(e);
    window.postMessage('contentScollTop');
  };

  const pagination = e => {
    const pages = [];
    for (let i = 1; i <= e; i += 1) {
      pages.push(i);
    }
    return pages
      .filter(item => !(maxPage - item < 0 || maxPage - item >= 5))
      .map(element => (
        <div
          className={classNames(styles.page, crrPage === element && styles.active)}
          key={element}
          onClick={() => onPage(element)}
        >
          {element}
        </div>
      ));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onPage(1), [total]);

  const onPrev = () => {
    if (maxPage === 5) return;
    setCrrPage(maxPage - 9);
    setMaxPage(maxPage - 5);
    onPage(maxPage - 9);
  };

  const onNext = () => {
    if (maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0)) return;
    onPage(maxPage + 1);
    setMaxPage(maxPage + 5);
  };

  const onFirst = () => {
    if (maxPage === 5) return;
    setCrrPage(1);
    setMaxPage(5);
    onPage(1);
  };

  const onLast = () => {
    if (maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0)) return;
    const remain = total % 10 !== 0 ? 1 : 0;
    setMaxPage(Math.floor(total / 10) + remain);
    onPage(Math.floor(total / 10) + remain);
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.page, styles.prev, maxPage === 5 && styles.disabled)} onClick={onFirst}>
        <img src={icArrowFirst} alt="arrow" />
      </div>
      <div className={classNames(styles.page, styles.prev, maxPage === 5 && styles.disabled)} onClick={onPrev}>
        <img src={icArrowPrev} alt="arrow" />
      </div>
      {pagination(Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0))}
      <div
        className={classNames(
          styles.page,
          styles.next,
          maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0) && styles.disabled,
        )}
        onClick={onNext}
      >
        <img src={icArrowNext} alt="arrow" />
      </div>
      <div
        className={classNames(
          styles.page,
          styles.next,
          maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0) && styles.disabled,
        )}
        onClick={onLast}
      >
        <img src={icArrowLast} alt="arrow" />
      </div>
    </div>
  );
}

export default Pagination;
