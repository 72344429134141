import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TfiAlert } from 'react-icons/tfi';
import dayjs from 'dayjs';

import { alertStore } from '../../../stores/modal/alertStore';
import { subscribeInfoStore } from '../../../stores/mypage/subscribeInfoStore';
import { subscribeStore } from '../../../stores/pay/subscribeStore';

import styles from '../../../assets/scss/mypage/subscribe.module.scss';

import icCopy from '../../../assets/img/icons/ic_copy.svg';

function NowForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { subscribeInfo, nextPlanInfo } = subscribeInfoStore();
  const { basicOptions } = subscribeStore();
  const { alertShow } = alertStore();

  const onCopy = value => {
    navigator.clipboard.writeText(value);
    alertShow(t('주소가 복사되었습니다.'));
  };

  return (
    <div className={styles.plan}>
      {subscribeInfo.subscribe.sb_customer_code ? (
        <div className={styles.planItem}>
          <div className={styles.planItemTitle}>{t('기업코드')}</div>
          <div>{subscribeInfo.subscribe.sb_customer_code}</div>
        </div>
      ) : subscribeInfo.subscribe?.sb_enable ? (
        <div className={classNames(styles.planItem, styles.alert)}>
          <div className={styles.planItemTitle} onClick={() => navigate('/mypage/account')}>
            <span>
              <TfiAlert color="#bd2130" />
            </span>{' '}
            {t('기업 정보를 등록하십시오!')}
          </div>
        </div>
      ) : null}

      {subscribeInfo.subscribe.sb_customer_code && subscribeInfo.subscribe?.sb_enable && (
        <div className={styles.planItem}>
          <div className={styles.planItemTitle}>{t('링크')}</div>
          <div className={styles.planItemLinkContainer}>
            <div className={styles.planItemLinkWrapper}>
              <div
                className={styles.planItemLink}
                onClick={() => {
                  window.open(`/${subscribeInfo.subscribe.sb_customer_code}/login`, '_blank');
                }}
              >
                {t('사용자 페이지')}
              </div>
              <img
                src={icCopy}
                alt="copy"
                onClick={() =>
                  onCopy(`${process.env.REACT_APP_USER_URL}/${subscribeInfo.subscribe.sb_customer_code}/login`)
                }
              />
            </div>
            <div className={styles.planItemLinkWrapper}>
              <div
                className={styles.planItemLink}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_ADMIN_URL}/${subscribeInfo.subscribe.sb_customer_code}/login`,
                    '_blank',
                  );
                }}
              >
                {t('관리자 페이지')}
              </div>
              <img
                src={icCopy}
                alt="copy"
                onClick={() =>
                  onCopy(`${process.env.REACT_APP_ADMIN_URL}/${subscribeInfo.subscribe.sb_customer_code}/login`)
                }
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.planItem}>
        <div className={styles.planItemTitle}>{t('구독 기간')}</div>
        {dayjs(subscribeInfo.subscribe?.sb_start_date).format('YYYY-MM-DD')} ~{' '}
        {dayjs(subscribeInfo.subscribe?.sb_end_date).format('YYYY-MM-DD')}
        {subscribeInfo.subscribe?.sb_demo ? (
          subscribeInfo.subscribe?.sb_enable ? (
            <div style={{ color: 'var(--danger)', fontSize: '14px' }}>* {t('데모체험 이용중입니다.')}</div>
          ) : (
            <div style={{ color: 'var(--danger)', fontSize: '14px' }}>* {t('데모체험 기간이 종료되었습니다.')}</div>
          )
        ) : (
          !subscribeInfo.subscribe?.sb_enable && (
            <div style={{ color: 'var(--danger)', fontSize: '14px' }}>* {t('구독 기간이 만료되었습니다.')}</div>
          )
        )}
      </div>
      {subscribeInfo.subscribe?.sb_enable && (
        <>
          {!nextPlanInfo && (
            <div className={styles.planItem}>
              <div className={styles.planItemTitle}>{t('결제 금액')}</div>
              {subscribeInfo.subscribe?.sb_demo ? (
                <div>{t('없음')}</div>
              ) : (
                <div>
                  {Number(subscribeInfo.price).toLocaleString('ko-KR')}
                  {t('원')} / {subscribeInfo.subscribe?.sb_period === 'month' ? t('월') : t('연')}
                </div>
              )}
            </div>
          )}

          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('구독중인 기능')}</div>
            <div>
              {subscribeInfo.subscribe.sb_have_modules?.split(',').map((v, i) => {
                let name = '';
                basicOptions?.forEach(value => {
                  if (value.name === v) name = value.name_kor;
                });
                if (i === subscribeInfo.subscribe.sb_have_modules.split(',').length - 1) {
                  return <span key={name || i}>{name}</span>;
                }
                return <span key={name || i}>{name}, </span>;
              })}
            </div>
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('사용 인원')}</div>
            {subscribeInfo.subscribe?.sb_user_limit}
            {t('명')}
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('트래픽 제한')}</div>
            <div className={styles.explanationContainer}>
              {subscribeInfo.subscribe?.sb_traffic_limit || 0}
              GB
            </div>
          </div>
          <div className={styles.planItem}>
            <div className={styles.planItemTitle}>{t('저장공간')}</div>
            {subscribeInfo.subscribe?.sb_storage_limit || 0}GB
          </div>
        </>
      )}
    </div>
  );
}

export default NowForm;
