import React from 'react';
import Layout from '../components/layout/Layout';
import LoginScreen from '../components/others/LoginScreen';

export default function Login() {
  return (
    <Layout>
      <LoginScreen />
    </Layout>
  );
}
