import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Plans from './Plans';

import { subscribeStore, usePay } from '../../stores/pay/subscribeStore';

import styles from '../../assets/scss/pay/pay.module.scss';

import icMinus from '../../assets/img/icons/ic_minus.svg';
import icPlus from '../../assets/img/icons/ic_plus.svg';

export default function Contents() {
  const { t } = useTranslation();
  const {
    type,
    setType,
    number,
    setNumber,
    allBasicOptions,
    basicOptions,
    setBasicOptions,
    addOptions,
    setAddOptions,
    requiredTraffic,
    setRequiredTraffic,
    requiredStorage,
    setRequiredStorage,
  } = subscribeStore();
  const { allSelect, reset } = usePay();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <div className={styles.contents}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('서비스 이용 가격')}</div>
        <div className={styles.etc}>
          <div>* {t('가격은 1인 기준(월)입니다.')}</div>
          <div>* {t('인원수가 증가할수록 1인당 비용이 내려갑니다.')}</div>
          <div>* {t('저장공간과 트래픽은 100GB당 가격이며, 추가 가능합니다.')}</div>
          <div style={{ color: 'var(--primary)', fontSize: '14px' }}>
            * {t('기업 상황에 맞게 원하는 기능만 결제하고 서비스를 이용해보세요!')}
          </div>
        </div>
      </div>
      <Plans />
      <div className={styles.hr}></div>
      <div className={classNames(styles.module, styles.common)}>
        <div className={styles.commonTitle}>{t('결제 단위')}</div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <div
              className={classNames(styles.inputs, type === 'year' && styles.active)}
              onClick={() => setType('year')}
            >
              {t('연 결제')}
            </div>
            <div
              className={classNames(styles.inputs, type === 'month' && styles.active)}
              onClick={() => setType('month')}
            >
              {t('월 결제')}
            </div>
          </div>
          <div>
            <div className={styles.etc}>* {t('연 결제 시, 할인되어 10개월 요금으로 결제됩니다!')}</div>
            <div style={{ fontSize: '14px' }}>
              * {t('서비스 이용 기간은 결제일로부터 n개월입니다.', { n: type === 'year' ? 12 : 1 })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={classNames(styles.number, styles.common)}>
        <div className={styles.commonTitle}>{t('사용자 수')}</div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <div className={styles.inputs}>
              <img
                src={icMinus}
                alt="img"
                onClick={() => {
                  if (number - 10 <= 10) {
                    setNumber(10);
                    return;
                  }
                  setNumber(number - 10);
                }}
              />
              <input type="number" value={number} onChange={e => setNumber(Number(e.target.value))} />
              <img src={icPlus} alt="img" onClick={() => setNumber(number + 10)} />
            </div>
            {t('명')}
          </div>
          <div className={styles.etc}>
            <div>* {t('최소 10명부터 이용 가능합니다.')}</div>
            <div>* {t('10명 단위로 입력해주세요.')}</div>
          </div>
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={classNames(styles.options, styles.common)}>
        <div className={styles.commonTitle}>{t('기능 선택')}</div>
        <div className={styles.form}>
          <div className={styles.input} onClick={allSelect}>
            <div className={classNames(styles.checkbox, allBasicOptions && styles.active)}></div>
            {t('전체 선택')}
          </div>
          <div className={styles.datas}>
            {basicOptions.map((v, i) => {
              if (v.type === 'module')
                return (
                  <div
                    className={styles.input}
                    key={v.p_key}
                    onClick={() => {
                      const tmp = [];
                      let checkTraffic = false;
                      let checkStorage = false;
                      basicOptions.forEach(value => {
                        if (v.item === value.item) {
                          if (value.traffic && !value.checked) {
                            checkTraffic = true;
                          }
                          if (value.storage && !value.checked) {
                            checkStorage = true;
                          }
                          tmp.push({
                            ...value,
                            checked: !value.checked,
                          });
                        } else {
                          if (value.traffic && value.checked) {
                            checkTraffic = true;
                          }
                          if (value.storage && value.checked) {
                            checkStorage = true;
                          }
                          tmp.push(value);
                        }
                      });
                      if (!checkTraffic) setRequiredTraffic(false);
                      else setRequiredTraffic(true);
                      if (!checkStorage) setRequiredStorage(false);
                      else setRequiredStorage(true);
                      setBasicOptions(tmp);
                    }}
                  >
                    <div className={classNames(styles.checkbox, v.checked && styles.active)}></div>
                    {t(v.name_kor)}
                  </div>
                );
              return null;
            })}
          </div>
        </div>
      </div>
      {(requiredStorage || requiredTraffic) && (
        <>
          <div className={styles.hr}></div>
          <div className={classNames(styles.add, styles.common)}>
            <div className={styles.commonTitle}>{t('필수 옵션')}</div>
            <div className={styles.formContainer}>
              <div className={styles.form}>
                {addOptions.map(v =>
                  (v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage) ? (
                    <div className={styles.itemContainer} key={v.name}>
                      <div className={styles.item} key={v.name}>
                        <div className={styles.itemTitle}>
                          {t(v.name_kor)} {v.unit}GB
                        </div>
                        <div className={styles.priceContainer}>
                          <div className={styles.itemPrice}>
                            {v.price.toLocaleString('ko')}
                            {t('원')} / {t('1개')}
                          </div>
                          <div className={styles.inputContainer}>
                            <img
                              src={icMinus}
                              alt="img"
                              onClick={() => {
                                setAddOptions(
                                  addOptions.map(value => {
                                    if (v.item === value.item) {
                                      if (
                                        value.data <=
                                        ((v.name === 'traffic' && requiredTraffic) ||
                                          (v.name === 'storage' && requiredStorage))
                                          ? 1
                                          : 0
                                      )
                                        return value;
                                      return { ...value, data: value.data - 1 };
                                    }
                                    return value;
                                  }),
                                );
                              }}
                            />
                            <input
                              type="number"
                              value={v.data}
                              onChange={e =>
                                setAddOptions(
                                  addOptions.map(value => {
                                    if (v.item === value.item) {
                                      return {
                                        ...value,
                                        data: Number(e.target.value),
                                      };
                                    }
                                    return value;
                                  }),
                                )
                              }
                            />
                            <img
                              src={icPlus}
                              alt="img"
                              onClick={() =>
                                setAddOptions(
                                  addOptions.map(value => {
                                    if (v.item === value.item) {
                                      return { ...value, data: value.data + 1 };
                                    }
                                    return value;
                                  }),
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* {v.name === 'traffic' && (
                        <div className={styles.etc}>* {t('트래픽은 최소 1개 구매하셔야 합니다.')}</div>
                      )}
                      {v.name === 'storage' && (
                        <div className={styles.etc}>* {t('저장공간은 최소 1개 구매하셔야 합니다.')}</div>
                      )} */}
                    </div>
                  ) : null,
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
