import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { useLogout } from '../user/loginStore';

const api = process.env.REACT_APP_API;

export const demoStore = create((set, get) => ({
  data: {
    name: '',
    email: '',
    tel: '',
    company: '',
    empCount: '',
    sector: '',
    question: '',
  },
  setData: v =>
    set({
      data: v,
    }),
}));

export function useDemo() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setData } = demoStore();
  const { logout } = useLogout();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const onDemoExperience = async DemoData => {
    try {
      setLoading(true);
      await axios.post(`${api}/demo/request`, DemoData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });

      alertShow(t('데모체험 신청이 완료되었습니다. 확인 후 빠른 시일 내로 이메일을 통해 연락드리겠습니다.'), () => {
        setData({
          name: '',
          email: '',
          tel: '',
          company: '',
          empCount: '',
          sector: '',
          question: '',
        });
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.response?.data?.message === 'DEMO_ALREADY_REQUEST') {
        alertShow(t('이미 데모체험 신청내역이 있습니다.'));
        return;
      }
      alertShow(t('데모체험 신청에 실패했습니다.'));
      //   alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  return { onDemoExperience };
}
