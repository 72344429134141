import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/layout/footer/Footer';
import Layout from '../components/layout/Layout';
import PageHeader from '../components/team/PageHeader';
import PrivateComponent from '../components/private';

function Private() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('개인정보 처리방침')} Parent="Pages" PageTitle="Private Policy" />
      <PrivateComponent />
      <Footer space />
    </Layout>
  );
}

export default Private;
