const Agree = () => {
  return (
    <div className="WordSection1">
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "8.0pt",
          textAlign: "center",
          lineHeight: "normal",
          wordBreak: "normal",
        }}
      >
        <b>
          <span>엑스클래스 </span>
        </b>
        <span
          style={{
            position: "relative",
            zIndex: 251658240,
            left: "-1px",
            top: "0px",
            width: "226px",
            height: "82px",
          }}
        />
        <br clear="ALL" />
        <b>
          <span>전자금융거래 약관</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
          wordBreak: "normal",
        }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span>제</span> 1<span>장 총칙</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: ".2pt" }}>제</span>
          <span style={{ letterSpacing: ".2pt" }}>
            1<span>조 목적</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>2<span>조 용어의 정의</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span> 3<span>조 약관의 게시와 개정</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          <span>제</span> 2<span>장 서비스의 이용</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>4<span>조 전자지급결제 대행서비스의 종류</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.1pt" }}>제</span>
          <span style={{ letterSpacing: "-.1pt" }}>
            5<span>조 접근매체의 선정 및 관리</span>
          </span>
        </b>
        <span style={{ letterSpacing: "-.1pt" }}> </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>6<span>조 거래내용의 확인 및 보관기간</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>7<span>조 오류의 정정</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.15pt" }}>제</span>
          <span style={{ letterSpacing: "-.15pt" }}>
            8<span>조 거래지시의 철회 및 제한</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>9
          <span>조 전자지급결제 대행서비스 이용 기록의 생성 및 보존</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>10
          <span>
            조 이용자의{" "}
            <span style={{ letterSpacing: "-.05pt" }}>준수사항</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>11<span>조 회사의 책임</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>12<span>조 회사의 안정성 확보 의무</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.1pt" }}>제</span>
          <span style={{ letterSpacing: "-.1pt" }}>
            13<span>조 전자금융거래의 제공금지</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.15pt" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span style={{ letterSpacing: "-.15pt" }}>제</span>
          <span style={{ letterSpacing: "-.15pt" }}>
            {" "}
            3<span>장 기타</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>14<span>조 약관적용의 우선순위</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span> 15<span>조 손해배상</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.05pt" }}>제</span>
          <span style={{ letterSpacing: "-.05pt" }}>
            16<span>조 준거법 및 재판관할</span>
          </span>
        </b>
      </p>
      <p className="MsoNormal">
        <b>&nbsp;</b>
      </p>
      <p className="MsoNormal">
        <b>
          [<span>부칙</span>]
        </b>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "8.0pt",
          textAlign: "center",
          lineHeight: "normal",
          wordBreak: "normal",
        }}
      >
        <b>&nbsp;</b>
      </p>
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "103%",
          color: "black",
        }}
      >
        <br clear="all" style={{ pageBreakBefore: "always" }} />
      </span>
      <p className="MsoNormal">&nbsp;</p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span>제</span> 1<span>장 총칙</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: ".2pt" }}>제</span>
          <span style={{ letterSpacing: ".2pt" }}>
            1<span>조 목적</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span style={{ letterSpacing: ".2pt" }}>&nbsp;</span>
        </b>
        <span style={{ letterSpacing: "-.5pt" }}>이 약관은 엑스캐스트</span>
        <span style={{ letterSpacing: "-.5pt" }}>
          (<span>이하 </span>
        </span>
        <span
          style={{
            letterSpacing: "-.5pt",
          }}
        >
          ‘
        </span>
        <span style={{ letterSpacing: "-.5pt" }}>회사</span>
        <span
          style={{
            letterSpacing: "-.5pt",
          }}
        >
          ’
        </span>
        <span style={{ letterSpacing: "-.5pt" }}>
          )<span>와 이용자 사이의 </span>
        </span>
        <span style={{ letterSpacing: "-.65pt" }}>전자금융</span>
        <span style={{ letterSpacing: "-.3pt" }}>거</span>
        <span style={{ letterSpacing: "-.05pt" }}>래에 관한</span>
        <span style={{ letterSpacing: ".15pt" }}> 사항을 정함으로써</span>
        <span style={{ letterSpacing: ".15pt" }}>
          , <span>거래의 신속하고 효율적인 </span>
        </span>
        <span style={{ letterSpacing: ".3pt" }}>처리를 </span>
        <span style={{ letterSpacing: ".35pt" }}>도모하고 거래당</span>
        <span style={{ letterSpacing: ".2pt" }}>
          사자 상호간의 이해관계를 합리적으로 조정하는 것을 목적으로 합니다
        </span>
        <span style={{ letterSpacing: ".2pt" }}>.</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>2<span>조 용어의 정의</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;<span>이 약관에서 사용하는 용어의 의미는 다음과 같습니다</span>.{" "}
        <span>
          이 약관에서 별도로 정하지 아니한 용어는 「전자금융거래법」 및 관계
          법령 등에서 정하는 바에 따릅니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span style={{ letterSpacing: "-.2pt" }}>전자금융거래</span>
        <span style={{ letterSpacing: "-.2pt" }}>
          : <span>전자적 장치를 통하여 회사가 제공하는 금융상품</span>
        </span>
        <span style={{ letterSpacing: "-.05pt" }}> </span>
        <span style={{ letterSpacing: "-.2pt" }}>및 </span>
        <span style={{ letterSpacing: "-.25pt" }}>
          서비스를 이용자가 전자적 장치를 통하여 비대면
        </span>
        <span
          style={{
            letterSpacing: "-.25pt",
          }}
        >
          ·
        </span>
        <span style={{ letterSpacing: "-.25pt" }}>자동화된 방식으로 직</span>
        <span style={{ letterSpacing: "-.15pt" }}>접 이</span>
        <span style={{ letterSpacing: "-.05pt" }}>용하</span>
        <span>는 거래를 말합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.2pt" }}>2. </span>
        <span style={{ letterSpacing: "-.4pt" }}>이용자</span>
        <span style={{ letterSpacing: "-.4pt" }}>
          : <span>전자금융거래를 위하여 회사와 체결한 계약</span>(
          <span>이하</span> "<span>전자</span>
        </span>
        <span style={{ letterSpacing: "-.6pt" }}>금융거래계</span>
        <span style={{ letterSpacing: "-.5pt" }}>약</span>
        <span style={{ letterSpacing: "-.5pt" }}>
          "<span>이라 합니다</span>.)
          <span>에 따라 전자금융거래를 이용하는 고객을 말합</span>
        </span>
        <span style={{ letterSpacing: "-.2pt" }}>니다</span>
        <span style={{ letterSpacing: "-.2pt" }}>.</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        3. <span style={{ letterSpacing: "-.25pt" }}>지급인</span>
        <span style={{ letterSpacing: "-.25pt" }}>
          : <span>전자금융거래에 의하여 자금이 출금되는 계좌</span>(
          <span>이하</span>
        </span>
        <span style={{ letterSpacing: "-.15pt" }}> </span>
        <span
          style={{
            letterSpacing: "-.15pt",
          }}
        >
          “
        </span>
        <span style={{ letterSpacing: "-.15pt" }}>출금계좌</span>
        <span>”</span>
        <span>라 합니다</span>.)<span>의 명의인을 말합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        4. <span style={{ letterSpacing: "-.05pt" }}>수취인</span>
        <span style={{ letterSpacing: "-.05pt" }}>
          : <span>전자금융거래에 의하여 자금이 입금되는 계좌</span>(
          <span>이하 </span>
        </span>
        <span>“</span>
        <span>입금계좌</span>
        <span>”</span>
        <span>라 합니다</span>.)<span>의 명의인을 말합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        5. <span>전자지급수단</span>: <span>선불전자지급수단</span>,{" "}
        <span>신용카드 등 전자금융거래법 제</span>2<span>조</span> 11
        <span>호에서 정하는 전자적 방법에 따른 지급수단을 말합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        6. <span>전자지급거래</span>:{" "}
        <span>
          이용자가 회사에서 제공하는 재화를 구입하기 위해 회사에서 제공하는
          전자지급수단을 통하여 회사에게 자금을 이동하게 하는 전자금융거래를
          말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        7. <span>전자지급결제 대행서비스</span>:{" "}
        <span>
          이용자가 전자적 방법으로 재화를 구입함에 있어서 지급결제정보를
          송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는
          서비스를 말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        8. <span>전자적 장치</span>:{" "}
        <span>
          전자금융거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는
          장치로서
        </span>
        , <span>현금자동지급기</span>, <span>자동입출금기</span>,
        <span>지급용단말기</span>, <span>컴퓨터</span>,{" "}
        <span>
          전화기 등 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        9. <span style={{ letterSpacing: "-.25pt" }}>접근매체</span>
        <span style={{ letterSpacing: "-.25pt" }}>
          : <span>전자금융거래에 있어서 거래지시를 하거나 이용자 및</span>
        </span>
        <span style={{ letterSpacing: "-.1pt" }}> 거래</span>
        <span>
          내용의 진정성을 확보하기 위하여 사용되는 다음 각목의 어느 하나에
          해당하는
          <span style={{ letterSpacing: ".05pt" }}>
            {" "}
            수단 또는 정보를 말합니다
          </span>
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ① <span>은행이 제공한 전자식 카드 및 이에 준하는 전자적 정보</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ② <span>「전자서명법」에 따른 전자서명생성정보 또는 인증서</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ③ <span>은행에 등록된 이용자 번호</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ④ <span>등록되어 있는 이용자의 생체정보</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ⑤ 1<span>목 또는</span> 2
        <span>목의 수단이나 정보를 사용하는 데 필요한 비밀번호</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        10. <span style={{ letterSpacing: "-.3pt" }}>거래지시</span>
        <span style={{ letterSpacing: "-.3pt" }}>
          :{" "}
          <span>
            이용자가 전자금융거래계약에 의하여 금융기관 혹은 전자금융업자에게
          </span>
        </span>
        <span style={{ letterSpacing: "-.1pt" }}> </span>
        <span>전자금융거래의 처리를 지시하는 것을 말합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.05pt" }}>11. </span>
        <span style={{ letterSpacing: "-.6pt" }}>오류</span>
        <span style={{ letterSpacing: "-.6pt" }}>
          : <span>이용자의 고의 또는 과실 없이 전자금융거래가 약관</span>
        </span>
        <span style={{ letterSpacing: "-.2pt" }}>
          , <span>전자금융거래계약 또는 이용자가 거래지시한 대로 이</span>
        </span>
        <span style={{ letterSpacing: "-.25pt" }}>행</span>
        <span style={{ letterSpacing: "-.05pt" }}>
          되지 아니한 경우를 말합니다
        </span>
        <span style={{ letterSpacing: "-.05pt" }}>.</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span> 3<span>조 약관의 게시와 개정</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(1){" "}
        <span>
          회사는 이 약관의 내용을 회원이 쉽게 확인할 수 있도록 사이트 화면을
          통해 게시하며
        </span>
        , <span>앱 내부에도 이를 게시합니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(2) <span>회사는 약관의 규제에 관한 법률</span>,{" "}
        <span>
          정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지
          않는 범위에서 본 약관을 개정할 수 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(3) <span>회사는 약관을 개정하고자 할 경우 그 적용일자</span> 7
        <span>일 이전부터 적용일자 전일까지 공지합니다</span>.
        <span>다만 회원에게 불리한 약관 개정의 경우</span> 30
        <span>
          일 전부터 공지를 진행함과 동시에 일정기간 계정에 등록된 전자우편주소
        </span>
        , <span>앱 접속시 동의창 등</span> 1
        <span>
          개 이상의 전자적 수단을 통해 이를 회원에게 따로 명확히 통지합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(4) <span>회원은 변경된 약관에 대해 거부할 권리가 있습니다</span>.{" "}
        <span>
          본 약관의 변경에 대해 동의하지 않는 회원은 서비스 이용을 중단하고
          이용계약을 해지
        </span>
        (<span>회원탈퇴</span>)<span>할 수 있습니다</span>.{" "}
        <span>
          회원이 변경된 약관의 적용 이후에도 서비스를 계속 이용하는 경우에는
          변경된 약관에 동의한 것으로 간주합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          <span>제</span> 2<span>장 서비스의 이용</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>4<span>조 전자지급결제 대행서비스의 종류</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
        <span>
          회사가 제공하는 전자지급결제 대행서비스는 전자지급수단에 따라 다음과
          같이 구별됩니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span>신용카드결제 대행서비스</span>:{" "}
        <span>
          이용자가 결제대금을 지급하기 위하여 제공한 전자지급수단이 신용카드인
          경우로
        </span>
        , <span>회사가 전자결제시스템을 통한 신용카드 지불정보의 송</span>
        <span>·</span>
        <span>
          수신 및 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        2. <span>계좌이체 대행서비스</span>:{" "}
        <span>
          이용자가 결제대금을 회사의 전자지급결제 시스템을 통하여 금융기관에
          등록한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간
          송금 서비스를 말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        3. <span>가상계좌 서비스</span>:{" "}
        <span>이용자가 결제대금을 현금으로 결제하고자 하는 경우</span>,{" "}
        <span>
          회사의 전자지급결제 시스템을 통하여 자동으로 발급되는 이용자만의
          고유한 일회용 계좌를 발급 받아 결제대금의 지급이 이루어지는 서비스를
          말합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span style={{ letterSpacing: "-.1pt" }}>&nbsp;</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.1pt" }}>제</span>
          <span style={{ letterSpacing: "-.1pt" }}>
            5<span>조 접근매체의 선정 및 관리</span>
          </span>
        </b>
        <span style={{ letterSpacing: "-.1pt" }}> </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(1){" "}
          <span>
            회사는 전자지급결제 대행서비스 제공 시 접근매체를 선정하여 이용자의
            신원
          </span>
          , <span>권한 및 거래지시의 내용 등을 확인할 수 있습니다</span>.
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(2){" "}
          <span>
            이용자는 전자금융거래에 필요한 접근매체에 대하여 다른 법률에 특별한
            규정이 없는 한 다음 각 호의 행위를 하여서는 안되며
          </span>
          , <span>접근매체의</span>
        </span>
        <span> 도용</span>, <span>위조</span>,
        <span>변조를 방지하기 위한 관리에 충분한 주의를 기울여야 합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "10.0pt",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "28.3pt",
          lineHeight: "normal",
        }}
      >
        1. <span>접근매체를 제</span>3
        <span>자에게 대여하거나 사용을 위임하는 행위</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "10.0pt",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "28.3pt",
          lineHeight: "normal",
        }}
      >
        2. <span>접근매체를 제</span>3
        <span>자에게 양도 또는 담보 목적으로 제공하는 행위</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "10.0pt",
          marginBottom: "8.0pt",
          marginLeft: "0in",
          textAlign: "left",
          textIndent: "28.3pt",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.4pt" }}>3. </span>
        <span style={{ letterSpacing: "-.25pt" }}>제</span>
        <span style={{ letterSpacing: "-.25pt" }}>
          3
          <span>
            자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할{" "}
          </span>
        </span>
        <span style={{ letterSpacing: "-.3pt" }}>수 </span>
        <span style={{ letterSpacing: "-.2pt" }}>있음을 </span>
        <span style={{ letterSpacing: "-.4pt" }}>
          알았거나 쉽게 알 수 있었음에도 불구하고 접근매체를 누설
        </span>
        <span style={{ letterSpacing: "-.4pt" }}>
          ,<span>노</span>
        </span>
        <span style={{ letterSpacing: "-.3pt" }}>출</span>
        <span style={{ letterSpacing: "-.3pt" }}>
          , <span>방</span>
        </span>
        <span style={{ letterSpacing: "-.4pt" }}>치하는 행위</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.4pt" }}>
          &nbsp;(3){" "}
          <span>
            회사는 이용자로부터 접근매체의 분실 또는 도난 등의 통지를 받은
            때에는 그 때부터 제
          </span>
          3
          <span>
            자가 접근매체를 사용함으로써 이용자에게 발생한 손해를 배상해야
            합니다
          </span>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>6<span>조 거래내용의 확인 및 보관기간</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(1){" "}
        <span>
          회사는 사이트의 거래내역 조회 화면을 통하여 각 이용자의 거래내용
        </span>
        ,<span>오류정정 요구내역 및 처리결과에 대해 확인할 수 있도록 하며</span>
        ,{" "}
        <span>
          이용자의 거래 내용 서면교부 요청이 있는 경우에는 요청을 받은 날로부터
        </span>{" "}
        2<span>주 이내에 우편</span>, <span>모사전송</span>,{" "}
        <span>
          직접 교부 등의 방법을 통해 거래내용에 관한 서면을 교부합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(2){" "}
        <span>
          이용자가 조회할 수 있는 거래내용의 일부는 보관기간을 거래가 발생한
          일자부터
        </span>{" "}
        5<span>년으로 하며</span>,{" "}
        <span>해당되는 거래내용을 다음 각호와 같습니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span>거래계좌의 명칭 혹은 번호</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        2. <span>거래의 종류 및 금액</span>, <span>거래일시</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        3. <span>지급인을 나타내는 정보</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        4. <span>전자적 장치의 종류와 전자적 장치를 식별할 수 있는 정보</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        5. <span>이용자의 출금 동의에 관한 사항</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        6. <span>전자지급수단 이용과 관련된 거래승인에 관한 기록</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        7. <span>해당 전자금융거래와 관련한 전자적 장치의 접속기록</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(3){" "}
        <span>
          이용자가 조회할 수 있는 거래내용의 일부는 보관기간을 거래가 발생한
          일자부터
        </span>{" "}
        3<span>년으로 하며</span>,{" "}
        <span>해당되는 거래내용을 다음 각호와 같습니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span>이용자의 오류정정 요청사실 및 처리결과에 대한 사항</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>7<span>조 오류의 정정</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(1){" "}
        <span>
          이용자는 전자금융거래에 오류가 있음을 안 때에는 즉시 회사에 정정을
          요구할 수 있으며 이 경우 회사는 즉시 조사하여{" "}
          <span style={{ letterSpacing: "-.15pt" }}>
            필요한 조치를 취한 후 정정요구를 받은 날부터
          </span>
        </span>
        <span style={{ letterSpacing: "-.15pt" }}>
          {" "}
          2<span>주일 이내에 그</span>
        </span>
        <span> 원인과 처리결과를 이용자에게 알려야 합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: ".15pt" }}>
          &nbsp;(2){" "}
          <span>
            회사는 스스로 전자금융거래에 오류가 있음을 안 때에는 이를 즉시{" "}
          </span>
        </span>
        <span style={{ letterSpacing: ".3pt" }}>조</span>
        <span style={{ letterSpacing: ".15pt" }}>
          사하여 처리한 후 오류가 있음을 안 날부터
        </span>
        <span style={{ letterSpacing: ".15pt" }}>
          {" "}
          2<span>주일 이내에 그 원인과 처리결과를 이</span>
        </span>
        <span>용자에게 알려야 합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.15pt" }}>제</span>
          <span style={{ letterSpacing: "-.15pt" }}>
            8<span>조 거래지시의 철회 및 제한</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.15pt" }}>
          &nbsp;<span>이용자가 전자지급거래를 한 경우</span>,{" "}
          <span>
            이용자는 별도의 환불규정에서 지정된 기한 내에 청약을 철회함으로써
            거래지시를 철회하고 결제대금을 반환 받을 수 있습니다
          </span>
          . <span>단</span>, <span>금융기관</span>,{" "}
          <span>
            이동통신사 등의 규정 및 회사의 환불규정에 의거하여 거래지시 철회가
            제한될 수 있습니다
          </span>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.15pt" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>9
          <span>조 전자지급결제 대행서비스 이용 기록의 생성 및 보존</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(1){" "}
        <span>
          회사는 이용자가 전제지급결제 대행서비스 이용거래의 내용을 추적
        </span>
        ,
        <span>
          검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수
          있는 기록을 생성하여 보존합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;(2){" "}
        <span>회사가 보존하여야 하는 기록의 종류 및 보존밥법은 제</span>6
        <span>조</span> 2<span>항과</span> 3
        <span>항에서 정한 바에 따릅니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>10
          <span>
            조 이용자의{" "}
            <span style={{ letterSpacing: "-.05pt" }}>준수사항</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span style={{ letterSpacing: "-.05pt" }}>&nbsp;</span>
        </b>
        <span style={{ letterSpacing: "-.05pt" }}>
          전자금융거래의 안전한 수행을 위하여 이용자는
        </span>
        <span> 다음 각 호의 사항을 준수하여야 합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1.{" "}
        <span>
          비밀번호 유출 및 해킹 등 전자적 침해를 방지하기 위하여 필요한 조치 및
          관리방법
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        2. <span>이용자 보호를 위하여 회사가 제공하는 절차와 방법</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        3. <span>기타 회사가 정하는 사항</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>11<span>조 회사의 책임</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(1){" "}
          <span>
            회사는 다음 각 호의 어느 하나에 해당하는 사고로 인하여 이용자에게
            손해가 발생한 경우에는 그 손해를 배상할 책임을 집니다
          </span>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span>접근매체의 위조나 변조로 발생한 사고</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        2.{" "}
        <span>
          계약체결 또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        3.{" "}
        <span>
          전자금융거래를 위한 전자적 장치 또는 정보통신망 이용촉진 및 정보보호
          등에 관란 법률 제
        </span>
        2<span>조 제</span>1<span>항 제</span>1
        <span>
          호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로
          획득한 접근매체의 이용으로 발생한 사고
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(2) <span>회사는 제</span>1
          <span>
            항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 책임의
            전부 또는 일부를 이용자가 부담하게 할 수 있습니다
          </span>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        1. <span>이용자가 접근매체를 제</span>3
        <span>
          자에게 대여하거나 그 사용을 위임한 경우 혹은 양도나 담보 목적으로
          제공한 경우
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        2. <span>제</span>3
        <span>
          자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수
          있음을 이용자가 알았거나 쉽게 알 수 있었음에도 불구하고 접근매체를
          누설하거나 노출 또는 방치한 경우
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textAlign: "left",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>3. </span>
        <span style={{ letterSpacing: "-.1pt" }}>
          회사가 제공 및 요구하는 추가적인 보안조치를 이용자가 정당한 사유 없이
          거부하여 위 제
        </span>
        <span style={{ letterSpacing: "-.1pt" }}>
          1<span>항 제</span>3<span>호에 따른 사고가 발생한 경우</span>
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(3){" "}
          <span>
            회사는 이용자로부터의 거래지시가 있음에도 불구하고 컴퓨터 등
            정보통신설비의 보수점검
          </span>
          , <span>교체 및 고장</span>,
          <span>
            통신의 두절 등의 사유가 발생한 경우에는 전자금융서비스의 제공을
            일시적으로 중단할 수 있으며
          </span>
          ,{" "}
          <span>
            이로 인하여 이용자에게 손해가 발생한 경우에는 그 손해를 배상할
            책임이 있습니다
          </span>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>12<span>조 회사의 안정성 확보 의무</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
        <span>
          회사는 전자금융거래의 안정성과 신뢰성을 확보할 수 있도록 관리자로서의
          주의를 다하며
        </span>
        , <span>전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력</span>,{" "}
        <span>시설</span>,{" "}
        <span>
          전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가
          정하는 기준을 준수합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.1pt" }}>제</span>
          <span style={{ letterSpacing: "-.1pt" }}>
            13<span>조 전자금융거래의 제공금지</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;
          <span>
            회사는 전자지급거래를 제공함에 있어 취득한 이용자의 인적사항
          </span>
          , <span>이용자의 계좌</span>,{" "}
          <span>
            접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를
            금융실명법 등 법령에 의하거나 이용자의 동의를 얻지 아니하고는 제
          </span>
          3<span>자에게 제공</span>,{" "}
          <span>누설하거나 업무상 목적 외에 사용하지 않습니다</span>.
        </span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.15pt" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <b>
          <span style={{ letterSpacing: "-.15pt" }}>제</span>
          <span style={{ letterSpacing: "-.15pt" }}>
            {" "}
            3<span>장 기타</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span>14<span>조 약관적용의 우선순위</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.1pt" }}>
          &nbsp;(1){" "}
          <span>
            회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항
          </span>
        </span>
        <span style={{ letterSpacing: "-.05pt" }}>
          과 다를 때에는 그 합의사항을 이 약관에 우선하
        </span>
        <span>여 적용합니다</span>.
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.15pt" }}>
          &nbsp;(2){" "}
          <span>
            전자금융거래에 관하여 이 약관에 정하지 않은 사항은 개별약관이 정하
          </span>
        </span>
        <span style={{ letterSpacing: "-.05pt" }}>는 바에 따릅니다</span>
        <span style={{ letterSpacing: "-.05pt" }}>.</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.3pt" }}>
          &nbsp;(3){" "}
          <span>
            이 약관과 전자금융거래에 관한 개별약관에 정하지 않은 사항에 대하여
          </span>
        </span>
        <span style={{ letterSpacing: "-.2pt" }}>
          는 다른 약정이 없으면 「전자금융거래법」 등 관계 법령
        </span>
        <span style={{ letterSpacing: "-.2pt" }}>
          ,<span>예금거래기본약관 및 은행여신거래기본약</span>
        </span>
        <span>관</span>(<span>가계용</span>/<span>기업용</span>)
        <span>을 적용합니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span>제</span> 15<span>조 손해배상</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(1) <span>회사 또는 회원의 귀책사유로 인하여 상대방 또는 제</span>
        3
        <span>
          자에게 손해가 발생한 경우 귀책당사자는 해당 손해를 배상하여야 합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(2) <span>회사의 고의 또는 과실</span>,{" "}
        <span>
          약관위반으로 인해 회사에 귀책사유가 있는 경우 이로 인하여 회원이 입은
          통상손해에 대하여 회사는 배상하여야 합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(3) <span>회원의 고의 또는 과실</span>,{" "}
        <span>
          약관위반으로 인해 회원에 귀책사유가 있는 경우 이로 인하여 회사가 입은
          통상손해에 대하여 회원은 배상하여야 합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b>
          <span style={{ letterSpacing: "-.05pt" }}>제</span>
          <span style={{ letterSpacing: "-.05pt" }}>
            16<span>조 준거법 및 재판관할</span>
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{
          marginBottom: "8.0pt",
          textAlign: "left",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "-.05pt" }}>
          &nbsp;(1) <span>이 약관의 해석</span>
        </span>
        <span
          style={{
            letterSpacing: "-.05pt",
          }}
        >
          ·
        </span>
        <span style={{ letterSpacing: "-.05pt" }}>
          적용에 관하여는 대한민국법을 적용합니다
        </span>
        <span style={{ letterSpacing: "-.05pt" }}>.</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(2){" "}
        <span>
          이 약관에 명시되지 않은 사항은 관계법령 및 상관습에 따릅니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(3){" "}
        <span>회사와 회원 간 제기된 소송은 대한민국을 준거법으로 합니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(4){" "}
        <span>
          회사와 회원 간 발생한 분쟁의 경우 관할법원은 민사소송법이 정한 바에
          따릅니다
        </span>
        . <span>다만</span>,{" "}
        <span>
          해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 분쟁에 관한
          소송은 서울중앙지방법원을 관할 법원으로 합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        [<span>부칙</span>]
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(1) <span>이 약관은</span> 2022<span>년 </span>
        <span>◯</span>
        <span>월 </span>
        <span>◯◯</span>
        <span>일부터 적용됩니다</span>.
      </p>
    </div>
  );
};

export default Agree;
