import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import AppRoutes from './routes/Routes';
import { useLanguage } from './stores/modal/languageStore';
import { signUpStore } from './stores/user/signupStore';
import { subscribeStore } from './stores/pay/subscribeStore';
import { inquiryStore } from './stores/inquiry/inquiryStore';

import ScrollToTop from './components/others/ScrollToTop';
import Language from './modal/language';
import Alert from './modal/alert';
import Confirm from './modal/confirm';
import Loading from './components/layout/Loading';

import Header from './components/layout/header/Header';
import ChatContainer from './components/chat/ChatContainer';

import './assets/scss/common.scss';

function App() {
  const { pathname } = useLocation();
  const [cookies] = useCookies(['xclass-lang', 'XHOME', 'xclass-access-token']);
  const { changeLanguage } = useLanguage();
  const { complete } = signUpStore();
  const { complete: payComplete } = subscribeStore();
  const { complete: contactComplete } = inquiryStore();

  const setLang = () => {
    if (cookies['xclass-lang']) {
      changeLanguage(cookies['xclass-lang']);
      return;
    }
    changeLanguage(navigator.language.substr(0, 2));
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLang();
    window.addEventListener('message', message => {
      if (message.data === 'logout') {
        navigate('/');
      }
    });
    window.addEventListener('click', () => {
      const ele = document.getElementById('navbarSupportedContent');
      if (ele?.className.includes('show')) ele.classList.remove('show');
    });
  }, []);

  useEffect(() => {
    if (
      (!cookies.XHOME || !cookies['xclass-access-token']) &&
      (pathname.includes('mypage') || pathname === '/pay' || pathname === '/demo')
    )
      navigate('/');

    if (cookies.XHOME && (pathname === '/login' || pathname === '/signup' || pathname.includes('find'))) {
      navigate('/');
    }

    // complete는 모두 첫 방문 시에만 갈 수 있도록! (페이지 한번 나오면 다시 못들어가게~)
    if (pathname === '/signup/complete' && !complete) {
      navigate('/');
    }
    if (pathname === '/pay/complete' && !payComplete) {
      navigate('/');
    }
    if (pathname === '/contact/complete' && !contactComplete) {
      navigate('/');
    }
  }, [pathname]);
  return (
    <>
      <Header />
      <ScrollToTop />
      <Language />
      <Alert />
      <Confirm />
      <Loading />
      {/* <ChatContainer /> */}
      <div className="root__container">
        <AppRoutes />
      </div>
      <span style={{ display: 'none' }}>version 1.0.1</span>
    </>
  );
}

export default App;
