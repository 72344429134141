import { create } from 'zustand';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { useLogout, loginStore } from '../user/loginStore';

const accountApi = process.env.REACT_APP_ACCOUNT_API;

export const infoStore = create((set, get) => ({
  infoName: null,
  setInfoName: v =>
    set({
      infoName: v,
    }),

  infoPhone: null,
  setInfoPhone: v =>
    set({
      infoPhone: v,
    }),

  infoEmail: null,
  setInfoEmail: v =>
    set({
      infoEmail: v,
    }),

  infoId: null,
  setInfoId: v => set({ infoId: v }),
}));

export function useInfo() {
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setInfoName, setInfoEmail, setInfoPhone, setInfoId } = infoStore();
  const { logout } = useLogout();
  const [, setCookie] = useCookies(['xclass-expires', 'XHOME', 'xclass-name', 'xclass-marketing_status']);
  const { setXName } = loginStore();
  const { t } = useTranslation();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const getUserInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${accountApi}/user`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);

      setInfoName(data.data?.name);
      setInfoEmail(data.data?.email);
      setInfoPhone(data.data?.phone);
      setInfoId(data.data?.id);
    } catch (e) {
      setLoading(false);
      if (e.response.data.message === 'Internal server error') {
        alertShow(t('데이터를 불러오지 못했습니다. 다시 시도해주세요.'));
        return;
      }
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  const editInfo = async (name, phone, email, gender, birthday, job, marketing, referral_code, first) => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        `${accountApi}/user/info`,
        {
          name,
          phone,
          email,
          gender,
          birthday,
          job,
          marketing,
          referral_code,
          first,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      setLoading(false);
      setInfoName(name);
      setInfoEmail(email);
      setInfoPhone(phone);

      const option = { domain: process.env.NODE_ENV === 'development' ? '.xcast.co.kr' : 'xclass.co.kr', path: '/' };
      const expires = dayjs().add(6, 'h').toDate();

      setCookie('xclass-name', data.data?.name, { ...option, expires });
      setXName(CookieManager.get(`xclass-name`));

      // await getUserInfo();

      if (data.code === '1000') {
        alertShow(t('데이터가 존재하지 않습니다.'), null);
      } else {
        CookieManager.set('xName', data.data.name, 0.25);
        alertShow(t('변경되었습니다.'), null);
      }
    } catch (e) {
      setLoading(false);
      if (e.response.data.code === '8000') {
        logout();
        return;
      }
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  const editPassword = async (password, new_password) => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        `${accountApi}/user/password`,
        {
          password,
          new_password,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      setLoading(false);
      if (data.code === '1000') {
        alertShow(t('데이터가 존재하지 않습니다.'), null);
        return false;
      }
      alertShow(t('변경되었습니다.'), null);
      return true;
    } catch (e) {
      setLoading(false);
      if (e.response.data.code === '8000') {
        logout();
        return false;
      }
      alertShow(e.response.data.message_ko || e.response.data.message);
      return false;
    }
  };

  return { getUserInfo, editPassword, editInfo };
}
