import AboutGoal from '../../components/about/AboutGoal';
import Contact from '../../components/contact/Contact';
import FaqStatic from '../../components/Faqs/FaqStatic';
import FeatureImg from '../../components/features/FeatureImg';
import Footer from '../../components/layout/footer/Footer';
import Layout from '../../components/layout/Layout';
import Price from '../../components/prices/Price';
import Promo from '../../components/promo/Promo';
import Cta from '../../components/cta/Cta';
import Screenshot from '../../components/screenshot/Screenshot';
import BrandCarousel from '../../components/testimonial/BrandCarousel';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import HeroFour from './HeroFour';

import img10 from '../../assets/public_img/image-10.png';

export default function HomeFour() {
  return (
    <Layout>
      <HeroFour />
      <Promo />
      <AboutGoal />
      <FeatureImg ImgSource={img10} />
      <Price hasBg />
      <FaqStatic />
      <Screenshot />
      <Contact bgColor />
      <Cta />
      <TestimonialTwo hasBg />
      <BrandCarousel />
      <Footer space />
    </Layout>
  );
}
