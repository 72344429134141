import { create } from 'zustand';

export const confirmStore = create((set, get) => ({
  state: false,
  message: '',
  func: null,
  whiteSpace: 'pre-wrap',

  confirmShow: (msg, fnc = null, whiteSpace = 'pre-wrap') =>
    set({
      state: true,
      message: msg,
      func: fnc,
      whiteSpace,
    }),

  confirmCheck: () => {
    if (get().func) get().func();
    set({
      state: false,
      message: '',
      func: null,
      whiteSpace: 'pre-wrap',
    });
  },

  confirmClose: () => {
    set({ state: false, message: '', func: null, whiteSpace: 'pre-wrap' });
  },
}));
