import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotice, noticeStore } from '../../stores/notice/noticeStore';

import styles from '../../assets/scss/notice/detail.module.scss';

function NoticeDetail() {
  const { t } = useTranslation();
  const { param } = useParams();
  const { noticeDetail } = noticeStore();
  const { getNoticeDetail } = useNotice();
  const navigate = useNavigate();

  useEffect(() => {
    if (param === '') return;
    getNoticeDetail(param);
  }, [param]);

  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{noticeDetail?.cn_title}</div>
          <div className={styles.date}>{dayjs(noticeDetail?.created_at).format('YYYY-MM-DD')}</div>
        </div>
        <div className={styles.detail} dangerouslySetInnerHTML={{ __html: noticeDetail?.cn_contents || '' }} />
      </div>
      <div className={styles.btns}>
        <div className={styles.btn} onClick={() => navigate('/notice')}>
          {t('목록으로')}
        </div>
      </div>
    </div>
  );
}
export default NoticeDetail;
