import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PageHeader from '../../components/team/PageHeader';
import Footer from '../../components/layout/footer/Footer';
import Layout from '../../components/layout/Layout';

import Pay from '../../components/pay/Pay';

function PayPage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('결제')} Parent="Pages" PageTitle="Pay" />
      <Pay />
      <Footer space />
    </Layout>
  );
}

export default PayPage;
