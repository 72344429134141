import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/team/PageHeader';
import Footer from '../../components/layout/footer/Footer';
import Layout from '../../components/layout/Layout';

function NoticePage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('공지사항')} Parent="Pages" PageTitle="Notice" />
      <Outlet />
      <Footer space />
    </Layout>
  );
}

export default NoticePage;
