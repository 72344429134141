/* eslint-disable no-else-return */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useCookies } from 'react-cookie';
import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

import { useCard, cardStore } from '../../stores/pay/cardStore';
import { confirmStore } from '../../stores/modal/confirmStore';
import { useSubscribeInfo, subscribeInfoStore } from '../../stores/mypage/subscribeInfoStore';
import { alertStore } from '../../stores/modal/alertStore';
import { useLogout } from '../../stores/user/loginStore';
import { useSubscribe, usePay } from '../../stores/pay/subscribeStore';

// style
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../../assets/scss/mypage/payment.module.scss';

import icCalendar from '../../assets/img/icons/ic_calendar.svg';
import icClose from '../../assets/img/icons/ic_close.svg';
import icAdd from '../../assets/img/icons/ic_add_circle.svg';

import woori from '../../assets/img/banklogo/wr.png'; // 우리
import sinhan from '../../assets/img/banklogo/sh.png'; // 신한
import bc from '../../assets/img/banklogo/bc.png'; // 비씨
import hd from '../../assets/img/banklogo/hd.png'; // 현대
import hn from '../../assets/img/banklogo/hn.png'; // 하나
import kb from '../../assets/img/banklogo/kb.png'; // 국민
import lt from '../../assets/img/banklogo/lt.png'; // 롯데
import nh from '../../assets/img/banklogo/nh.png'; // 농협
import ss from '../../assets/img/banklogo/ss.png'; // 삼성
import ibk from '../../assets/img/banklogo/ibk.png'; // 기업

function Payment() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirmShow } = confirmStore();
  const { alertShow } = alertStore();
  const { logout } = useLogout();

  const { getPaylog, getSubscribeInfo, getTrafficInfo } = useSubscribeInfo();
  const { paylog, subscribeInfo } = subscribeInfoStore();
  const { getCardInfo, onCancelCard } = useCard();
  const { onToggleSubscribe, onReSubscribe } = useSubscribe();

  const { cardInfo } = cardStore();
  const [cookies] = useCookies(['xclass-lang', 'xclass-access-token', 'XHOME']);

  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      // await getTrafficInfo(dayjs(new Date()).format('YYYYMM'));
      await getSubscribeInfo();
      await getCardInfo();
      await getPaylog(dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'));
    };

    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    fetchData();
  }, []);

  const getCardData = cardName => {
    if (cardName.includes('신한')) return ['#0586c9', 'linear-gradient(180deg,#e6effd,#d8e4f0)', sinhan, '신한카드'];
    else if (cardName.includes('삼성')) return ['#035fa9', 'linear-gradient(180deg,#ddeaf4,#d7e1ee)', ss, '삼성카드'];
    else if (cardName.includes('국민')) return ['#9e6e0d', 'linear-gradient(180deg,#fef4d9,#f9e9bf)', kb, 'KB국민카드'];
    else if (cardName.includes('롯데')) return ['#000000', 'linear-gradient(180deg,#e9e9e9,#e1e1e1)', lt, '롯데카드'];
    else if (cardName.includes('하나')) return ['#03857f', 'linear-gradient(180deg,#daf2f0,#c9edeb)', hn, '하나카드'];
    else if (cardName.includes('우리'))
      return ['#0167ac', 'linear-gradient(180deg,#deebf6,#d5e1ed)', woori, '우리카드'];
    else if (cardName.includes('농협')) return ['#005bac', 'linear-gradient(180deg,#e1f3f7,#cae6ee)', nh, 'NH농협카드'];
    else if (cardName.includes('기업'))
      return ['#0156a3', 'linear-gradient(180deg,#ddeaf4,#d7e1ee)', ibk, 'IBK기업은행'];
    else if (cardName.includes('현대')) return ['#000000', 'linear-gradient(180deg,#e8eaec,#d7dade)', hd, '현대카드'];
    else if (cardName.includes('BC')) return ['#fa3246', 'linear-gradient(180deg,#fde7ec,#fcdae1)', bc, 'BC 바로카드'];
    return ['var(--primary)', 'white', null, '기타'];
  };

  const onClickCancelCard = () => {
    confirmShow(
      t(
        '카드 등록을 취소하는 경우 자동결제가 해지되며, 다음 결제일부터 구독이 취소됩니다.\n정말 카드 등록을 취소하시겠습니까?',
      ),
      () => {
        onCancelCard();
      },
    );
  };

  const onCancel = () => {
    if (subscribeInfo.subscribe?.sb_autopay)
      confirmShow(t('자동결제를 해지하면 다음 결제일부터 구독이 취소됩니다.\n정말 자동결제를 해지하시겠습니까?'), () =>
        onToggleSubscribe(false),
      );
    else {
      confirmShow(
        t('다음 결제일부터 자동결제가 다시 시작됩니다.\n자동결제를 등록하시겠습니까?', {
          price: Number(subscribeInfo.price).toLocaleString('ko-KR'),
          period: subscribeInfo.subscribe?.sb_period === 'month' ? t('월') : t('연'),
        }),
        () => {
          onToggleSubscribe(true);
        },
      );
    }
  };

  const onReSubs = () => {
    confirmShow(
      t('총 결제금액은 n원입니다.\n결제하시겠습니까?', { n: Number(subscribeInfo.price).toLocaleString('ko-KR') }),
      () => {
        onReSubscribe();
      },
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.explanation}>
        <div className={styles.item}>* {t('등록된 카드로 자동결제가 진행됩니다.')}</div>
        <div className={styles.item}>
          * {t('구독 기간이 10일 이상 지나 구독이 만료되는 경우 카드 정보가 삭제됩니다.')}
        </div>
      </div>
      <div className={styles.payment}>
        <div className={classNames(styles.item, styles.payments)}>
          <div className={styles.title}>{t('결제 정보')}</div>
          <div className={styles.data}>
            <div className={styles.smallTitle}>{t('카드 자동결제 예정액')}</div>
            <div className={styles.ammount}>
              {subscribeInfo?.price && cardInfo ? Number(subscribeInfo?.price).toLocaleString('ko-KR') : 0}
              {t('원')}
            </div>
          </div>
        </div>
        <div className={classNames(styles.item, styles.cards)}>
          <div className={styles.title}>
            <div className={styles.realTitle}>
              {t('등록 카드')}
              {/* <div className={styles.number}>{cardInfo ? 1 : 0}</div> */}
            </div>
          </div>
          <div className={styles.data}>
            {cardInfo ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    background: getCardData(cardInfo.cardName)[1],
                  }}
                >
                  {/* {subscribeInfo && !subscribeInfo?.subscribe?.sb_autopay && ( */}
                  <div className={styles.cardCancel} onClick={onClickCancelCard}>
                    <img src={icClose} alt="x" />
                  </div>
                  {/* )} */}
                  {getCardData(cardInfo.cardName)[2] && (
                    <img src={getCardData(cardInfo.cardName)[2]} alt="card" className={styles.cardImg} />
                  )}
                  <div className={styles.cardName}>{getCardData(cardInfo.cardName)[3]}</div>
                  <div className={styles.cardNumber} style={{ color: getCardData(cardInfo.cardName)[0] }}>
                    {cardInfo.cardNumber}
                  </div>
                </div>
                {subscribeInfo &&
                  (!dayjs(new Date()).isAfter(dayjs(subscribeInfo.subscribe.sb_end_date)) ? (
                    subscribeInfo.subscribe?.sb_autopay ? (
                      <div className={styles.btn} onClick={onCancel}>
                        {t('자동결제 해지')}
                      </div>
                    ) : (
                      <div className={styles.btn} onClick={onCancel}>
                        {t('자동결제 등록')}
                      </div>
                    )
                  ) : (
                    <div className={styles.btn} onClick={onReSubs}>
                      {t('재결제')}
                    </div>
                  ))}
              </>
            ) : (
              <div
                className={classNames(styles.add, !subscribeInfo?.subscribe?.sb_enable && styles.active)}
                onClick={() => {
                  if (!subscribeInfo?.subscribe?.sb_enable) navigate('/pay');
                }}
              >
                {subscribeInfo?.subscribe?.sb_enable &&
                !dayjs(new Date()).isAfter(dayjs(subscribeInfo.subscribe.sb_end_date)) ? (
                  <div>{t('구독 만료 후 다시 결제하실 수 있습니다.')}</div>
                ) : (
                  <img src={icAdd} alt="card" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.history}>
        <div className={styles.head}>
          <div className={styles.title}>{t('결제 내역')}</div>
          <div className={styles.search}>
            <div className={styles.input} style={{ maxWidth: '408px' }}>
              <img src={icCalendar} alt="img" />
              <DatePicker
                selected={startDate}
                onChange={async date => {
                  const tmpDate = new Date(dayjs(endDate).subtract(60, 'day')); // end - 60일
                  if (dayjs(date).isBefore(tmpDate)) {
                    // 선택한 날짜가 end - 60일보다 전이라면
                    alertShow(t('최대 60일까지 조회가능합니다.'), null);
                    return;
                  }
                  setStartDate(date);
                  await getPaylog(dayjs(date).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'));
                }}
                maxDate={endDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale={cookies['xclass-lang'] === 'ko' ? ko : en}
                dateFormat="yyyy-MM-dd"
                disabledKeyboardNavigation
                onChangeRaw={e => e.preventDefault()}
              />
              <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
              <DatePicker
                selected={endDate}
                onChange={async date => {
                  const tmpDate = new Date(dayjs(startDate).add(60, 'day')); // start + 60일
                  if (dayjs(date).isAfter(tmpDate)) {
                    // 선택한 날짜가 start + 60일보다 후라면
                    alertShow(t('최대 60일까지 조회가능합니다.'), null);
                    return;
                  }
                  setEndDate(date);
                  await getPaylog(dayjs(startDate).format('YYYY-MM-DD'), dayjs(date).format('YYYY-MM-DD'));
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                locale={cookies['xclass-lang'] === 'ko' ? ko : en}
                dateFormat="yyyy-MM-dd"
                disabledKeyboardNavigation
                onChangeRaw={e => e.preventDefault()}
              />
            </div>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <div className={styles.tr}>
              <div className={classNames(styles.th, styles.date)}>{t('거래일시')}</div>
              <div className={classNames(styles.th, styles.id)}>{t('아이디')}</div>
              <div className={classNames(styles.th, styles.ammount)}>{t('결제 금액')}</div>
              <div className={classNames(styles.th, styles.state)}>{t('결제 상태')}</div>
              <div className={classNames(styles.th, styles.etc)}>{t('비고')}</div>
            </div>
            {paylog.length > 0 ? (
              paylog.map(v => (
                <div className={styles.tr} key={v.pl_key}>
                  <div className={classNames(styles.td, styles.date)}>
                    {/* {dayjs(v.created_at).format('YYYY년 MM월 DD일 HH시mm분')} */}
                    {dayjs(v.created_at).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className={classNames(styles.td, styles.id)}>{v.pl_user_id}</div>
                  <div className={classNames(styles.td, styles.ammount)}>
                    {v.pl_amount.toLocaleString('ko-KR')}
                    {t('원')}
                  </div>
                  <div className={classNames(styles.td, styles.state)}>
                    {v.pl_status === 'SUCCESS'
                      ? t('결제 완료')
                      : v.pl_status === 'FAILED'
                      ? t('결제 실패')
                      : t('결제 취소')}
                  </div>
                  <div className={classNames(styles.td, styles.etc)}>{v.etc}</div>
                </div>
              ))
            ) : (
              <div className={styles.empty}>{t('결제 내역이 없습니다.')}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
