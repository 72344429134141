import { create } from 'zustand';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { useLogout } from '../user/loginStore';

const subscribeApi = process.env.REACT_APP_SUBSCRIBE_API;
const api = process.env.REACT_APP_API;

export const subscribeInfoStore = create((set, get) => ({
  subscribeInfo: null,
  setSubscribeInfo: v => {
    set({
      subscribeInfo: v,
    });
  },

  tab: 'now',
  setTab: v => {
    set({
      tab: v,
    });
  },

  nextPlanInfo: null,
  setNextPlanInfo: v => {
    set({
      nextPlanInfo: v,
    });
  },

  paylog: [],
  setPaylog: v => {
    set({
      paylog: v,
    });
  },

  trafficInfo: null,
  setTrafficInfo: v => {
    set({
      trafficInfo: v,
    });
  },

  trafficState: false,
  setTrafficState: v => set({ trafficState: v }),

  showCodeSubmit: false,
  setShowCodeSubmit: v => set({ showCodeSubmit: v }),
}));

export function useSubscribeInfo() {
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setSubscribeInfo, setPaylog, setTrafficInfo, setTrafficState, subscribeInfo, setNextPlanInfo } =
    subscribeInfoStore();
  const { logout } = useLogout();
  const { t } = useTranslation();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const getSubscribeInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);

      setSubscribeInfo(data.data);
    } catch (e) {
      setLoading(false);
      setSubscribeInfo(null);
      // 구독중이 아닌 상태
      if (
        e?.response?.data?.message === 'NO_SUBSCRIBE_USER' ||
        e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE'
      ) {
        return;
      }
      if (e?.response?.data?.message) alertShow(e?.response?.data?.message);
    }
  };

  const getNextPlan = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe/change/nextplan`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);

      if (data?.data) {
        setNextPlanInfo(data.data);
      }
    } catch (e) {
      setLoading(false);
      setNextPlanInfo(null);

      // 구독중이 아닌 상태
      if (
        e?.response?.data?.message === 'NO_SUBSCRIBE_USER' ||
        e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE'
      ) {
        return;
      }
      console.log(e);
      if (e?.response?.data?.message) alertShow(e?.response?.data?.message);
    }
  };

  const getPaylog = async (startDate, endDate) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe/paylog?start=${startDate}&end=${endDate} 23:59:59`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      if (data?.data?.length > 0) setPaylog(data.data.reverse());
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e?.response?.data?.message) alertShow(e?.response?.data?.message);
    }
  };

  const getTrafficInfo = async v => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe/traffic_info?date=${v}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setTrafficInfo(data.data);
      setLoading(false);
    } catch (e) {
      setTrafficInfo(null);
      setLoading(false);
      if (
        e?.response?.data?.message === 'NO_SUBSCRIBE_USER' ||
        e?.response?.data?.message === 'NOT_OR_EXPIRED_SUBSCRIBE'
      ) {
        setTrafficState('subscribe');
        return;
      }
      if (e?.response?.data?.message === 'NO_CUSTOMER_CODE') {
        setTrafficState('code');
        return;
      }
      alertShow(t('데이터를 불러오지 못했습니다. 다시 시도해주세요.'));
    }
  };

  const resetPw = async () => {
    try {
      setLoading(true);
      await axios.patch(
        `${api}/admin/customer/external/password/${subscribeInfo.subscribe.sb_customer_code}`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      alertShow(
        t(
          '관리자 비밀번호가 초기화되었습니다.\n기업 정보 등록 시 작성하신 이메일로 초기화된 관리자 계정 비밀번호를 발송해드렸으니 확인 바랍니다.',
        ),
      );
      setLoading(false);
    } catch (e) {
      console.log(e?.response);
      setLoading(false);
      alertShow(t('관리자 비밀번호 초기화에 실패했습니다.'));
    }
  };

  return { getSubscribeInfo, getPaylog, getTrafficInfo, resetPw, getNextPlan };
}
