import React from 'react';
import { useTranslation } from 'react-i18next';
import { TfiDashboard, TfiVector, TfiAlarmClock } from 'react-icons/tfi';

import about from '../../assets/public_img/about-img.png';

export default function AboutGoal() {
  const { t } = useTranslation();
  return (
    <section id="about" className="about-us ptb-100 gray-light-bg">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <div className="about-content-left section-heading">
              <span className="text-uppercase color-secondary sub-title">{t('우리의 목표에 관하여')}</span>
              <h2>{t('엑스클래스는 고객사의 비즈니스를 더 빠르게 발전시킬 수 있도록 도와드립니다.')}</h2>
              <br />
              <div className="single-feature mb-4">
                <div className="d-flex align-items-center mb-2">
                  <span className="rounded mr-3 icon icon-color-2">
                    <TfiDashboard color="#63b0e3" />
                  </span>
                  <h5 className="mb-0">{t('꾸준한 업데이트')}</h5>
                </div>
                <p>
                  {t(
                    '직원 교육과 매출 증대에 필요한 신규 기능들을 빠르게 추가하고 업데이트합니다. 새롭게 업데이트 되는 기능들에 대해서는 추가요금이 발생하지 않고 무료로 제공됩니다.',
                  )}
                </p>
              </div>
              <div className="single-feature mb-4">
                <div className="d-flex align-items-center mb-2">
                  <span className="rounded mr-3 icon icon-color-1">
                    <TfiVector color="#b17cc6" />
                  </span>
                  <h5 className="mb-0">{t('편리한 접근성')}</h5>
                </div>
                <p>
                  {t(
                    '별도의 프로그램을 설치하지 않고도 누구나 손쉽게 웹 브라우저 접속을 통해 접속할 수 있습니다. 다년간 쌓아온 사용자 경험을 바탕으로 최적의 인터페이스 환경을 제공합니다.',
                  )}
                </p>
              </div>

              <div className="single-feature mb-4">
                <div className="d-flex align-items-center mb-2">
                  <span className="rounded mr-3 icon icon-color-3">
                    <TfiAlarmClock color="#ed7265" />
                  </span>
                  <h5 className="mb-0">{t('고객지원')}</h5>
                </div>
                <p>{t('업무시간 내 전화나 메일로 문의하시면 영업일 기준 24시간 이내에 답변을 드립니다.')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-content-right">
              <img src={about} alt="about us" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
