import React from 'react';
import Layout from '../components/layout/Layout';
import FindId from '../components/others/FindId';

export default function FindIdPage() {
  return (
    <Layout>
      <FindId />
    </Layout>
  );
}
