import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { alertStore } from '../../stores/modal/alertStore';
import { inquiryStore, useInquiry } from '../../stores/inquiry/inquiryStore';

export default function Contact({ bgColor }) {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { data, setData } = inquiryStore();
  const { postInquiry } = useInquiry();

  const alertRef = useRef();

  const onChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    if (!data.customer) {
      alertShow(t('회사명을 입력해주세요.'), null);
      return;
    }
    if (!data.name) {
      alertShow(t('이름을 입력해주세요.'), null);
      return;
    }
    if (!data.phone) {
      alertShow(t('연락처를 입력해주세요.'), null);
      return;
    }
    if (!data.email) {
      alertShow(t('이메일을 입력해주세요.'), null);
      return;
    }
    if (!data.employee) {
      alertShow(t('직원수를 입력해주세요.'), null);
      return;
    }
    if (!data.business) {
      alertShow(t('업종을 입력해주세요.'), null);
      return;
    }
    if (!data.text) {
      alertShow(t('문의사항을 입력해주세요.'), null);
      return;
    }
    const emailReg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!emailReg.test(data.email)) {
      alertShow(t('올바른 이메일 주소를 입력해주세요.'));
      return;
    }

    postInquiry(data);

    // alertRef.current.className = "col-12 pb-3 message-box d-block";
  };
  return (
    <section id="contact" className={`contact-us ptb-100 ${bgColor ? 'gray-light-bg' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 pb-3 message-box d-none" ref={alertRef}>
            <div className="alert alert-success">{t('문의완료')}</div>
          </div>
          <div className="col-md-5">
            <div className="section-heading">
              <h2>{t('도입문의')}</h2>
              <p>
                {t(
                  '더 자세히 알고 싶거나 빠른 오픈을 원하신다면 지금 바로 문의주세요. 어떤 내용이라도 친절히 답변드릴게요. 엑스클래스가 우리 회사에 적합할지 맞춤 데모로 안내해드릴게요.',
                )}
              </p>
            </div>
            <div className="footer-address">
              <h6>
                <strong>{t('주소')}</strong>
              </h6>
              <p>{t('서울특별시 영등포구 국제금융로 8길 25, 807호(여의도동, 주택건설회관) (우)07332')}</p>
              <ul>
                <li>
                  <span>{t('연락처')} : 02-717-6302</span>
                </li>
                <li>
                  <span>
                    {t('이메일')} : <a href="mailto:sec@xcast.kr">sec@xcast.kr</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <form
              // action="#"
              method="POST"
              id="contactForm"
              className="contact-us-form"
            >
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="customer"
                      id="customer"
                      placeholder={t('회사명')}
                      value={data.customer}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder={t('이름')}
                      value={data.name}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder={t('연락처')}
                      value={data.phone}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      size="40"
                      className="form-control"
                      id="email"
                      placeholder={t('이메일')}
                      value={data.email}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      name="employee"
                      className="form-control"
                      id="employee"
                      placeholder={t('직원수')}
                      value={data.employee}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="business"
                      size="40"
                      className="form-control"
                      id="business"
                      placeholder={t('업종')}
                      value={data.business}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      name="text"
                      id="text"
                      className="form-control"
                      rows="7"
                      cols="25"
                      placeholder={t('문의사항')}
                      value={data.text}
                      onChange={onChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-3 d-flex justify-content-center">
                  <button type="button" className="btn solid-btn" id="btnContactUs" onClick={onSubmit}>
                    {t('문의하기')}
                  </button>
                </div>
              </div>
            </form>
            <p className="form-message"></p>
          </div>
        </div>
      </div>
    </section>
  );
}
