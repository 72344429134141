class CookieManager {
  static instance;

  static getInstance() {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;
  }

  get = (name) => {
    const tmp = encodeURIComponent(name);
    const cookies = document.cookie.split(";");
    const object = {};
    cookies.forEach((cookie) => {
      const data = cookie.split("=");
      const trim = data[0].trim();
      const value = data[1];
      object[trim] = value;
    });
    if (object[tmp]) return decodeURIComponent(object[tmp]);
    return null;
  };

  set = (name, value, expire, path, inside) => {
    this.remove(name);
    const date = new Date();
    date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
    let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    cookieText += `${expire ? `; EXPIRES=${date.toGMTString()}` : ""}`;
    cookieText += inside ? "" : "; DOMAIN=.xcast.co.kr";
    cookieText += `${path ? `; PATH=${path}` : "; PATH=/"}`;
    document.cookie = cookieText;
  };

  remove = (name, domain) => {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() - 36000);
    let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent("")}`;
    cookieText += `; EXPIRES=${exdate.toUTCString()}`;
    if (!domain) cookieText += "; DOMAIN=.xcast.co.kr";
    cookieText += "; PATH=/";
    document.cookie = cookieText;
  };
}

const instance = CookieManager.getInstance();
export default instance;
