import { create } from 'zustand';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useLogin } from './loginStore';
import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';

const accountApi = process.env.REACT_APP_ACCOUNT_API;

export const signUpStore = create((set, get) => ({
  tryAuth: false, // 이메일 인증 시도
  setTryAuth: v =>
    set({
      tryAuth: v,
    }),

  complete: false,
  setComplete: complete => set({ complete }), // 회원가입 성공 후 한번만 페이지 이동
}));

export function useSignUp() {
  const { alertShow } = alertStore();
  const { loading, setLoading } = loadingStore();
  const { onLogin } = useLogin();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTryAuth, setComplete } = signUpStore();

  const onIdCheck = async id => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${accountApi}/user/check?id=${id}`, {
        withCredentials: true,
      });
      setLoading(false);
      if (data.data.isDisplay === 1) {
        alertShow(t('이미 사용중인 아이디입니다.'), null);
        return false;
      }
      return true;
    } catch (e) {
      setLoading(false);
      alertShow(e?.response?.data?.message);
      return false;
    }
  };

  const onNicknameCheck = async nickname => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${accountApi}/user/check/name?name=${nickname}`, {
        withCredentials: true,
      });
      setLoading(false);
      if (data.data.isDisplay === 1) {
        alertShow(t('이미 사용중인 닉네임입니다.'), null);
        return false;
      }
      return true;
    } catch (e) {
      setLoading(false);
      alertShow(e.response.data.message_ko || e.response.data.message);
      return false;
    }
  };

  const join = async (id, name, password, marketing, phone, email, gender, birthday, job, referral_code) => {
    try {
      if (loading) return;
      setLoading(true);
      const { data } = await axios.post(
        `${accountApi}/user/signup`,
        {
          id,
          name,
          password,
          type: 'NORMAL',
          marketing,
          phone,
          email,
          gender,
          birthday,
          job,
          referral_code,
        },
        { withCredentials: true },
      );
      setLoading(false);
      if (data?.code === '1000') {
        alertShow(t('데이터가 존재하지 않습니다.'), null);
      } else {
        await onLogin(id, password);
        setTryAuth(false);
        setComplete(true);
        navigate('/signup/complete');
        // alertShow(t('가입되었습니다.'), async () => {
        //   await onLogin(id, password);
        //   setTryAuth(false);
        // });
      }
    } catch (e) {
      setLoading(false);
      alertShow(e?.response?.data?.message || t('회원가입에 실패하였습니다. 다시 시도해주세요.'), null);
    }
  };

  const onAuth = async ({ email }) => {
    try {
      // 인증 요청
      setLoading(true);
      const { data } = await axios.post(`${accountApi}/user/email`, {
        email,
      });
      setLoading(false);
      if (data.status === 'success' || data.statusCode === 201) {
        alertShow(t('회원님의 이메일로 인증 코드가 전송되었습니다.\n인증 코드를 입력해 이메일 인증을 완료해 주세요!'));
        setTryAuth(true);
      }
    } catch (e) {
      if (e?.response?.data?.message === 'EMAIL_DUPLICATION') {
        alertShow(t('이미 사용중인 이메일입니다.'));
      }
      setLoading(false);
      console.log(e);
    }
  };

  const onCodeCheck = async ({ email, code }) => {
    try {
      // 보안코드 확인
      setLoading(true);
      const { data } = await axios.post(`${accountApi}/user/email/code`, {
        email,
        code,
      });
      setLoading(false);
      if (data.status === 'success' || data.statusCode === 201) {
        return true;
      }
      return false;
    } catch (e) {
      if (e?.response?.data?.message === 'NOT_MATCH_FIND_CODE') {
        alertShow(t('인증번호가 일치하지 않습니다.'));
      }
      setLoading(false);
      return false;
    }
  };

  return { onIdCheck, onNicknameCheck, join, onAuth, onCodeCheck };
}
