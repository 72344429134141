const Agree = () => {
  return (
    <div className="WordSection1">
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "8.0pt",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <b>
          <span>엑스클래스 개인정보 처리방침</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          1. <span>수집하는 개인정보 항목 및 수집 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>수집하는 개인정보의 항목</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>개인정보 수집 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;3) <span>고유식별정보의 처리</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          2. <span>개인정보의 수집 및 이용목적</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1){" "}
          <span>
            서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>회원관리</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          3. <span>개인정보 보유 및 이용기간</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          4. <span>개인정보 파기절차 및 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>파기절차</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>파기방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;3) <span>개인정보 유효기간제</span>(<span>휴면계정 정책</span>)
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          5. <span>이용자 및 법정대리인의 권리와 그 행사방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          6. <span>개인정도의 기술적</span>/<span>관리적 보호 대책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>해킹 등에 대비한 대책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>처리 직원의 최소화 및 교육</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          7. <span>기타</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>외부 사이트 정보에 대한 책임 면책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>고지의 의무</span>
        </b>
      </p>
      <span
        style={{
          fontSize: "10.0pt",
          lineHeight: "103%",
          color: "black",
        }}
      >
        <br clear="all" style={{ pageBreakBefore: "always" }} />
      </span>
      <p className="MsoNormal">&nbsp;</p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          1. <span>수집하는 개인정보 항목 및 수집 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          1) <span>수집하는 개인정보의 항목</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.5pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ① <span>회사는 회원가입</span>,
        <span>
          서비스 제공을 위해 아래와 같은 최소한의 개인정보를 필수항목으로
          수집하고 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.5pt",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp; - <span>필수항목</span> : <span>아이디</span>(
        <span>고유식별번호</span>
        ), <span>이름</span>,<span>이메일</span>, <span>휴대폰 번호</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.5pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>
          회사는 이용자에게 보다 편리하고 특화된 서비스를 제공하고자 최소한의
          개인정보를 선택항목으로 수집하고 있습니다
        </span>
        .{" "}
        <span>
          선택 정보를 입력하지 않아도 서비스를 이용할 수 있으나 아이템 구매 및
          상품 수령에 제한이 있을 수 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          textIndent: ".5pt",
          lineHeight: "normal",
        }}
      >
        - <span>선택항목</span>: <span>계좌번호</span>,{" "}
        <span>결제 관련 정보</span>, <span>배송지 관련 정보</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>개인정보 수집 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "8.0pt",
          textIndent: "20.0pt",
          lineHeight: "normal",
        }}
      >
        <span>회사는 다음과 같은 방법으로 개인정보를 수집합니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "8.0pt",
          textIndent: "20.0pt",
          lineHeight: "normal",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp; - PC <span>사이트</span>,{" "}
        <span>모바일 앱</span>, <span>고객센터 문의내용</span>,{" "}
        <span>이메일</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;3) <span>고유식별정보의 처리</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          lineHeight: "normal",
        }}
      >
        -{" "}
        <span>
          수집된 고유식별정보는 개인정보보호법 기타 법률에서 규정하고 있는
          경우를 제외하고는 개인정보수집 목적 이외의 용도로 사용 및 제공되지
          않으며 암호화되어 안전하게 관리하고 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          2. <span>개인정보의 수집 및 이용목적</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1){" "}
          <span>
            서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>공통 서비스 제공</span>, <span>특정 맞춤 서비스 제공</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>회원관리</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>회원제 서비스 제공</span>, <span>개인식별</span>,{" "}
        <span>이용약관 위반 회원에 대한 이용제한 조치</span>,{" "}
        <span>
          서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재
        </span>
        , <span>가입의사 확인</span>, <span>가입 및 가입횟수 제한</span>, 19
        <span>세 미만 미성년자 결제 진행시 법정대리인 동의여부 확인</span>,{" "}
        <span>추후 법정 대리인 본인확인</span>,{" "}
        <span>분쟁 조정을 위한 기록보존</span>,{" "}
        <span>불만처리 등 민원처리</span>, <span>고지사항 전달</span>,{" "}
        <span>회원탈퇴 의사 확인</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          3. <span>개인정보 보유 및 이용기간</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>
          이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
          지체 없이 파기되나
        </span>
        ,{" "}
        <span>해지의 경우 데이터 복구요청 등 이용자의 보호를 위해 해지 후</span>{" "}
        7<span>일간 보관되며</span>, 7<span>일이 지난 후 즉시 삭제됩니다</span>.{" "}
        <span>단</span>, <span>상법</span>,{" "}
        <span>
          전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
          의거하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정 기간
          동안 회원 정보를 보관합니다
        </span>
        .{" "}
        <span>
          이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
          보존가간은 아래와 같습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>① </span>
        <span>계약 또는 청약철회 등에 관한 기록</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 이유</span> :{" "}
        <span>전자상거래 등에서의 소비자보호에 관한 법률</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 기간</span> : 5<span>년</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>대금결제 및 재화 등의 공급에 관한 기록</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 이유</span> :{" "}
        <span>전자상거래 등에서의 소비자보호에 관한 법률</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 기간</span> : 5<span>년</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>③ </span>
        <span>전자금융 거래에 관한 기록</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 이유</span> : <span>전자금융거래법</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 기간</span> : 5<span>년</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>④ </span>
        <span>
          소비자의 오류정정 요청 및 그 처리결과 또는 분쟁처리에 관한 기록
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 이유</span> :{" "}
        <span>전자상거래 등에서의 소비자보호에 관한 법률</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "40.0pt",
          lineHeight: "normal",
        }}
      >
        <span>보존 기간</span> : 3<span>년</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          4. <span>개인정보 파기절차 및 방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
        <span>
          이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
          지체 없이 파기합니다
        </span>
        .{" "}
        <span>
          다만 기타 법령에 의해 보관해야 하는 정보는 법령이 정한 일정 기간 동안
          별도의 분리 보관 후 즉시 파기합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>파기절차</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ①{" "}
        <span>
          이용자의 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기되며 기타
          법령에 따라 보관되어야 할 경우 별도로 분리 보관하며
        </span>
        ,{" "}
        <span>
          관련 법령 및 내부규정을 준수하여 일정기간 동안 안전하게 보관된 후 지체
          없이 파기합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>
          동 개인정보는 법령에서 명시한 경우를 제외하고는 보유목적 이외의 다른
          용도로 이용되지 않습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>파기방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "20.1pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        ①
        <span>
          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "20.1pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>
          전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
          방법을 사용하여 삭제합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;3) <span>개인정보 유효기간제</span>(<span>휴면계정 정책</span>)
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>① </span>1
        <span>
          년 동안 서비스 이용기록이 없는 휴면상태의 회원의 개인정보는 서비스를
          이용 중인 회원들의 개인정보와 분리하여 별도로 저장 및 관리됩니다
        </span>
        .{" "}
        <span>
          다만 휴면 상태의 회원의 요청이 있는 경우 서비스 이용을 재개하는 시점에
          다시 개인정보가 제공됩니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>회사는 휴면계정으로 전환되는 시점</span> 30
        <span>
          일 이전에 해당 내용을 이메일 등의 방법을 통하여 회원에게 이를
          고지합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>③ </span>
        <span>
          분리 보관된 개인정보는 기타 법령에 특별한 규정이 있는 경우를
          제외하고는 해당 개인정보를 이용하거나 제공하지 않습니다
        </span>
        .{" "}
        <span>
          또한 분리 보관된 개인정보는 관련 법령에서 명시하고 있는 일정기간이
          경과하면 지체 없이 파기되며
        </span>
        , <span>파기 시점을 기준으로</span> 30
        <span>
          일 이전에 해당 내용에 대해 이메일 등의 방법을 통하여 회원에게 이를
          고지합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          5. <span>이용자 및 법정대리인의 권리와 그 행사방법</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>① </span>
        <span>
          회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만
        </span>
        14
        <span>세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며</span>,{" "}
        <span>
          회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나
          가입해지를 요청하실 수 있습니다
        </span>
        .{" "}
        <span>
          다만 이 경우 회사의 서비스의 일부 또는 전부 이용이 어려울 수 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>② </span>
        <span>회원 혹은 만</span> 14
        <span>세 미만 아동의 개인정보 조회</span>, <span>수정을 위해서는 </span>
        <span>‘</span>
        <span>개인정보변경</span>
        <span>’</span>
        <span>을 통해</span>, <span>가입해지를 위해서는 </span>
        <span>‘</span>
        <span>회원탈퇴</span>
        <span>’</span>
        <span>를 클릭하여 본인 확인 절차를 거치신 후 직접 열람</span>,{" "}
        <span>정정하거나 탈퇴할 수 있습니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "20.0pt",
          textIndent: "0in",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontSize: "11.0pt" }}>③ </span>
        <span>
          회사는 회원 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는{" "}
        </span>
        <span>“</span>3. <span>개인정보 보유 및 이용기간</span>
        <span>”</span>
        <span>
          에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
          처리합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          6. <span>개인정도의 기술적</span>/<span>관리적 보호 대책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실</span>,{" "}
        <span>도난</span>,<span>유출</span>,{" "}
        <span>
          변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적
        </span>
        <span>•</span>
        <span>관리적 대책을 강구하고 있습니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>&nbsp;</b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1)&nbsp; <span>해킹 등에 대비한 대책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>
          회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
          훼손되는 것을 막기 위해 최선의 노력을 기울이고 있습니다
        </span>
        .<span>개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고</span>,{" "}
        <span>
          최신 백신 프로그램 사용 등의 방법을 통해 회원들의 개인정보가 유출
        </span>
        , <span>손상되지 않도록 방지하고 있습니다</span>.{" "}
        <span>
          더불어 기타 시스템적 보안성을 확보하기 위해 가능한 모든 기술적 장치를
          갖추어 노력을 다하고 있습니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>처리 직원의 최소화 및 교육</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>
          회사의 개인정보관련 처리 직원은 담당자에 한정하고 있고 이를 위한
          별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며
        </span>
        ,
        <span>
          담당자에 대한 수시 교육을 통하여 개인정보 취급방침을 준수할 것을 항상
          강조합니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          7. <span>기타</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;1) <span>외부 사이트 정보에 대한 책임 면책</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>
          회사의 서비스를 통해 회사 혹은 타 회원을 통해 타사의 웹사이트 혹은
          자료를 제공할 수 있습니다
        </span>
        .{" "}
        <span>
          이 경우 회사는 외부에서 유입되는 자료에 대한 아무런 통제권이 없으므로
          그로부터 제공받는 서비스나 자료의 유용성에 대한 책임이 없으며
        </span>
        ,{" "}
        <span>
          타 웹사이트에 접속하는 경우 해당 사이트의 개인정보 취급방침은 회사와
          무관하므로 해당 사이트의 정책을 확인하시기 바랍니다
        </span>
        .
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        <b>
          &nbsp;2) <span>고지의 의무</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{" "}
        <span>본 개인정보 처리방침은 관계 법령</span>,{" "}
        <span>보안기술의 변경에 따라 내용의 추가</span>,
        <span>삭제 및 수정이 있을 수 있으며</span>,{" "}
        <span>이 경우 변경되는 개인정보 처리방침 시행으로부터 최소</span> 7
        <span>일전 홈페이지의 공지사항을 통해 고지합니다</span>.
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        [<span>부칙</span>]
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "8.0pt", lineHeight: "normal" }}
      >
        &nbsp;(1) <span>이 정책은</span> 2022
        <span>년 </span>
        <span>3</span>
        <span>월 </span>
        <span>3</span>
        <span>일부터 적용됩니다</span>.
      </p>
    </div>
  );
};

export default Agree;
