import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { loadingStore } from '../loadingStore';
import { useLogin } from './loginStore';
import { alertStore } from '../modal/alertStore';

const accountApi = process.env.REACT_APP_ACCOUNT_API;

export const findIdStore = create((set, get) => ({
  secureCodePage: false,
  setSecureCodePage: v =>
    set({
      secureCodePage: v,
    }),
}));

export function useFindId() {
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { logout } = useLogin();
  const { setSecureCodePage } = findIdStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSecureCode = async (id, name, email, type) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${accountApi}/user/find`,
        {
          id,
          name,
          email,
          type,
        },
        { withCredentials: true },
      );
      if (data.code === '1000') {
        alertShow(t('데이터가 존재하지 않습니다.'), null);
      } else {
        setSecureCodePage(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // if (e.response.data.code === '8000') {
      //   logout();
      //   return;
      // }
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  const findId = async code => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${accountApi}/user/find?code=${code}&type=id`, {
        withCredentials: true,
      });
      setLoading(false);
      alertShow(t(`회원님의 아이디는 <span></span>입니다.`, { id: data.data.u_id }), () => navigate('/login'));
    } catch (e) {
      setLoading(false);
      if (e.response?.data?.message === 'NOT_MATCH_FIND_CODE') {
        alertShow(t('인증번호가 일치하지 않습니다.'), null);
        return;
      }
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  return { getSecureCode, findId };
}
