import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CookieManager from '../../assets/js/CookieManager';

import { alertStore } from '../modal/alertStore';
import { useLogout } from '../user/loginStore';
import { loadingStore } from '../loadingStore';

const accountApi = process.env.REACT_APP_ACCOUNT_API;

export function useWithdrawal() {
  const { alertShow } = alertStore();
  const { logout } = useLogout();
  const { t } = useTranslation();
  const { setLoading } = loadingStore();

  const onWithdrawal = async password => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${accountApi}/user`, {
        data: { password, type: null },
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      if (data.code === '1000') {
        alertShow(t('데이터가 존재하지 않습니다.'), null);
      } else {
        const fnc = () => {
          logout();
        };
        alertShow(t('탈퇴되었습니다.'), fnc);
      }
    } catch (e) {
      setLoading(false);
      if (e.response.data.code === '8000' || e.response.data.message === 'AUTH_ERROR') {
        logout();
        return;
      }
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  return { onWithdrawal };
}
