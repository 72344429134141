import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

// components
import ModalPortal from "../ModalPortal";
import Delay from "./delay";

// style
import styles from "./modal.module.scss";

function Modal({ close, children }) {
  const bgRef = useRef(null);

  return (
    <ModalPortal>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "linear" }}
        exit={{ opacity: 0 }}
        className={styles.container}
        ref={bgRef}
        onClick={(e) => {
          if (e.target === bgRef.current && close) close();
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <Delay delay={200}>{children}</Delay>
            </div>
          </div>
        </div>
      </motion.div>
    </ModalPortal>
  );
}

export default Modal;
