/* eslint-disable no-useless-catch */
/* eslint-disable no-else-return */
import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { confirmStore } from '../modal/confirmStore';
import { alertStore } from '../modal/alertStore';
import { loadingStore } from '../loadingStore';
import { useSubscribeInfo, subscribeInfoStore } from '../mypage/subscribeInfoStore';
import { infoStore } from '../mypage/infoStore';

import CookieManager from '../../assets/js/CookieManager';

const subscribeApi = process.env.REACT_APP_SUBSCRIBE_API;

export const subscribeStore = create((set, get) => ({
  base: null, // 기본 요금
  setBase: v => {
    set({
      base: v,
    });
  },

  type: 'year', // 결제 단위(기간)
  setType: v => {
    set({
      type: v,
    });
  },

  number: 10, // 사용인원수
  setNumber: v => {
    set({ number: v });
  },

  discount: [], // 할인율
  setDiscount: v => {
    set({ discount: v });
  },

  allBasicOptions: true,
  setAllBasicOptions: v => {
    set({ allBasicOptions: v });
  },

  basicOptions: [],
  setBasicOptions: v =>
    set({
      basicOptions: v,
    }),

  addOptions: [],
  setAddOptions: v => set({ addOptions: v }),

  requiredTraffic: true,
  setRequiredTraffic: v => set({ requiredTraffic: v }),

  requiredStorage: true,
  setRequiredStorage: v => set({ requiredStorage: v }),

  complete: false,
  setComplete: complete => set({ complete }),
}));

export const usePay = () => {
  // 단순 값 관리
  const {
    number,
    allBasicOptions,
    setAllBasicOptions,
    basicOptions,
    setBasicOptions,
    discount,
    setDiscount,
    addOptions,
    setAddOptions,
    type,
    setType,
    setNumber,
    base,
    setBase,
    requiredStorage,
    setRequiredStorage,
    requiredTraffic,
    setRequiredTraffic,
  } = subscribeStore();
  const { alertShow } = alertStore();
  const { t } = useTranslation();
  const { setLoading } = loadingStore();

  const getPriceList = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe/price`);
      // , {
      //   withCredentials: true,
      //   headers: {
      //     Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
      //   },
      // }
      setLoading(false);

      const basics = [];
      const adds = [];

      data.data.price.forEach(v => {
        if (v.p_type === 'base') {
          setBase({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            checked: true,
            price: v.p_base_amount,
            traffic: v.p_essential_traffic,
            storage: v.p_essential_storage,
          });
          return;
        }
        basics.push({
          p_key: v.p_key,
          item: v.p_name,
          name: v.p_name,
          name_kor: v.p_name_kor,
          type: v.p_type,
          unit: v.p_unit,
          checked: true,
          price: v.p_base_amount,
          traffic: v.p_essential_traffic,
          storage: v.p_essential_storage,
        });

        if (v.p_type === 'limit') {
          adds.push({
            p_key: v.p_key,
            item: v.p_name,
            name: v.p_name,
            name_kor: v.p_name_kor,
            type: v.p_type,
            unit: v.p_unit,
            price: v.p_base_amount,
            data: 1,
          });
        }
      });

      setDiscount([
        { number: 10, discount: 0 },
        ...Object.entries(data.data.discount).map(v => ({
          number: v[0],
          discount: v[1],
        })),
      ]);

      setBasicOptions(basics);
      setAddOptions(adds);
    } catch (e) {
      setLoading(false);
      alertShow(e.response?.data?.message || t('데이터를 불러오지 못했습니다. 다시 시도해주세요.'));
    }
  };

  const allSelect = () => {
    if (allBasicOptions) {
      setRequiredStorage(false);
      setRequiredTraffic(false);
    } else {
      setRequiredStorage(true);
      setRequiredTraffic(true);
    }
    setAllBasicOptions(!allBasicOptions);
    setBasicOptions(
      basicOptions.map(v => {
        return { ...v, checked: !allBasicOptions };
      }),
    );
  };

  const getPriceNoDiscount = v => {
    return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 12 : 1);
  };

  const getPrice = v => {
    if (number < 100) {
      return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 500) {
      return ((v * (100 - discount[1].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 1000) {
      return ((v * (100 - discount[2].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    } else if (number < 5000) {
      return ((v * (100 - discount[3].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    return ((v * (100 - discount[4].discount)) / 100) * number * (type === 'year' ? 10 : 1);
  };

  const getAllPriceNoDiscount = () => {
    let data = getPriceNoDiscount(base.price); // 기본요금
    basicOptions.forEach(v => {
      if (v.checked && v.type === 'module') data += getPriceNoDiscount(v.price);
    });
    addOptions.forEach(v => {
      if (v.data > 0 && ((v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage)))
        data += Number(v.price * v.data * (type === 'year' ? 12 : 1));
    });
    return data;
  };

  const getAllPrice = () => {
    let data = getPrice(base.price);
    basicOptions.forEach(v => {
      if (v.checked && v.type === 'module') data += getPrice(v.price);
    });
    addOptions.forEach(v => {
      if (v.data > 0 && ((v.name === 'traffic' && requiredTraffic) || (v.name === 'storage' && requiredStorage)))
        data += Number(v.price * v.data * (type === 'year' ? 10 : 1));
    });
    return data;
  };

  const reset = () => {
    setBase(null);
    setType('year');
    setNumber(10);
    setAllBasicOptions(true);
    setBasicOptions(
      basicOptions.map(v => {
        return { ...v, checked: true };
      }),
    );
    setAddOptions([]);
    setRequiredStorage(true);
    setRequiredTraffic(true);
  };

  return {
    allSelect,
    getPriceNoDiscount,
    getPrice,
    getAllPriceNoDiscount,
    getAllPrice,
    reset,
    getPriceList,
  };
};

export const useSubscribe = () => {
  // 결제 진행 로직
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const { number, type, basicOptions, addOptions, requiredTraffic, requiredStorage, setComplete } = subscribeStore();
  const { getAllPrice } = usePay();
  const { setLoading } = loadingStore();
  const navigate = useNavigate();
  const { infoName, infoEmail, infoPhone } = infoStore();
  const { getSubscribeInfo, getPaylog } = useSubscribeInfo();
  const { subscribeInfo, trafficInfo } = subscribeInfoStore();

  const setPayComplete = async (param, trafficLimit, storageLimit, modules) => {
    try {
      const { data } = await axios.post(
        `${subscribeApi}/subscribe`,
        {
          trafficLimit,
          storageLimit,
          userLimit: number,
          modules,
          month: type,
          cardInfo: {
            impUid: param.imp_uid,
            merchantUid: param.merchant_uid,
            customerUid: param.customer_uid,
            cardName: param.card_name,
            cardNumber: param.card_number,
            pgProvider: param.pg_provider,
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );

      if (data.status === 'success') return data;
      return false;
    } catch (error) {
      console.log(error);
      if (error.response?.data?.message === 'ALREADY_SUBSCRIBE') {
        alertShow(t('이미 구독중입니다.'));
      } else alertShow(t('결제에 실패하였습니다.'));
      return false;
    }
  };

  const setPayCancel = async () => {
    try {
      await axios.post(
        `${subscribeApi}/subscribe/cancel`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  async function callback(response, trafficLimit, storageLimit, modules) {
    if (response.success) {
      // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
      const result = await setPayComplete(response, trafficLimit, storageLimit, modules);
      setLoading(false);
      switch (result.status) {
        case 'success':
          // 결제 성공 시 로직
          // alertShow(t('결제가 완료되었습니다. 구독을 시작합니다.'), () => navigate('/mypage/subscribe'));
          setComplete(true);
          navigate('/pay/complete');
          break;
        default:
          console.log(result);
      }
    } else {
      // 결제 실패 시 로직
      await setPayCancel();
      setLoading(false);
      alertShow(`${t('결제에 실패하였습니다.')}\n${response.error_msg}.`);
    }
  }

  const onPayment = async () => {
    try {
      const fnc = async () => {
        // eslint-disable-next-line no-useless-catch
        try {
          setLoading(true);
          const { data } = await axios.get(`${subscribeApi}/subscribe/merchant_info`, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
            },
          });

          if (data.status === 'success' && data.data) {
            let trafficLimit = 0;
            let storageLimit = 0;
            const modules = [];
            basicOptions.forEach(v => {
              if (v.type === 'module' && v.checked) {
                modules.push(v.name);
              }
            });
            addOptions.forEach(v => {
              if (v.name === 'traffic' && requiredTraffic) {
                trafficLimit = v.unit * v.data;
              } else if (v.name === 'storage' && requiredStorage) storageLimit = v.unit * v.data;
            });

            try {
              const { IMP } = window;
              const userCode = 'imp26478312';
              let param = {
                pay_method: 'card',
                merchant_uid: data.data.merchant_uid,
                customer_uid: data.data.customer_uid,
                period: {
                  from: dayjs(new Date()).format('YYYYMMDD'),
                  to: dayjs(dayjs(new Date()).add(1, type)).format('YYYYMMDD'),
                },
                name: '엑스클래스 구독',
                amount: getAllPrice(),
                buyer_name: infoName,
                buyer_tel: infoPhone,
                buyer_email: infoEmail,
                buyer_addr: '',
                buyer_postcode: '',
                popup: true,
              };
              if (process.env.NODE_ENV === 'development') {
                param = {
                  ...param,
                  // 개발만 추가!
                  pg: 'danal_tpay.9810030929',
                };
              }

              IMP.init(userCode);
              IMP.request_pay(param, res => callback(res, trafficLimit, storageLimit, modules));
            } catch (error) {
              setLoading(false);
              throw error;
            }
          }
        } catch (e) {
          if (e?.response?.data?.message === 'PayFailed') {
            alertShow(t('결제에 실패하였습니다.'));
          }
          throw e;
        }
      };

      confirmShow(
        `${t('총 결제금액은 n원입니다.\n결제하시겠습니까?', { n: getAllPrice().toLocaleString('ko') })}`,
        () => {
          fnc();
        },
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onToggleSubscribe = async enable => {
    try {
      await axios.patch(
        `${subscribeApi}/subscribe/control/autopay`,
        { enable },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      if (enable)
        alertShow(t('자동결제가 등록되었습니다.\n다음 결제일부터 자동결제가 시작됩니다.'), () => getSubscribeInfo());
      else
        alertShow(
          t(
            '자동결제가 해지되었습니다.\n계속해서 구독하기를 원하신다면 구독 종료 일자 전에 다시 자동결제를 등록해주세요.',
          ),
          () => getSubscribeInfo(),
        );
    } catch (e) {
      alertShow(t(`자동결제${enable ? ' 등록' : ' 해지'}에 실패했습니다.\n다시 시도해주세요.`));
      console.log(e);
    }
  };

  const onReSubscribe = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${subscribeApi}/subscribe`,
        {
          trafficLimit: trafficInfo?.traffic?.trafficLimit || 0,
          storageLimit: trafficInfo?.storage?.storageLimit || 0,
          userLimit: number,
          modules: subscribeInfo.subscribe.sb_have_modules?.split(',').map(v => v),
          month: type,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      setLoading(false);
      if (data?.status === 'success') {
        alertShow(t('결제가 완료되었습니다.'), () => {
          getSubscribeInfo();
          const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
          const endDate = new Date();
          getPaylog(dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'));
        });
        return;
      }
      // todo error message
      alertShow(
        t('재결제에 실패하였습니다.\n카드 잔액을 확인하고 재결제를 시도하시거나, 카드를 제거 후에 다시 등록해 주세요.'),
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response?.data?.message === 'ALREADY_SUBSCRIBE') {
        alertShow(t('이미 구독중입니다.'), null);
        return;
      }
      alertShow(
        t('재결제에 실패하였습니다.\n카드 잔액을 확인하고 재결제를 시도하시거나, 카드를 제거 후에 다시 등록해 주세요.'),
      );
      // alertShow(t('구독이 만료되어 재결제에 실패하였습니다.\n결제페이지로 이동하여 결제를 진행해 주세요.'), () =>
      //   navigate('/pay'),
      // );
    }
  };

  return { onPayment, onToggleSubscribe, onReSubscribe };
};
