import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';

const api = process.env.REACT_APP_API;

export const noticeStore = create((set, get) => ({
  noticeList: [],
  setNoticeList: v =>
    set({
      noticeList: v,
    }),

  noticeDetail: {},
  setNoticeDetail: v =>
    set({
      noticeDetail: v,
    }),
}));

export function useNotice() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { setLoading } = loadingStore();
  const { setNoticeList, setNoticeDetail } = noticeStore();

  const getNoticeList = async page => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${api}/user/customer/notice/list?page=${page}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);

      setNoticeList(data.data);
    } catch (e) {
      setLoading(false);
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  const getNoticeDetail = async cn_key => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${api}/user/customer/notice/${cn_key}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);

      setNoticeDetail(data.data);
    } catch (e) {
      setLoading(false);
      alertShow(e.response.data.message_ko || e.response.data.message);
    }
  };

  return { getNoticeList, getNoticeDetail };
}
