import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/layout/footer/Footer';
import Layout from '../components/layout/Layout';
import PageHeader from '../components/team/PageHeader';
import CopyrightComponent from '../components/copyright';

function Copyright() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('저작권 정책')} Parent="Pages" PageTitle="copyright" />
      <CopyrightComponent />
      <Footer space />
    </Layout>
  );
}

export default Copyright;
