import React from 'react';
import { useTranslation } from 'react-i18next';

import { TfiCloud, TfiWorld, TfiLock } from 'react-icons/tfi';

function Promo() {
  const { t } = useTranslation();
  return (
    <section id="promo" className="promo-section ptb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-8">
            <div className="section-heading text-center mb-5">
              <h2>{t('왜 엑스클래스 인가요?')}</h2>
              <p className="lead">
                {t(
                  '저희 다이나믹미디어는 교육과 미디어 분야에 20년 이상의 업력을 가지고 다양한 노하우와 기술을 보유하고 있습니다. 다 년간 국내외 수많은 기업들과 함께 성장해 왔으며, 엑스클래스는 기업의 성장 원동력이 되게끔 저희의 모든 노하우를 담아냈습니다.',
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="row equal">
          <div className="col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
              <div className="circle-icon mb-5">
                <span
                  style={{
                    padding: '30px 35px 35px 35px',
                  }}
                >
                  <TfiCloud color="white" />
                </span>
              </div>
              <h5>{t('인프라')}</h5>
              <p>
                {t(
                  '엑스클래스는 AWS에서 동작합니다. AWS는 가장 유연하고 안전한 클라우드 컴퓨팅 환경입니다. 10명이하의 중소기업부터 1만명 이상의 대기업까지 가장 뛰어난 가용성을 제공합니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
              <div className="circle-icon mb-5">
                <span
                  style={{
                    padding: '30px 35px 35px 35px',
                  }}
                >
                  <TfiWorld color="white" />
                </span>
              </div>
              <h5>{t('커뮤니케이션')}</h5>
              <p>
                {t(
                  '강사가 참여자에게 단방향으로만 교육을 진행했던 기존의 LMS 시스템과 달리, 엑스클래스에서는 참여자들 스스로 교육에 필요한 영상이나 자료를 올려 공유하고 학습할 수 있습니다.',
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
              <div className="circle-icon mb-5">
                <span
                  style={{
                    padding: '30px 35px 35px 35px',
                  }}
                >
                  <TfiLock color="white" />
                </span>
              </div>
              <h5>{t('보안')}</h5>
              <p>{t('고객의 개인정보를 암호화하고 계층별 데이터 접근 권한 설정을 통해 데이터 유출을 방지합니다.')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Promo;
