import { create } from 'zustand';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CookieManager from '../../assets/js/CookieManager';

import { alertStore } from '../modal/alertStore';
import { loadingStore } from '../loadingStore';
import { useLogout } from '../user/loginStore';

const subscribeApi = process.env.REACT_APP_SUBSCRIBE_API;

export const cardStore = create((set, get) => ({
  cardInfo: null,
  setCardInfo: v => {
    set({
      cardInfo: v,
    });
  },
}));

export const useCard = () => {
  const { alertShow } = alertStore();
  const { setCardInfo } = cardStore();
  const { setLoading } = loadingStore();
  const { t } = useTranslation();
  const { logout } = useLogout();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.message === 'AUTH_ERROR') {
        logout();
        setLoading(false);
      } else return Promise.reject(error);
    },
  );

  const getCardInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${subscribeApi}/subscribe/card`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      if (data.data && data.status === 'success') {
        setCardInfo(data.data);
      }
    } catch (e) {
      setLoading(false);
      setCardInfo(null);
      if (e.response.data.message === 'NO_CARD') {
        // 카드 등록을 안한 상태
        return;
      }
      alertShow(e.response.data.message);
    }
  };

  const onCancelCard = async () => {
    try {
      setLoading(true);
      await axios.delete(`${subscribeApi}/subscribe/cancel`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
        },
      });
      setLoading(false);
      alertShow(t('카드 등록이 해제되었습니다.'));
      setCardInfo(null);
    } catch (error) {
      setLoading(false);
      alertShow(t('카드 삭제에 실패했습니다.\n다시 시도해주세요.'));
      console.log(error);
    }
  };

  return { getCardInfo, onCancelCard };
};
