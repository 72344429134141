import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { alertStore } from '../../stores/modal/alertStore';
import { useFindPw, findPwStore } from '../../stores/user/findPwStore';

import '../../assets/scss/customForm.scss';

import logo from '../../assets/img/logo.svg';
import icCloseWrong from '../../assets/img/icons/ic_close_wrong.svg';
import icCheckRight from '../../assets/img/icons/ic_check_right.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';

import here_bg from '../../assets/public_img/hero-bg-1.jpg';
import here_bg_shape from '../../assets/public_img/hero-bg-shape-1.svg';

export default function FindPw() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { secureCodePage, setSecureCodePage } = findPwStore();
  const { getSecureCode, resetPw } = useFindPw();

  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  const [pw, setPw] = useState('');
  const [pwView, setPwView] = useState(false);
  const [pwConfrimView, setPwConfirmView] = useState(false);
  const [pwCheckState, setPwCheckState] = useState(false);
  // 최소한 8글자
  const [pwCheck1, setPwCheck1] = useState(false);
  // 숫자를 포함
  const [pwCheck2, setPwCheck2] = useState(false);
  // 영문이나 특수문자 포함
  const [pwCheck3, setPwCheck3] = useState(false);
  // 빈칸 포함 금지
  const [pwCheck4, setPwCheck4] = useState(true);
  const changePw = e => {
    setPw(e.target.value);
    // 최소한 8글자
    setPwCheck1(e.target.value.length > 7);
    setPwCheck2(/[0-9]/.test(e.target.value));
    setPwCheck3(/[a-zA-Z]/.test(e.target.value) || /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value));
    setPwCheck4(e.target.value.search(/\s/) === -1);
    setPwConfirmCheck(e.target.value.length > 0 && e.target.value === pwConfirm);
  };
  const [pwConfirm, setPwConfirm] = useState('');
  const [pwConfirmCheckState, setPwConfirmCheckState] = useState(false);
  const [pwConfirmCheck, setPwConfirmCheck] = useState(false);
  const changePwConfirm = e => {
    setPwConfirm(e.target.value);
    setPwConfirmCheck(e.target.value.length > 0 && e.target.value === pw);
  };

  const getCode = () => {
    if (!secureCodePage) {
      if (!id) {
        alertShow(t('아이디를 입력해주세요.'), null);
        return;
      }
      if (!name) {
        alertShow(t('닉네임을 입력해주세요.'), null);
        return;
      }
      if (!email) {
        alertShow(t('이메일을 입력해주세요.'), null);
        return;
      }
      getSecureCode(id, name, email, 'password');
      return;
    }
    if (!code) {
      alertShow(t('인증번호를 입력해주세요'), null);
      return;
    }
    if (pwCheck1 && pwCheck2 && pwCheck3 && pwCheck4 && pwConfirmCheck) {
      resetPw(id, pw, code);
    } else {
      alertShow(t('비밀번호 주의사항을 확인해주세요.'), null);
    }
  };

  useEffect(() => {
    return () => {
      setId('');
      setEmail('');
      setName('');

      setCode('');
      setPw('');
      setPwConfirm('');
      setSecureCodePage(false);
    };
  }, []);
  return (
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
        background: `url(${here_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row  align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
          <div className="col-md-7 col-lg-6 customFormTitle">
            <div className="hero-content-left text-white">
              <h1 className="text-white"> {t('비밀번호를 잊으셨나요?')}</h1>
              <p className="lead">
                {t('가입하신 이메일을 통해 보안코드가 발급됩니다. 보안코드를 입력해 비밀번호를 변경할 수 있습니다.')}
              </p>
            </div>
          </div>
          <div className="col-md-5 col-lg-5 customForm">
            <div className="card login-signup-card shadow-lg mb-0">
              <div className="card-body px-md-5 py-5">
                <div className="mb-5 text-center">
                  <Link to="/login">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>

                <form className="login-signup-form">
                  {secureCodePage ? (
                    <>
                      <div className="form-group">
                        <label className="pb-1">{t('인증번호')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('인증번호 입력')}
                            value={code}
                            onChange={e => setCode(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="pb-1">{t('새 비밀번호')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type={pwView ? 'text' : 'password'}
                            autoComplete="off"
                            className="form-control"
                            placeholder={t('새 비밀번호 입력')}
                            value={pw}
                            onChange={changePw}
                            onFocus={() => setPwCheckState(true)}
                          />
                          <img
                            src={pwView ? icVisibilityOff : icVisibility}
                            alt="visibility"
                            onClick={() => setPwView(prev => !prev)}
                          />
                        </div>

                        {pwCheckState && (
                          <div className="guide">
                            {pwCheck1 && pwCheck2 && pwCheck3 && pwCheck4 ? (
                              <div className="guideItem active">
                                <img src={icCloseWrong} alt="icon" className="wrong" />
                                <img src={icCheckRight} alt="icon" className="right" />
                                <span>사용 가능한 비밀번호 입니다.</span>
                              </div>
                            ) : (
                              <>
                                <div className={`guideItem${pwCheck1 ? ' active' : ''}`}>
                                  <img src={icCloseWrong} alt="icon" className="wrong" />
                                  <img src={icCheckRight} alt="icon" className="right" />
                                  <span>최소한 8글자</span>
                                </div>
                                <div className={`guideItem${pwCheck2 ? ' active' : ''}`}>
                                  <img src={icCloseWrong} alt="icon" className="wrong" />
                                  <img src={icCheckRight} alt="icon" className="right" />
                                  <span>숫자를 포함</span>
                                </div>
                                <div className={`guideItem${pwCheck3 ? ' active' : ''}`}>
                                  <img src={icCloseWrong} alt="icon" className="wrong" />
                                  <img src={icCheckRight} alt="icon" className="right" />
                                  <span>영문이나 특수문자 포함</span>
                                </div>
                                <div className={`guideItem${pwCheck4 ? ' active' : ''}`}>
                                  <img src={icCloseWrong} alt="icon" className="wrong" />
                                  <img src={icCheckRight} alt="icon" className="right" />
                                  <span>빈칸 포함 금지</span>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="pb-1">{t('새 비밀번호 확인')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type={pwConfrimView ? 'text' : 'password'}
                            autoComplete="off"
                            className="form-control"
                            placeholder={t('새 비밀번호 확인 입력')}
                            value={pwConfirm}
                            onChange={changePwConfirm}
                            onFocus={() => setPwConfirmCheckState(true)}
                          />
                          <img
                            src={pwConfrimView ? icVisibilityOff : icVisibility}
                            alt="visibility"
                            onClick={() => setPwConfirmView(prev => !prev)}
                          />
                        </div>
                        {pwConfirmCheckState && (
                          <div className="guide">
                            <div className={`guideItem${pwConfirmCheck ? ' active' : ''}`}>
                              <img src={icCloseWrong} alt="icon" className="wrong" />
                              <img src={icCheckRight} alt="icon" className="right" />
                              <span>{pwConfirmCheck ? '비밀번호가 일치합니다.' : '비밀번호가 일치하지 않습니다.'}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <label className="pb-1">{t('아이디')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('아이디 입력')}
                            value={id}
                            onChange={e => setId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="pb-1">{t('닉네임')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('닉네임 입력')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="pb-1">{t('이메일')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('이메일 입력')}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    type="button"
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    onClick={getCode}
                  >
                    {secureCodePage ? t('비밀번호 변경') : t('인증코드 보내기')}
                  </button>
                  <div className="links">
                    <Link to="/signup" className="small">
                      {t('회원가입')}
                    </Link>
                    <div className="find">
                      <Link to="/find/id" className="form-text small ">
                        {t('아이디 찾기')}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-transparent border-top px-md-5">
                <small className="text-muted text-center">
                  {t('비밀번호가 기억나셨다면?')}{' '}
                  <Link className="color-primary" to="/login">
                    {t('로그인')}
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-img-absolute">
        <img src={here_bg_shape} alt="wave shape" className="img-fluid" />
      </div>
    </section>
  );
}
