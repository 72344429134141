import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { alertStore } from '../../stores/modal/alertStore';
import { useFindId, findIdStore } from '../../stores/user/findIdStore';

import '../../assets/scss/customForm.scss';

import logo from '../../assets/img/logo.svg';
import here_bg from '../../assets/public_img/hero-bg-1.jpg';
import here_bg_shape from '../../assets/public_img/hero-bg-shape-1.svg';

export default function FindId() {
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { secureCodePage, setSecureCodePage } = findIdStore();
  const { getSecureCode, findId } = useFindId();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  const getCode = () => {
    if (!secureCodePage) {
      if (!name) {
        alertShow(t('닉네임을 입력해주세요.'), null);
        return;
      }
      if (!email) {
        alertShow(t('이메일을 입력해주세요.'), null);
        return;
      }
      getSecureCode('', name, email, 'id');
      return;
    }
    if (!code) {
      alertShow(t('인증번호를 입력해주세요.'), null);
      return;
    }
    findId(code);
  };

  useEffect(() => {
    return () => {
      setEmail('');
      setCode('');
      setName('');
      setSecureCodePage(false);
    };
  }, []);
  return (
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
        background: `url(${here_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
          <div className="col-md-7 col-lg-6 customFormTitle">
            <div className="hero-content-left text-white">
              <h1 className="text-white"> {t('아이디를 잊으셨나요?')}</h1>
              <p className="lead">
                {t('가입하신 이메일을 통해 인증코드가 발급됩니다. 인증코드를 입력해 아이디를 확인할 수 있습니다.')}
              </p>
            </div>
          </div>
          <div className="col-md-5 col-lg-5 customForm">
            <div className="card login-signup-card shadow-lg mb-0">
              <div className="card-body px-md-5 py-5">
                <div className="mb-5 text-center">
                  <Link to="/login">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>

                <form className="login-signup-form">
                  {secureCodePage ? (
                    <div className="form-group">
                      <label className="pb-1">{t('인증번호')}</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t('인증번호를 입력해주세요.')}
                          value={code}
                          onChange={e => setCode(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="form-group">
                        <label className="pb-1">{t('닉네임')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t('닉네임 입력')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="pb-1">{t('이메일')}</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t('이메일 입력')}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    type="button"
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    onClick={getCode}
                  >
                    {secureCodePage ? t('아이디 조회') : t('인증코드 보내기')}
                  </button>
                  <div className="links">
                    <Link to="/signup" className="small">
                      {t('회원가입')}
                    </Link>
                    <div className="find">
                      <Link to="/find/pw" className="form-text small ">
                        {t('비밀번호 찾기')}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-transparent border-top px-md-5">
                <small className="text-muted text-center">
                  {t('아이디가 기억나셨다면?')}{' '}
                  <Link className="color-primary" to="/login">
                    {t('로그인')}
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-img-absolute">
        <img src={here_bg_shape} alt="wave shape" className="img-fluid" />
      </div>
    </section>
  );
}
