import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/layout/footer/Footer';
import Layout from '../components/layout/Layout';
import PageHeader from '../components/team/PageHeader';
import TermOfUse from '../components/termofuse';

function Term() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader HeaderTitle={t('이용 약관')} Parent="Pages" PageTitle="Term Of Use" />
      <TermOfUse />
      <Footer space />
    </Layout>
  );
}

export default Term;
