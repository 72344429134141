import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';

import screenshot1 from '../../assets/public_img/screenshot/1.png';
import screenshot2 from '../../assets/public_img/screenshot/2.png';
import screenshot3 from '../../assets/public_img/screenshot/3.png';
import screenshot4 from '../../assets/public_img/screenshot/4.png';
import screenshot5 from '../../assets/public_img/screenshot/5.png';
import screenshot6 from '../../assets/public_img/screenshot/6.png';
import screenshot7 from '../../assets/public_img/screenshot/7.png';
import screenshot8 from '../../assets/public_img/screenshot/8.png';

export default function Screenshot({ hasBg }) {
  const { t } = useTranslation();
  const options = {
    loop: true,
    margin: 0,
    center: true,
    dots: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 4,
      },
      1920: {
        items: 4,
      },
    },
  };
  return (
    <section id="screenshots" className={`screenshots-section ptb-100 ${hasBg ? 'gray-light-bg' : ''}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <span className="text-uppercase color-secondary sub-title">{t('앱 스크린샷')}</span>
              <h2>{t('서비스 화면')}</h2>
              <p className="lead">
                {t('비대면 교육의 중심! 쉽고 강력한 엑스클래스의 실제 운영 화면을 확인해 보세요.')}
                <br />
                {t('다양한 기능을 통해 기업의 교육, 관리, 점검, 평가를 효과적으로 진행할 수 있습니다!')}
              </p>
            </div>
          </div>
        </div>
        <div className="screen-slider-content mt-5">
          {/* <div className="screenshot-frame"></div> */}
          <OwlCarousel className="screen-carousel owl-carousel owl-theme dot-indicator" {...options}>
            <img src={screenshot1} className="img-fluid" alt="screenshots" />
            <img src={screenshot2} className="img-fluid" alt="screenshots" />
            <img src={screenshot3} className="img-fluid" alt="screenshots" />
            <img src={screenshot4} className="img-fluid" alt="screenshots" />
            <img src={screenshot5} className="img-fluid" alt="screenshots" />
            <img src={screenshot6} className="img-fluid" alt="screenshots" />
            <img src={screenshot7} className="img-fluid" alt="screenshots" />
            <img src={screenshot8} className="img-fluid" alt="screenshots" />
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}
