/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { useSubscribeInfo } from '../../stores/mypage/subscribeInfoStore';
import { alertStore } from '../../stores/modal/alertStore';
import { useLogout } from '../../stores/user/loginStore';

import Contents from './Contents';
import Sidebar from './Sidebar';

import styles from '../../assets/scss/pay/pay.module.scss';

export default function Pay() {
  const { getSubscribeInfo } = useSubscribeInfo();
  const { alertShow } = alertStore();
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);
  const { logout } = useLogout();

  useEffect(() => {
    const fetchData = async () => {
      await getSubscribeInfo();
    };

    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      {/* <div className={styles.etc}>
        <div>
          * 구독 후에 사용자 수를 늘리거나 기능을 추가하는 것은 가능하지만, 사용자 수를 줄이거나 이용중인 기능을
          취소하는 것은 불가능합니다.
        </div>
        <div>* 사용자 수 및 사용하실 기능을 신중하게 고려하시고 결제를 진행해주세요.</div>
      </div> */}
      <Contents />
      <Sidebar />
    </div>
  );
}
