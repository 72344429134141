// style
import styles from './index.module.scss';

function TermOfUse() {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 1<span>장 총칙</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 1<span>조 목적</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 2<span>조 용어의 정의</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 3<span>조 약관의 게시와 개정</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 2<span>장 이용계약체결</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 4<span>조 회원가입 및 이용계약 체결</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 5<span>조 사용자 계정 관리에 대한 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 6<span>조 개인정보 보호 및 이용</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 7<span>조 회사의 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 8<span>조 회원의 권리 및 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 3<span>장 서비스의 이용</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 9<span>조 서비스의 제공 및 변경</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 10
              <span>조 정보의 제공</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 11
              <span>조 회원 작성 콘텐츠 정책 및 위반시 제재 방침</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 12
              <span>조 이용 요금 및 결제</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 13
              <span>조 보상의 획득 및 수령</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 14
              <span>조 보상의 정정 및 취소</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 15
              <span>조 환불 및 계약해지</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 16
              <span>조 책임제한 및 면책조항</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 4<span>장 기타</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 17<span>조 손해배상</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 18
              <span>조 준거법 및 재판관할</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              [<span>부칙</span>]
            </b>
          </p>
          <span
            style={{
              fontSize: '10.0pt',
              lineHeight: '103%',
              color: 'black',
            }}
          >
            <br clear="all" style={{ pageBreakBefore: 'always' }} />
          </span>
          <p className="MsoNormal">&nbsp;</p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 1<span>장 총칙</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 1<span>조 목적</span>
            </b>
          </p>
          <p
            className="MsoNormal"
            align="left"
            style={{
              marginBottom: '8.0pt',
              textAlign: 'left',
              lineHeight: 'normal',
            }}
          >
            &nbsp;<span>이 이용약관</span>(<span>이하 </span>
            <span>‘</span>
            <span>약관</span>
            <span>’</span>)<span>은 엑스캐스트</span>(<span>이하 </span>
            <span>‘</span>
            <span>회사</span>
            <span>’</span>)<span>가 제공하는</span> xClass(
            <span>이하 </span>
            <span>‘</span>
            <span>서비스</span>
            <span>’</span>)<span>의 이용과 관련하여 </span>
            <span>‘</span>
            <span>회사</span>
            <span>’</span>
            <span>와 사용자</span>(<span>이하 </span>
            <span>‘</span>
            <span>회원</span>
            <span>’</span>) <span>간 권리와 의무 및 기타 사항을 규정하는 데 그 목적이 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 2<span>조 용어의 정의</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
            <span>이 약관에서 사용하는 용어의 정의는 다음과 같습니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            1. <span>사이트</span>:<span>회사에서 제공하는 서비스를</span> PC
            <span>에서 이용할 수 있는 웹사이트를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            2. <span>앱</span>:
            <span>회사에서 제공하는 서비스를 모바일에서 이용할 수 있도록 개발된 애플리케이션을 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            3. <span>단말기</span>: PC, <span>스마트폰 및 태블릿</span>PC{' '}
            <span>등 사이트에 접속하거나 앱을 설치하여 구동할 수 있는 디바이스 일체를 의미합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            4. <span>서비스</span>:<span>게임 참가 및 참가 예약</span>, <span>예약 취소 등</span> PC
            <span>와 모바일 환경에서 콘텐츠를 이용하기 위해 회사가 제공하는 일체의 기능을 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            5. <span>회원</span>:<span>회사에서 제공하는 서비스를 이용하기 위해 단말기를 통해 접속</span>,{' '}
            <span>본 약관에 따라 회사와 이용계약을 체결하여 서비스를 이용하는 자 일체를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            6. <span>법인회원</span>:
            <span>
              이용계약을 통해 가입한 회원 중 게임 참가가 아닌 게임 개최를 목적으로 가입한 법인 명의 회원을 지칭합니다
            </span>
            .
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            7. <span>계정</span>:<span>회원의 서비스 이용을 위해 구글</span>,{' '}
            <span>페이스북 등의 플랫폼사업자가 제공하는 회원의 본인 식별 정보를 의미합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            8. <span>계정정보</span>:<span>회원이 계정을 생성함에 따라 부여되는</span> ID(
            <span>고유식별번호</span>)<span>와 비밀번호</span>, <span>서비스 이용 정보</span>, <span>이용 요금</span>,{' '}
            <span>결제 상태 등을 통칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            9. <span>콘텐츠</span>:<span>참여가 가능한 게임</span>,{' '}
            <span>지난 게임의 결과 조회 등 회사가 제공하는 사이트 및 앱에서 이용 가능한 내용물 일체를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            10. <span>퀴즈쇼</span>:
            <span>회사 및 법인회원이 제공하는 서비스 중 일정한 보상을 전제로 진행되는 실시간 퀴즈쇼를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            11. <span>보상</span>:<span>회원이 서비스를 이용하는 과정에서 게임에 참가하여 일정 등수를 하거나</span>,{' '}
            <span>
              정해진 퀴즈를 맞힘으로써 법인회원이 지정하는 행위를 수행하였을 경우 법인회원이 회원에게 지급하는 일정한
              대가를 지칭합니다
            </span>
            .
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            12. <span>상금</span>:<span>회원이 서비스 이용 과정에서 지급 받을 수 있는 보상 중 하나로</span>,{' '}
            <span>법인 회원이 회원에게 지급하는 현금 혹은 동일한 상당의 포인트를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            13. <span>상품</span>:<span>회원이 서비스 이용 과정에서 지급 받을 수 있는 보상 중 하나로</span>,{' '}
            <span>법인 회원이 회원에게 지급하는 현물 혹은 상품교환권 등을 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            14. <span>회원콘텐츠</span>:<span>서비스를 이용하는 과정에서 회원이 작성할 수 있는 게시글 및 댓글</span>,{' '}
            <span>실시간 채팅 등 회원에 의해 작성되는 일체의 콘텐츠를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            15. <span>법인서비스</span>:<span>개별 게임의 개최</span>,{' '}
            <span>게임 진행 관리 기능 등 법인회원들에 한정하여 제공되는 법인회원 전용 서비스를 지칭합니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '0in',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            16. <span>유료콘텐츠</span>:
            <span>
              게임과 관련한 특수 기능 등 회사 및 법인회원이 제공하는 서비스를 이용하는 과정에서 그 사용을 위해 결제를
              거쳐야 하는 콘텐츠를 지칭합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 3<span>조 약관의 게시와 개정</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회사는 이 약관의 내용을 회원이 쉽게 확인할 수 있도록 사이트 화면을 통해 게시하며</span>,{' '}
            <span>앱 내부에도 이를 게시합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회사는 약관의 규제에 관한 법률</span>,{' '}
            <span>
              정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수
              있습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사는 약관을 개정하고자 할 경우 그 적용일자</span> 7
            <span>일 이전부터 적용일자 전일까지 공지합니다</span>.<span>다만 회원에게 불리한 약관 개정의 경우</span> 30
            <span>일 전부터 공지를 진행함과 동시에 일정기간 계정에 등록된 전자우편주소</span>,{' '}
            <span>앱 접속시 동의창 등</span> 1
            <span>개 이상의 전자적 수단을 통해 이를 회원에게 따로 명확히 통지합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회원은 변경된 약관에 대해 거부할 권리가 있습니다</span>.{' '}
            <span>본 약관의 변경에 대해 동의하지 않는 회원은 서비스 이용을 중단하고 이용계약을 해지</span>(
            <span>회원탈퇴</span>)<span>할 수 있습니다</span>.{' '}
            <span>
              회원이 변경된 약관의 적용 이후에도 서비스를 계속 이용하는 경우에는 변경된 약관에 동의한 것으로 간주합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 2<span>장 이용계약체결</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 4<span>조 회원가입 및 이용계약 체결</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회원이 되고자 하는 자</span>(<span>이하 </span>
            <span>‘</span>
            <span>가입신청자</span>
            <span>’</span>)<span>가 본 약관의 내용에 동의한 후 회원가입신청을 하고</span>,{' '}
            <span>회사가 이러한 신청에 승낙함으로써 체결됩니다</span>.
            <span>이 과정에 있어 회사는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회사는 이용계약 체결 과정에서 </span>
            <span>‘</span>
            <span>가입신청자</span>
            <span>’</span>
            <span>의 이메일 주소 등</span> (<span>개인</span>)<span>정보를 수집할 수 있으며</span>,{' '}
            <span>이 경우 </span>
            <span>‘</span>
            <span>가입신청자</span>
            <span>’</span>
            <span>는 정확한</span>(<span>거짓이 아닌</span>) <span>정보를 제공하여야 합니다</span>.{' '}
            <span>
              본 항에 따른 개인정보의 수집 및 이용과 보관 등에 대해서는 별도로 게시되는 개인정보처리방침이 적용됩니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다</span>.{' '}
            <span>
              다만 회사는 아래 각 호에 해당하는 신청에 대하여 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다
            </span>
            .
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            1. <span>가입신청자가 본 약관에 의거하여 이전에 회원자격을 상실한 적이 있는 경우</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            2. <span>실명이 아니거나 타인의 명의를 이용한 경우</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            3. <span>허위 정보를 기재하거나 회사에서 필수적으로 요청하는 내용을 기재하지 않은 경우</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            4. <span>위법</span>,<span>불법행위 등 부정한 용도</span>,{' '}
            <span>타인의 권리를 침해하기 위한 행위로 회사의 서비스를 이용하는 경우</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            5.{' '}
            <span>
              가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            6. <span>기타 회원으로 등록하는 것이 회사의 이해에 침해가 된다고 판단되는 경우</span>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4){' '}
            <span>본 조에 따라 회원가입신청을 승인하지 않은 경우 회사는 원칙적으로 이를 가입신청자에게 알립니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5){' '}
            <span>
              서비스 이용계약의 성립시기는 회사가 회원가입신청에 대한 완료를 신청절차상에 표시한 시점부터 적용됩니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(6) <span>회원은 개인정보관리 화면을 통해 언제든지 본인의 정보를 열람</span>,{' '}
            <span>수정할 수 있습니다</span>.<span>다만 서비스 관리를 위해 필요한 실명</span>,{' '}
            <span>아이디 등 회사가 수정을 불허하는 일부 항목의 수정은 회사와 사전 협의가 필요합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(7){' '}
            <span>
              회원은 회원가입신청 시 기재한 정보에 변경이 생긴 경우 온라인으로 수정을 하거나 전자우편 등 기타 방법을
              통해 회사에 그 변경사항을 알려야 하며
            </span>
            ,{' '}
            <span>
              회원이 정보에 대한 변경사항을 적절히 수정하지 않아 발생한 불이익에 대하여 회사는 책임을 부담하지 않습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(8) <span>회사는 아동</span>(<span>만</span>14<span>세 미만</span>,<span>외국인의 경우 만</span> 16
            <span>세 미만</span>)<span>을 대상으로 서비스를 제공하지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 5<span>조 사용자 계정 관리에 대한 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회원의 계정 정보에 대한 기밀유지 및 관리의 책임은 회원에게 있으며</span>,{' '}
            <span>이를 제</span>3<span>자가 이용하도록 하여서는 안 됩니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>
              회원은 자신의 계정 관리 부주의로 인하여 발생한 무단사용 혹은 사기성 사용에 의한 불이익에 대한 책임을
              집니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회원은 자신의 계정이 도용되거나 제</span>3
            <span>자에 의한 사용을 인지한 경우 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다</span>.{' '}
            <span>
              회원이 회사에 이를 통지하지 않거나 통지 후 회사의 안내에 따라 발생한 불이익에 대하여 회사는 책임을
              부담하지 않습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4){' '}
            <span>회사는 서비스 향상에 도움이 되거나 무단 사용 및 사기성 사용을 방지하기 위해 필요한 경우</span>,{' '}
            <span>회원의 아이디와 비밀번호를 변경할 권한을 보유합니다</span>.{' '}
            <span>이 경우 회사는 회원에게 사전에 그 이유 및 변경된 내용을 통지합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 6<span>조 개인정보 보호 및 이용</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1){' '}
            <span>
              회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계 법령이 정하는 바에 따라 회원의 개인정보를
              보호하기 위해 가능한 모든 노력을 기울입니다
            </span>
            .&nbsp;{' '}
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>개인정보 보호 및 사용에 대해서는 관계 법령 및 회사의 개인정보 처리방침이 적용됩니다</span>.{' '}
            <span>다만</span>,{' '}
            <span>회사의 공식 사이트 이외의 링크된 사이트의 경우 회사의 개인정보 처리방침이 적용되지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 7<span>조 회사의 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회사는 관계 법령 및 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며</span>,{' '}
            <span>지속적이고 안정적인 서비스를 제공하기 위하여 최선을 다하여 노력합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>
              회사는 회원의 개인정보 보호를 위해 적절한 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고
              준수합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3){' '}
            <span>
              회사는 서비스의 이용과 관련하여 회원으로부터 제기되는 의견이나 불만이 정당하다고 판단될 경우 이를 적절하게
              처리하여야 합니다
            </span>
            .
            <span>
              회원이 제기한 의견이나 불만에 대해서는 게시판과 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달할 수
              있으며
            </span>
            ,<span>즉각적인 처리가 곤란한 경우 그 사유와 차후의 처리일정을 통보합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회사가 회원에게 서비스 제공이 불가능해지는 경우 회사는 서비스 제공 중지일자로부터</span> 3
            <span>개월 전까지 전자메일</span>, <span>사이트 내 게시판 등을 이용하여 회원에게 통지합니다</span>.
            <span>다만</span>, <span>회사가</span> 3
            <span>
              개월 전에 통지할 수 없는 부득이한 사유가 있는 경우 회사는 서비스 제공이 불가능한 날까지 회원에게 통지하는
              것으로 갈음할 수 있습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 8<span>조 회원의 권리 및 의무</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회원은</span> PC <span>및 모바일 환경에서 회사가 제공하는 서비스와</span>,{' '}
            <span>서비스를 이용해 접근할 수 있는 콘텐츠를 정당한</span>(<span>서비스에서 제공하는</span>)
            <span>방식을 통해 구매하여 개인적</span>, <span>비영리적 목적으로 사용할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>회원은 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며</span>
            , <span>허위 또는 타인의 정보를 도용할 경우 일체의 권리를 주장할 수 없습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회원은 관계 법령</span>,{' '}
            <span>본 약관 등 회사가 공지한 주의사항 및 통지사항 등을 준수하여야 하며</span>,{' '}
            <span>기타 회사의 업무에 방해가 되는 행위를 하여서는 안 됩니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회원은 회사가 명시적으로 동의하지 않는 한 서비스의 이용권한</span>,{' '}
            <span>기타 이용약관상의 지위를 타인에게 양도</span>, <span>증여할 수 없습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5){' '}
            <span>
              회원이 게시하는 정보 등으로 인해 발생하는 손실이나 문제는 전적으로 회원 개인의 책임이며 회사의 중과실이
              아닌 한
            </span>
            ,<span>회사는 이에 대해 책임을 부담하지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(6) <span>회원은 아래 각 호에 해당하는 행위를 하여서는 안 됩니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            1. <span>회원가입 신청 및 회원정보 변경 시 허위내용을 등록하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            2. <span>타인의 정보를 도용하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            3.{' '}
            <span>회사로부터 명시적인 권리를 부여받지 않고 회사의 관리 하에 있는 데이터 또는 프로그램 등을 훼손</span>/
            <span>변경</span>/<span>위조하거나 회사가 게시한 정보를 임의적으로 변경하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            4. <span>해킹</span>,<span>매크로 등 부정한 방법을 통해 퀴즈쇼에 참가하여 보상을 받는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            5. <span>회사</span>,<span>법인회원을 비롯한 제</span>3
            <span>자의 저작물에 대한 지적재산권을 침해하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            6. <span>회사</span>,<span>법인회원을 비롯한 제</span>3
            <span>자의 명예를 훼손하거나 업무를 방해하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            7. <span>회사의 승인 없이 다른 회원의 개인정보를 수집 또는 저장하는 행위</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '40.0pt',
              textIndent: '-20.0pt',
              lineHeight: 'normal',
            }}
          >
            8. <span>기타 법령에서 금지하는 행위 및 부당한 행위</span>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 3<span>장 서비스의 이용</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 9<span>조 서비스의 제공 및 변경</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회사는 회원에게 회사가 개발한 서비스를 연중무휴로 제공함을 원칙으로 합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회사는 정보통신설비의 보수 및 점검</span>, <span>교체</span>,{' '}
            <span>고장 또는 운영상의 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다</span>.{' '}
            <span>이 경우 회사는 서비스의 제공이 중단되기</span> 7<span>일 전에 전자메일</span>,{' '}
            <span>앱 내 알림 팝업 등의 방법을 통해 회원에게 이를 통지합니다</span>.{' '}
            <span>다만 회사가 사전에 통지할 수 없는 부득이한 사정이 있는 경우 사후에 통지할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사는 서비스의 제공에 필요하다고 판단되는 경우 정기점검을 실시할 수 있으며</span>,{' '}
            <span>정기점검시간은 앱 내 알림 팝업 등의 방법을 통해 회원에게 통지합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회사는 제공하는 서비스의 품질 개선 또는 기술적 사양 변경 등 운영상</span>,{' '}
            <span>기술상의 필요에 따라 서비스의 내용을 변경할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5) <span>제공되는 서비스가 변경되는 경우</span>,{' '}
            <span>변경이 이루어지기 전에 변경되는 서비스의 내용 및 제공일자를 명시하여 전자메일</span>,{' '}
            <span>앱 내 알림 팝업 등의 방법을 통해 이를 통지합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(6){' '}
            <span>회원은 본 조에 의한 서비스의 변경에 동의하지 않는 경우 서비스 이용계약을 해지할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 10
              <span>조 정보의 제공</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1){' '}
            <span>
              회사는 회원의 사전 동의 하에 서비스를 이용함에 있어 필요하다고 인정되는 다양한 정보를 앱 내 알림 팝업
            </span>
            , <span>전자메일 등의 방법으로 회원에게 제공할 수 있습니다</span>.{' '}
            <span>회원은 이에 대하여 원하지 않는 경우 언제든지 수신을 거부할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>단</span>,{' '}
            <span>
              회사가 제공하고자 하는 정보가 이용약관의 개정 등 서비스를 이용함에 있어 반드시 필요한 정보인 경우 회원의
              수신 거부와 무관하게 정보를 제공할 수 있습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사는 서비스의 개선 등의 목적으로 추가적인 개인 정보를 요구할 수 있으며</span>,{' '}
            <span>회원은 이에 대하여 선택 여부를 결정할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 11
              <span>조 회원 작성 콘텐츠 정책 및 위반시 제재 방침</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회원은 스스로 작성하는 게시글과 댓글을 비롯한 모든 회원콘텐츠에 대한 책임이 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회원은 회원콘텐츠를 작성함에 있어 다음과 같은 행위를 하여서는 안 됩니다</span>.
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            1. <span>타인을 근거 없이 비방하거나 명예를 훼손하는 내용</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            2. <span>욕설 및 음란</span>/<span>외설적 내용</span>,{' '}
            <span>폭력적인 내용 등 사회의 미풍양속에 반하는 내용</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            3. <span>법령에 따라 금지되는 위</span>
            <span>·</span>
            <span>불법 행위와 관련된 내용</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            4. <span>범죄를 목적으로 하거나 범죄를 모의</span>, <span>교사 또는 방조하는 내용</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            5. <span>법령에 따라 금지된 영업비밀 또는 국가기밀 등을 누설하는 내용</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            6. <span>기타 법령에 의해 게시가 금지된 불법적인 내용</span>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회원이</span> (2)
            <span>항에 명시된 금지행위를 위반할 경우 아래 각 호의 내용에 의거하여 조치가 취해질 수 있습니다</span>.{' '}
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            1.{' '}
            <span>
              위반 사유가 확인되었거나 타 사용자에 의한 신고가 일정 횟수 이상 누적된 회원콘텐츠는 자동적으로 임시삭제
              조치가 취해집니다
            </span>
            .
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            2. (2)<span>항의</span>1<span>과</span> 2
            <span>
              에 해당하는 위반 사유가 확인되었거나 타 사용자에 의해 일정 횟수 이상 신고를 받은 회원은 일정한 기간 동안{' '}
            </span>
            <span>‘</span>
            <span>회원콘텐츠</span>
            <span>’</span>
            <span>작성이 제한됩니다</span>. (<span>※</span>
            <span>위 제재의 기준 횟수 및 제재기간은 부적절한 콘텐츠로부터 서비스 및 회원을</span>
            &nbsp; <span>보호하기 위한 가장 적절한 횟수로 수시로 정해지게 됩니다</span>
            .)
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '8.0pt',
              marginLeft: '20.0pt',
              textIndent: '0in',
              lineHeight: 'normal',
            }}
          >
            3. (2)<span>항의</span>
            3, 4, 5<span>와</span> 6
            <span>에 해당하는 위반 사유가 확인되었거나 타 사용자에 의해 신고가 접수된 회원은 영구히 </span>
            <span>‘</span>
            <span>회원콘텐츠</span>
            <span>’</span>
            <span>작성과 </span>
            <span>‘</span>
            <span>유료콘텐츠</span>
            <span>’</span>
            <span>시청을 포함한 </span>
            <span>‘</span>
            <span>서비스</span>
            <span>’</span>
            <span>의 이용이 제한됩니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회원이</span> (2)
            <span>항의 내용을 위반하여</span> (3)
            <span>항에 의거한 조치를 받았을 경우</span>, <span>해당 콘텐츠의 작성자는 기한에 상관없이</span>
            (2)
            <span>항의 위반사유에 해당되지 않음을 소명함으로써 취해진 조치를 해제할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
            &nbsp;(5) <span>단</span>,{' '}
            <span>임시삭제된 회원콘텐츠에 대한 소명이 적절한 기간 내에 이루어지지 않을 경우</span>,{' '}
            <span>회사는 임시삭제된 회원콘텐츠를 영구적으로 삭제할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 12
              <span>조 이용 요금 및 결제</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회사의 서비스를 통해 제공되는 유료콘텐츠의 요금</span>,{' '}
            <span>
              결제방법 등에 대해서는 사이트 및 앱 내에 별도 게시하며 회원은 사이트와 앱 내에서 이루어진 결제를 통해
              유료콘텐츠를 구매하여 서비스를 이용할 수 있습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>
              회사에서 제공하는 모든 서비스에 대한 소유권 및 지적재산권은 회사를 비롯한 정당한 권리자에게 있으며
            </span>
            , <span>회원이 유료콘텐츠를 구매하는 경우 그 회원은 해당 유료콘텐츠에 대한 사용권을 가집니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회원은 유료콘텐츠 결제와 관련하여 회원이 입력한 정보가 정확한 지 여부를 확인해야 하며</span>
            , <span>결제 정보의 오기 및 허위기재</span>,{' '}
            <span>도용 등과 관련하여 발생한 불이익과 그 책임은 회원이 전적으로 부담합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회원은 유료콘텐츠 결제에 대해 세금을 포함하여 지불해야 합니다</span>.{' '}
            <span>세금은 회사의 주소지 국가 세법에 따라서 지정됩니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5){' '}
            <span>
              카드의 이용한도 초과 등 일련의 사유로 인해 회원이 설정한 정보에 따른 결제가 온전히 완료되지 않았을 경우
            </span>
            , <span>결제가 실패된 콘텐츠에 대한 이용이 불가합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 13
              <span>조 보상의 획득 및 수령</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1){' '}
            <span>
              회원은 법인회원에 의해 제공되는 실시간 퀴즈쇼에서 지정된 행위를 수행함으로써 보상을 획득할 수 있습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>
              법인회원의 주최에 의해 진행되는 실시간 퀴즈쇼의 보상에 대한 최종 의사결정은 법인회원의 방송 담당자에
              있으며
            </span>
            ,<span>회사가 직접 주최하는 실시간 퀴즈쇼의 보상은 회사가 최종 결정권을 가집니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3){' '}
            <span>보상의 금액 및 지급 방식은 회사 혹은 법인회원의 정책과 의사결정에 따라 달라질 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회원이 획득한 보상의 내역은 서비스 내 마이 페이지에서 확인할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5) <span>보상은 퀴즈쇼를 주최한 법인회원의 정책에 따라 지급되며</span>,{' '}
            <span>
              회원은 법인회원 및 회사의 요청에 따라 보상 지급에 필요한 소정의 정보를 적절히 제공해주어야 합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(6){' '}
            <span>
              법인회원과 회사가 회원에게 보상을 지급할 때 보상 지급을 위해 필수적으로 필요한 정보 이외의 정보를 회원에게
              요구해서는 안됩니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(7){' '}
            <span>
              회원의 마이페이지에 표기된 보상의 내용과 회사의 서버에 입력된 보상의 내용에 차이가 있는 경우 회사의 서버에
              입력된 내용을 기준으로 합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(8) <span>보상의 수령과 관련하여 발생하는 제세공과금 등의 제반 비용은 회원이 부담합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(9) <span>보상은 양도</span>, <span>대여</span>, <span>담보의 목적으로 이용될 수 없습니다</span>.{' '}
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 14
              <span>조 보상의 정정 및 취소</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>보상 획득이 확정된 후 오류가 발생한 경우</span>, <span>회원은 오류 발생일로부터</span>
            30
            <span>일 이내에 법인회원 혹은 회사에 정정 요구를 할 수 있으며</span>,{' '}
            <span>회원의 요구가 정당하다면 법인회원과 회사는</span> 90<span>일 이내에 이를 정정해야 합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회원이 해킹</span>, <span>매크로를 비롯한 부정한 방법을 통해 보상을 획득한 경우</span>,
            <span>획득된 보상은 소멸되며 회원 자격은 상실됩니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 15
              <span>조 환불 및 계약해지</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회원은 유료콘텐츠에 대해 결제한 날로부터 해당 유료콘텐츠가 제공되기</span> 7
            <span>일 이전까지 사용하지 않은 유료콘텐츠에 한하여 청약의 철회를 할 수 있습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>
              유료콘텐츠의 내용이 표시된 내용과 다르거나 계약내용과 다르게 이행된 경우에는 유료콘텐츠를 공급받은
              날로부터
            </span>
            30
            <span>일 이내 또는 그 사실을 안 날 또는 알 수 있었던 날부터</span> 30
            <span>일 이내에 청약을 철회할 수 있습니다</span>.{' '}
            <span>
              이 경우 회원은 청약철회를 위해 유료콘텐츠의 이용으로부터 얻은 이익에 해당하는 금액을 회사에 지불하여야
              합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3){' '}
            <span>회원이 청약을 철회하는 경우 회사는 관계 법령 등이 정하는 바에 따라 이를 즉시 처리해야 하며</span>,{' '}
            <span>청약철회에 대한 처리는 회원이 최초 결제시 이용한 수단을 통해 철회하는 것을 원칙으로 합니다</span>.{' '}
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 16
              <span>조 책임제한 및 면책조항</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1){' '}
            <span>
              회사는 서비스 제공 및 회원의 서비스 이용과 관련하여 회사의 고의 또는 중과실이 없는 한 회원에게 발생한
              손해에 대하여 책임을 부담하지 않습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2){' '}
            <span>회사는 회원의 귀책사유로 인한 서비스 이용 장애와 그로 인한 손해에 대한 책임을 부담하지 않습니다</span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사는 회사가 제공하는 서비스를 매개로 하여 이루어진 회원간</span>, <span>회원과 제</span>3
            <span>자 간의 거래 및 분쟁 등에 관하여 책임을 부담하지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(4) <span>회사는 기간통신사업자 또는 별정통신 제</span>1
            <span>
              호사업자가 전기통신 서비스를 중지하거나 정상적으로 약정된 서비스를 제공하지 아니하여 손해가 발생한 경우
              책임을 부담하지 않습니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(5) <span>회사는 회원의 귀책사유로 인한 계정 정보 유출이 발생한 경우</span>,{' '}
            <span>수정된 개인정보를 적절하게 반영하지 않음으로 인해 일어난 피해에 대해 책임을 부담하지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(6) <span>회사는 회원이 게재한 각종 회원콘텐츠의 내용에 대하여 책임을 부담하지 않습니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>
              <span>제</span> 4<span>장 기타</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            <b>&nbsp;</b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 17<span>조 손해배상</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>회사 또는 회원의 귀책사유로 인하여 상대방 또는 제</span>3
            <span>자에게 손해가 발생한 경우 귀책당사자는 해당 손해를 배상하여야 합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회사의 고의 또는 과실</span>,{' '}
            <span>
              약관위반으로 인해 회사에 귀책사유가 있는 경우 이로 인하여 회원이 입은 통상손해에 대하여 회사는 배상하여야
              합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회원의 고의 또는 과실</span>,{' '}
            <span>
              약관위반으로 인해 회원에 귀책사유가 있는 경우 이로 인하여 회사가 입은 통상손해에 대하여 회원은 배상하여야
              합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>
              <span>제</span> 18
              <span>조 준거법 및 재판관할</span>
            </b>
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>이 약관에 명시되지 않은 사항은 관계법령 및 상관습에 따릅니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(2) <span>회사와 회원 간 제기된 소송은 대한민국을 준거법으로 합니다</span>.
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(3) <span>회사와 회원 간 발생한 분쟁의 경우 관할법원은 민사소송법이 정한 바에 따릅니다</span>.{' '}
            <span>다만</span>,{' '}
            <span>
              해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 분쟁에 관한 소송은 서울중앙지방법원을 관할 법원으로
              합니다
            </span>
            .
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            [<span>부칙</span>]
          </p>
          <p className="MsoNormal" style={{ marginBottom: '8.0pt', lineHeight: 'normal' }}>
            &nbsp;(1) <span>이 약관은</span> 2023
            <span>년 </span>
            <span>3</span>
            <span>월 </span>
            <span>3</span>
            <span>일부터 적용됩니다</span>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermOfUse;
