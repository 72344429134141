import React from 'react';
import footer_top_shape from '../../assets/public_img/footer-top-shape.png';

export default function Subsribe() {
  return (
    <div className="shape-img subscribe-wrap">
      <img src={footer_top_shape} alt="footer shape" className="img-fluid" />
    </div>
  );
}
