import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../components/others/NotFound';
import FindPwPage from '../pages/FindPwPage';
import FindIdPage from '../pages/FindIdPage';
import Login from '../pages/Login';
import HomeFour from '../themes/index-4/HomeFour';
import OurTeam from '../pages/OurTeam';
import SignupPage from '../pages/SignupPage';
import Private from '../pages/Private';
import EmailRefuse from '../pages/EmailRefuse';
import Copyright from '../pages/Copyright';
import Term from '../pages/TermOfUse';
import MyPage from '../pages/mypage';
import Payment from '../components/mypage/Payment';
import Info from '../components/mypage/Info';
import Account from '../components/mypage/Account';
import Subscribe from '../components/mypage/subscribe/Subscribe';
import Plan from '../components/mypage/Plan/Plan';
import AmountUsed from '../components/mypage/AmountUsed';
import Withdrawal from '../components/mypage/Withdrawal';
import PayPage from '../pages/pay';
import NoticePage from '../pages/notice';
import Notice from '../components/notice/Notice';
import NoticeDetail from '../components/notice/Detail';
import IframeContainer from '../modal/iframe/IframeContainer';
import DemoPage from '../pages/demo/DemoPage';
import SignupCompletePage from '../pages/complete/SignupCompletePage';
import PayCompletePage from '../pages/complete/PayCompletePage';
import ContactCompletePage from '../pages/complete/ContactCompletePage';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomeFour />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/private" element={<Private />} />
      <Route path="/email-refuse" element={<EmailRefuse />} />
      <Route path="/term-of-use" element={<Term />} />
      {/* <Route path="/copyright" element={<Copyright />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/learningtalktalk" element={<IframeContainer />} />
      <Route path="/learningtalktalk/:param" element={<IframeContainer />} />
      <Route path="/learningtalktalk/:param/:key" element={<IframeContainer />} />
      <Route path="/learningtalktalk/:param/:key/:secondKey" element={<IframeContainer />} />
      <Route path="/mypage" element={<MyPage />}>
        <Route path="" element={<Payment />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="plan" element={<Plan />} />
        <Route path="info/:type" element={<Info />} />
        <Route path="amount-used" element={<AmountUsed />} />
        <Route path="account" element={<Account />} />
        <Route path="withdrawal" element={<Withdrawal />} />
      </Route>
      <Route path="/pay" element={<PayPage />} />
      <Route path="/notice" element={<NoticePage />}>
        <Route path="" element={<Notice />} />
        <Route path="detail/:param" element={<NoticeDetail />} />
      </Route>
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/find/pw" element={<FindPwPage />} />
      <Route path="/find/id" element={<FindIdPage />} />
      <Route path="/signup/complete" element={<SignupCompletePage />} />
      <Route path="/pay/complete" element={<PayCompletePage />} />
      <Route path="/contact/complete" element={<ContactCompletePage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
