import { create } from 'zustand';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';
import { encrypt } from '../../util/util';

import CookieManager from '../../assets/js/CookieManager';

import { loadingStore } from '../loadingStore';
import { alertStore } from '../modal/alertStore';
import { infoStore } from '../mypage/infoStore';
import { cardStore } from '../pay/cardStore';
import { subscribeInfoStore } from '../mypage/subscribeInfoStore';

const accountApi = process.env.REACT_APP_ACCOUNT_API;

export const loginStore = create((set, get) => ({
  // 로그인 토큰
  xHome: CookieManager.get(`XHOME`) || '',
  expires: CookieManager.get(`xclass-expires`) || '',
  xName: CookieManager.get(`xclass-name`) || '',
  xMarketing: CookieManager.get('xclass-marketing_status') || false,
  missLoginCount: 0,
  xToken: CookieManager.get('xclass-access-token') || '',
  setXHome: v =>
    set({
      xHome: v,
    }),
  setExpires: v =>
    set({
      expires: v,
    }),
  setXName: v =>
    set({
      xName: v,
    }),
  setMissLoginCount: v =>
    set({
      missLoginCount: v,
    }),
  setXMarketing: v =>
    set({
      xMarketing: v,
    }),
  setXToken: v => set({ xToken: v }),
}));

export function useLogin() {
  const { t } = useTranslation();
  const { missLoginCount, setMissLoginCount, setXToken, setXHome, setExpires, setXName, setXMarketing } = loginStore();
  const { alertShow } = alertStore();
  const { loading, setLoading } = loadingStore();
  const { setInfoId } = infoStore();
  const navigate = useNavigate();
  const [, setCookie] = useCookies([
    'xclass-access-token',
    'xclass-expires',
    'XHOME',
    'xclass-name',
    'xclass-marketing_status',
  ]);

  const login = async (id, password) => {
    try {
      if (loading) return;
      setLoading(true);
      const { data } = await axios.post(`${accountApi}/user/signin`, { id, password }, { withCredentials: true });
      if (!data.data) return;
      setLoading(false);

      const encryptCookie = encrypt(data.data);
      const option = { domain: process.env.NODE_ENV === 'development' ? '.xcast.co.kr' : 'xclass.co.kr', path: '/' };
      const expires = dayjs().add(6, 'h').toDate();

      setCookie('xclass-expires', expires, { ...option, expires });
      setExpires(CookieManager.get(`xclass-expires`));

      setCookie('xclass-access-token', data.data.token, { ...option, expires });
      setXToken(CookieManager.get(`xclass-access-token`));

      setCookie('XHOME', encryptCookie, { ...option, expires });
      setXHome(CookieManager.get(`XHOME`));

      setCookie('xclass-name', data.data.name, { ...option, expires });
      setXName(CookieManager.get(`xclass-name`));

      setCookie('xclass-marketing_status', data.data.marketing_status, { ...option, expires });
      setXMarketing(CookieManager.get(`xclass-marketing_status`));

      setInfoId(id);

      return data.data;
    } catch (e) {
      setLoading(false);
      if (e?.response?.data?.code === '4003') {
        alertShow(t('아이디 또는 비밀번호가 일치하지 않습니다.'), null);
        if (missLoginCount === 3) return;
        setMissLoginCount(missLoginCount + 1);
        return;
      }
      alertShow(e?.response?.data?.message_ko || t('로그인에 실패하였습니다. 다시 시도해주세요.'), null);
      return;
    }
  };

  const onLogin = async (id, password) => {
    try {
      const data = await login(id, password);
      if (!data) return;

      navigate('/');
    } catch (e) {}
  };

  return { login, onLogin };
}

export const useLogout = () => {
  const { t } = useTranslation();
  const { setXHome, setExpires, setXName, setXToken, setXMarketing } = loginStore();
  const { setInfoId, setInfoName, setInfoEmail, setInfoPhone } = infoStore();
  const { alertShow } = alertStore();
  const { setCardInfo } = cardStore();
  const { setSubscribeInfo, setNextPlanInfo, setTrafficInfo, setPaylog } = subscribeInfoStore();
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies([
    'xclass-access-token',
    'xclass-expires',
    'XHOME',
    'xclass-name',
    'xclass-marketing_status',
  ]);

  const reset = () => {
    navigate('/');
    const option = { domain: process.env.NODE_ENV === 'development' ? '.xcast.co.kr' : 'xclass.co.kr', path: '/' };
    removeCookie('XHOME', option);
    removeCookie('xclass-expires', option);
    removeCookie('xclass-name', option);
    removeCookie('xclass-access-token', option);
    removeCookie('xclass-marketing_status', option);
    setXHome('');
    setExpires('');
    setXName('');
    setXMarketing(false);
    setXToken('');

    setInfoId(null);
    setInfoName(null);
    setInfoEmail(null);
    setInfoPhone(null);

    setCardInfo(null);
    setSubscribeInfo(null);
    setNextPlanInfo(null);
    setTrafficInfo(null);
    setPaylog([]);
  };

  const logout = async () => {
    try {
      await axios.post(
        `${accountApi}/user/signout`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${CookieManager.get('xclass-access-token')}`,
          },
        },
      );
      reset();
    } catch (e) {
      reset();
      // alertShow(e?.response?.data?.message_ko || t('로그아웃에 실패했습니다.'));
      throw e;
    }
  };

  return { logout };
};
