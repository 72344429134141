const Agree = () => {
  return (
    <div className="WordSection1">
      <p
        className="MsoNormal"
        align="left"
        style={{ textAlign: "left", lineHeight: "121%" }}
      >
        <b>
          <span>마케팅 정보 활용 동의</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{ textAlign: "left", lineHeight: "121%" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{ marginBottom: "12.0pt", textAlign: "left" }}
      >
        &nbsp;
        <span>
          마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용은 거부하실 수
          있으며
        </span>
        , <span>거부 시에도 엑스클래스 서비스를 이용하실 수 있으나</span>,{" "}
        <span>
          동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다
        </span>
        .
      </p>
      <table
        className="MsoNormalTable"
        border={0}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          <tr style={{ height: "13.85pt" }}>
            <td
              width={188}
              style={{
                width: "140.95pt",
                border: "solid #DFE2E5 1.0pt",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "13.85pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{ textAlign: "center" }}
              >
                <span>개인정보 수집 항목</span>
              </p>
            </td>
            <td
              width={188}
              style={{
                width: "140.95pt",
                border: "solid #DFE2E5 1.0pt",
                borderLeft: "none",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "13.85pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{ textAlign: "center" }}
              >
                <span>개인정보 수집 이용 목적</span>
              </p>
            </td>
            <td
              width={188}
              style={{
                width: "140.95pt",
                border: "solid #DFE2E5 1.0pt",
                borderLeft: "none",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "13.85pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{ textAlign: "center" }}
              >
                <span>보유 및 이용기간</span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "65.25pt" }}>
            <td
              width={188}
              style={{
                width: "140.95pt",
                border: "solid #DFE2E5 1.0pt",
                borderTop: "none",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "65.25pt",
              }}
            >
              <p
                className="MsoNormal"
                align="left"
                style={{ textAlign: "left" }}
              >
                <span>이름</span>,<span>휴대폰번호</span>, <span>이메일</span>,{" "}
                <span>주소</span>
              </p>
            </td>
            <td
              width={188}
              style={{
                width: "140.95pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid #DFE2E5 1.0pt",
                borderRight: "solid #DFE2E5 1.0pt",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "65.25pt",
              }}
            >
              <p
                className="MsoNormal"
                align="left"
                style={{ textAlign: "left" }}
              >
                - <span>이벤트 운영 및 광고성 정보 전송</span>
                <br />- <span>서비스 관련 정보 전송</span>
              </p>
            </td>
            <td
              width={188}
              style={{
                width: "140.95pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid #DFE2E5 1.0pt",
                borderRight: "solid #DFE2E5 1.0pt",
                padding: "4.5pt 9.75pt 4.5pt 9.75pt",
                height: "65.25pt",
              }}
            >
              <p
                className="MsoNormal"
                align="left"
                style={{ textAlign: "left" }}
              >
                <span>이용자가 동의를 철회하거나</span>,{" "}
                <span>탈퇴시까지 보유</span>
                <span>•</span>
                <span>이용</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="MsoNormal">
        <span style={{ fontSize: "1.0pt", lineHeight: "103%" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="left"
        style={{ marginBottom: "12.0pt", textAlign: "left" }}
      >
        &nbsp;
      </p>
    </div>
  );
};

export default Agree;
