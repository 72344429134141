import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';

import { alertStore } from '../../stores/modal/alertStore';
import { confirmStore } from '../../stores/modal/confirmStore';
import { loginStore, useLogout } from '../../stores/user/loginStore';
import { infoStore, useInfo } from '../../stores/mypage/infoStore';
import { useSignUp, signUpStore } from '../../stores/user/signupStore';

import styles from '../../assets/scss/mypage/info.module.scss';

// img
import icCloseWrong from '../../assets/img/icons/ic_close_wrong.svg';
import icCheckRight from '../../assets/img/icons/ic_check_right.svg';

function FormInfo() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);
  const { logout } = useLogout();

  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const { xMarketing } = loginStore();
  const { onNicknameCheck, onCodeCheck, onAuth } = useSignUp();
  const { tryAuth, setTryAuth } = signUpStore();
  const { infoName, infoEmail, infoPhone } = infoStore();
  const { getUserInfo, editInfo } = useInfo();

  const [email, setEmail] = useState(infoEmail || '');
  const [emailCheck, setEmailCheck] = useState('');
  const [emailCheck2, setEmailCheck2] = useState(false);
  const [emailCheckState, setEmailCheckState] = useState(false);
  const changeEmail = e => {
    setEmail(e.target.value);
    setTryAuth(false);
    setCode('');

    if (e.target.value === infoEmail) {
      setEmailCheck('');
      setEmailCheck2(false);
      setEmailCheckState(false);
      return;
    }

    setEmailCheckState(e.target.value.length > 0);
    setEmailCheck2(false);
    setEmailCheck(
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(e.target.value),
    );
  };
  const [editableEmail, setEditableEmail] = useState(false);
  const onEditableEmail = () => {
    if (!editableEmail) {
      setEmail(infoEmail || '');
      setEditableEmail(true);
    }
  };

  const onClickEmailCheck = () => {
    onAuth({ email });
  };

  const [code, setCode] = useState(''); // email code
  const onClickCodeCheck = async () => {
    const v = await onCodeCheck({ email, code });
    setEmailCheck2(v);
  };

  const [phone, setPhone] = useState(infoPhone || '');
  const [editablePhone, setEditablePhone] = useState(false);
  const onEditablePhone = () => {
    if (!editablePhone) {
      setPhone(infoPhone || '');
      setEditablePhone(true);
    }
  };

  const [nicknameCheckState, setNicknameCheckState] = useState(false);
  const [nickname, setNickname] = useState(infoName || '');
  const [nameLengthCheck, setNameLengthCheck] = useState('pass');
  const [nicknameCheck1, setNicknameCheck1] = useState(false);
  const [nicknameCheck2, setNicknameCheck2] = useState(true);
  const [editableName, setEditableName] = useState(false);
  const onEditableName = () => {
    if (!editableName) {
      setNickname(infoName || '');
      setEditableName(true);
    }
  };

  const changeNickname = e => {
    const minByte = 4;
    const maxByte = 16;
    const inputValue = e.target.value;
    const inputValueLength = e.target.value.length;

    let totalByte = 0;
    for (let i = 0; i < inputValueLength; i++) {
      const eachChar = inputValue.charAt(i);
      const uniChar = escape(eachChar);
      if (uniChar.length > 4) {
        totalByte += 2;
      } else {
        totalByte += 1;
      }
    }

    if (totalByte < minByte) {
      setNameLengthCheck('short');
    } else if (totalByte > maxByte) {
      setNameLengthCheck('long');
    } else {
      setNameLengthCheck('pass');
    }

    setNickname(e.target.value);
    if (e.target.value === infoName) {
      setNicknameCheck1(false);
      setNicknameCheck2(true);
      setNicknameCheckState(false);
      return;
    }
    setNicknameCheck1(!/([^_가-힣0-9a-zA-Z\x20])/i.test(e.target.value) && !e.target.value.includes(' '));
    setNicknameCheck2(false);
    setNicknameCheckState(true);
  };

  const onClickNicknameCheck = async () => {
    if (!nicknameCheck1 || nicknameCheck2 || nameLengthCheck !== 'pass') return;
    const v = await onNicknameCheck(nickname);
    setNicknameCheck2(v);
  };

  useEffect(() => {
    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    getUserInfo();
    // eslint-disable-next-line consistent-return
    return () => {
      setNickname('');
      setEmail('');
      setPhone('');
      setNicknameCheckState(false);
      setNameLengthCheck('pass');
      setNicknameCheck1(false);
      setNicknameCheck2(true);
      setEmailCheck('');
      setEmailCheck2(false);
      setEmailCheckState(false);
      setCode('');
      setTryAuth(false);
    };
  }, []);

  const onSubmit = () => {
    if (editableName) {
      if (!nickname) {
        alertShow(t('닉네임을 입력해주세요.'), null);
        return;
      }
      if (nameLengthCheck !== 'pass') {
        alertShow(t('적절한 닉네임이 사용되었는지 확인해주세요.'), null);
        return;
      }
      if (!nicknameCheck2) {
        // !nicknameCheck1 ||
        alertShow(t('닉네임 중복확인을 해주세요'), null);
        return;
      }
    }
    if (editableEmail && !email) {
      alertShow(t('이메일을 입력해주세요.'), null);
      return;
    }
    if (emailCheckState && !emailCheck) {
      alertShow(t('올바른 이메일주소를 입력해주세요.'), null);
      return;
    }
    if (emailCheckState && email !== infoEmail && !emailCheck2) {
      alertShow(t('이메일 인증을 완료해 주세요.'), null);
      return;
    }
    if (editablePhone && !phone) {
      alertShow(t('연락처를 입력해주세요.'), null);
      return;
    }
    confirmShow(t('수정하시겠습니까?'), async () => {
      await editInfo(
        editableName ? nickname : infoName,
        editablePhone ? phone : infoPhone,
        editableEmail ? email : infoEmail,
        null,
        null,
        null,
        xMarketing,
        null,
        true,
      );
      setNicknameCheckState(false);
      setNameLengthCheck('pass');
      setNicknameCheck1(false);
      setNicknameCheck2(true);

      setEmailCheckState(false);
      setEmailCheck('');
      setEmailCheck2(false);
      setTryAuth(false);
      setCode('');

      setEditableName(false);
      setEditableEmail(false);
      setEditablePhone(false);
    });
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('닉네임')}</span>
          </div>
          <div className={classNames(styles.input, styles.isCheck)}>
            <input
              type="text"
              maxLength={24}
              placeholder={t('닉네임 입력') || ''}
              value={!editableName ? infoName || '' : nickname}
              onChange={changeNickname}
              onClick={onEditableName}
            />
            <div
              className={`${styles.check} ${nicknameCheck2 ? styles.checked : ''} ${
                nameLengthCheck !== 'pass' || !nicknameCheck1 ? styles.disabled : styles.active
              }`}
              onClick={onClickNicknameCheck}
            >
              {t('중복확인')}
            </div>
          </div>
          {nicknameCheckState && (
            <div className={styles.guide}>
              {(nameLengthCheck === 'short' || nameLengthCheck === 'long') && (
                <div className={styles.guideItem}>
                  <img src={icCloseWrong} alt="icon" className={styles.wrong} />
                  <span>{t(`닉네임의 길이가 너무 ${nameLengthCheck === 'short' ? '짧습니다.' : '깁니다.'}`)}</span>
                </div>
              )}
              {!nicknameCheck1 && (
                <div className={`${styles.guideItem}${nicknameCheck1 ? styles.active : ''}`}>
                  {nickname.length === 0 && <img src={icCloseWrong} alt="icon" className={styles.wrong} />}
                  {nickname.length > 0 && (
                    <img
                      src={nicknameCheck1 ? icCheckRight : icCloseWrong}
                      alt="icon"
                      className={`${nicknameCheck1 ? styles.right : styles.wrong}`}
                    />
                  )}
                  <span>{t('한글 자음/모음, 특수문자, 공백 사용 불가능')}</span>
                </div>
              )}
              {!nicknameCheck2 && (
                <div className={styles.guideItem}>
                  <img src={icCloseWrong} alt="icon" className={styles.wrong} />
                  <span>{t('닉네임 중복확인')}</span>
                </div>
              )}
              {nicknameCheck1 && nicknameCheck2 && (
                <div className={classNames(styles.guideItem, styles.active)}>
                  <img src={icCheckRight} alt="icon" className={styles.right} />
                  <span>{t('사용 가능한 닉네임 입니다.')}</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('이메일')}</span>
          </div>
          <div className={classNames(styles.input, styles.isCheck)}>
            <input
              onClick={onEditableEmail}
              type="text"
              placeholder={t('이메일 입력') || ''}
              value={!editableEmail ? infoEmail || '' : email}
              onChange={changeEmail}
            />
            <div
              className={classNames(
                styles.check,
                tryAuth && styles.checked,
                !emailCheck ? styles.disabled : styles.active,
              )}
              onClick={onClickEmailCheck}
            >
              {t('본인인증')}
            </div>
          </div>
          {emailCheckState && (
            <div className={styles.guide}>
              {!emailCheck && (
                <div className={styles.guideItem}>
                  <img src={icCloseWrong} alt="icon" className={styles.wrong} />
                  <span>{t('올바른 이메일 주소를 입력하세요.')}</span>
                </div>
              )}
              {!tryAuth && (
                <div className={styles.guideItem}>
                  <img src={icCloseWrong} alt="icon" className={styles.wrong} />
                  <span>{t('이메일 인증')}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {tryAuth && (
          <div className={styles.item}>
            <div className={styles.subject}>
              <span>{t('이메일 인증')}</span>
            </div>
            <div className={classNames(styles.input, styles.isCheck)}>
              <input
                onClick={onEditableEmail}
                type="text"
                placeholder={t('인증 코드 입력')}
                value={code}
                onChange={e => setCode(e.target.value)}
              />
              <div
                className={classNames(
                  styles.check,
                  emailCheck2 && styles.checked,
                  !code ? styles.disabled : styles.active,
                )}
                onClick={onClickCodeCheck}
              >
                {t('인증하기')}
              </div>
            </div>

            <div className={styles.guide}>
              {!emailCheck2 ? (
                <div className={styles.guideItem}>
                  <img src={icCloseWrong} alt="icon" className={styles.wrong} />
                  <span>{t('이메일 인증을 완료해 주세요.')}</span>
                </div>
              ) : (
                <div className={classNames(styles.guideItem, styles.active)}>
                  <img src={icCheckRight} alt="icon" className={styles.wrong} />
                  <span>{t('이메일 인증이 완료되었습니다.')}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('연락처')}</span>
          </div>
          <div className={styles.input}>
            <input
              type="text"
              maxLength={16}
              placeholder={t('연락처') || ''}
              onClick={onEditablePhone}
              value={!editablePhone ? infoPhone || '' : phone}
              onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
            />
          </div>
        </div>
      </div>

      <div className={styles.btns}>
        <button type="button" className={styles.btn} onClick={onSubmit}>
          {t('수정')}
        </button>
      </div>
    </>
  );
}

export default FormInfo;
