import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useNotice, noticeStore } from '../../stores/notice/noticeStore';

import Pagination from '../layout/Pagination';

import styles from '../../assets/scss/notice/notice.module.scss';

export default function Notice() {
  const navigate = useNavigate();
  const { noticeList } = noticeStore();
  const { getNoticeList } = useNotice();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  useEffect(() => {
    getNoticeList(page);
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {noticeList?.length > 0 ? (
          noticeList.map(v => (
            <div key={v.cn_key} className={styles.item} onClick={() => navigate(`/notice/detail/${v.cn_key}`)}>
              <div className={styles.title}>{v.cn_title}</div>
              <div className={styles.date}>{dayjs(v.created_at).format('YYYY-MM-DD')}</div>
            </div>
          ))
        ) : (
          <div className={styles.noData}>{t('공지사항이 없습니다.')}</div>
        )}
      </div>
      <Pagination total={noticeList?.length || 0} movePage={e => setPage(e)} />
    </div>
  );
}
