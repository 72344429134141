import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import app_hero_bg from '../../assets/public_img/app-hero-bg.jpg';
import logo from '../../assets/public_img/logo-white-xclass.svg';

import { inquiryStore } from '../../stores/inquiry/inquiryStore';

function ContactCompletePage() {
  const { t } = useTranslation();
  const { setComplete } = inquiryStore();

  useEffect(() => {
    return () => setComplete(false);
  }, []);
  return (
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
        background: `url(${app_hero_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-lg-7">
            <div className="error-content text-center text-white">
              <div className="m-auto" style={{ width: '300px' }}>
                <img src={logo} alt="logo" className="w-100" />
              </div>
              <h3 className="text-white mt-3 mb-3">{t('문의가 완료되었습니다.')}</h3>
              <div>{t('확인 후 빠른 시일 내로 이메일을 통해 연락드리겠습니다.')}</div>
              <div className="mb-4">{t('엑스클래스와 함께 기업의 목표를 달성하고 비즈니스를 발전시켜요!')}</div>
              <Link className="btn app-store-btn" to="/">
                {t('홈페이지로 돌아가기')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactCompletePage;
