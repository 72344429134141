import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { loginStore } from '../../stores/user/loginStore';

import FormInfo from './FormInfo';
import FormPw from './FormPw';

import styles from '../../assets/scss/mypage/info.module.scss';

// img
import icAccount from '../../assets/img/icons/ic_account.svg';

function Info() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { xName } = loginStore();
  const { type } = useParams();

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.icon}>
          <img src={icAccount} alt="info" />
        </div>
        <div className={styles.header}>
          <span>{xName}</span> {t('고객님 안녕하세요!')}
          <div className={styles.link}>
            <div
              className={classNames(styles.item, type === 'basic' && styles.active)}
              onClick={() => navigate('/mypage/info/basic')}
            >
              {t('내 정보 변경')}
            </div>
            <div
              className={classNames(styles.item, type === 'password' && styles.active)}
              onClick={() => navigate('/mypage/info/password')}
            >
              {t('비밀번호 변경')}
            </div>
          </div>
        </div>
        <form>{type === 'basic' ? <FormInfo /> : <FormPw />}</form>
      </div>
      <div className={styles.withdraw}>
        <div onClick={() => navigate('/mypage/withdrawal')}>{t('회원 탈퇴')}</div>
      </div>
    </div>
  );
}

export default Info;
