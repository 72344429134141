/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { confirmStore } from '../../../stores/modal/confirmStore';
import { loginStore, useLogout } from '../../../stores/user/loginStore';
import { usePay } from '../../../stores/pay/subscribeStore';
import { subscribeInfoStore, useSubscribeInfo } from '../../../stores/mypage/subscribeInfoStore';

import NoSubscribe from '../NoSubscribe';
import NowForm from './NowForm';
import NextForm from './NextForm';

import styles from '../../../assets/scss/mypage/subscribe.module.scss';

import icSubscribe from '../../../assets/img/icons/ic_subscribe.svg';

function Subscribe() {
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);

  const { t } = useTranslation();
  const { confirmShow } = confirmStore();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const { xName } = loginStore();

  const { getPriceList } = usePay();
  const {
    subscribeInfo,
    nextPlanInfo,
    showCodeSubmit,
    setShowCodeSubmit,
    tab,
    setTab,
    setNextPlanInfo,
    setSubscribeInfo,
  } = subscribeInfoStore();
  const { getSubscribeInfo, resetPw, getNextPlan } = useSubscribeInfo();

  const onResetPw = () => {
    confirmShow(t('관리자 비밀번호를 초기화하시겠습니까?'), () => resetPw());
  };

  useEffect(() => {
    const fetchData = async () => {
      await getPriceList();
      await getSubscribeInfo();
      await getNextPlan();
    };

    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    fetchData();

    // eslint-disable-next-line consistent-return
    return () => {
      setTab('now');
      setSubscribeInfo(null);
      setNextPlanInfo(null);
    };
  }, []);

  useEffect(() => {
    if (subscribeInfo) {
      if (!subscribeInfo.subscribe?.sb_customer_code && subscribeInfo.subscribe?.sb_enable && !showCodeSubmit) {
        setShowCodeSubmit(true);
        confirmShow(
          t(
            '기업 정보 등록 후에 엑스클래스의 모든 기능을 사용하실 수 있습니다. 기업 정보 등록 페이지로 이동하시겠습니까?',
          ),
          () => {
            navigate('/mypage/account');
          },
        );
      }
    }
  }, [subscribeInfo]);

  return (
    <div className={styles.container}>
      {subscribeInfo ? (
        <div className={styles.subscribe}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img src={icSubscribe} alt="info" />
            </div>
            <div className={styles.header}>
              {nextPlanInfo ? (
                <>
                  <span>{xName}</span> {t('고객님의 구독정보입니다!')}
                  <div className={styles.link}>
                    <div
                      className={classNames(styles.linkItem, tab === 'now' && styles.active)}
                      onClick={() => setTab('now')}
                    >
                      {t('현재 구독정보')}
                    </div>
                    <div
                      className={classNames(styles.linkItem, tab === 'next' && styles.active)}
                      onClick={() => setTab('next')}
                    >
                      {t('다음 구독정보')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <span>{xName}</span> {t('고객님의 구독정보입니다!')}
                </>
              )}
            </div>
            {tab === 'now' ? <NowForm /> : <NextForm />}
          </div>
          {subscribeInfo.subscribe.sb_customer_code && subscribeInfo.subscribe?.sb_enable && (
            <div className={classNames(styles.btns)}>
              <div onClick={onResetPw}>{t('관리자 비밀번호 초기화')}</div>
            </div>
          )}
        </div>
      ) : (
        <NoSubscribe title={t('구독 정보가 없습니다.')} subtitle={t('엑스클래스를 구독하시려면?')} link="/pay" />
      )}
    </div>
  );
}

export default Subscribe;
