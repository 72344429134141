import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../stores/user/loginStore';
import { alertStore } from '../../stores/modal/alertStore';

import '../../assets/scss/customForm.scss';

import logo from '../../assets/img/logo.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';
import here_bg from '../../assets/public_img/hero-bg-1.jpg';
import here_bg_shape from '../../assets/public_img/hero-bg-shape-1.svg';

export default function LoginScreen() {
  const { t } = useTranslation();
  const { onLogin } = useLogin();
  const { alertShow } = alertStore();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [pwView, setPwView] = useState(false);

  const pwRef = useRef();

  const onKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 13) onClickLogin();
  };

  const onClickLogin = () => {
    pwRef.current?.blur();
    if (!id) {
      alertShow(t('아이디를 입력해주세요.'), null);
      return;
    }
    if (!pw) {
      alertShow(t('비밀번호를 입력해주세요.'), null);
      return;
    }
    onLogin(id, pw);
  };
  return (
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
        background: `url(${here_bg})no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
          <div className="col-md-7 col-lg-6 customFormTitle">
            <div className="hero-content-left text-white">
              <h1 className="text-white">{t('환영합니다!')}</h1>
              <p className="lead">{t('엑스클래스에 로그인하고, 다양한 기능을 이용해 보세요!')}</p>
            </div>
          </div>
          <div className="col-md-5 col-lg-5 customForm">
            <div className="card login-signup-card shadow-lg mb-0">
              <div className="card-body px-md-5 py-5">
                <div className="mb-5 text-center">
                  <Link to="/login">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>

                <form className="login-signup-form">
                  <div className="form-group">
                    <label className="pb-1">{t('아이디')}</label>
                    <div className="input-group input-group-merge">
                      <input
                        type="id"
                        className="form-control"
                        placeholder={t('아이디 입력')}
                        value={id}
                        onChange={e => setId(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label className="pb-1">{t('비밀번호')}</label>
                      </div>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type={pwView ? 'text' : 'password'}
                        className="form-control"
                        placeholder={t('비밀번호 입력')}
                        autoComplete="off"
                        value={pw}
                        onChange={e => setPw(e.target.value)}
                        onKeyDown={onKeyDown}
                        ref={pwRef}
                      />
                      <img
                        src={pwView ? icVisibilityOff : icVisibility}
                        alt="visibility"
                        onClick={() => setPwView(prev => !prev)}
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    onClick={onClickLogin}
                  >
                    {t('로그인')}
                  </button>

                  <div className="links">
                    <Link to="/signup" className="small">
                      {t('회원가입')}
                    </Link>
                    <div className="find content">
                      <Link to="/find/id" className="form-text small ">
                        {t('아이디 찾기')}
                      </Link>
                      <Link to="/find/pw" className="form-text small ">
                        {t('비밀번호 찾기')}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-img-absolute">
        <img src={here_bg_shape} alt="wave shape" className="img-fluid" />
      </div>
    </section>
  );
}
