import { create } from "zustand";

export const loadingStore = create((set, get) => ({
  loading: false,

  setLoading: (v) =>
    set({
      loading: v,
    }),
}));
