import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import styles from '../../assets/scss/mypage/index.module.scss';

import icError from '../../assets/img/icons/ic_error_outline.svg';

export default function NoSubscribe({ title, subtitle, link }) {
  const { t } = useTranslation();
  return (
    <div className={styles.noSubscribe}>
      <img src={icError} alt="img" />
      <div className={styles.title}>{title}</div>
      <HashLink smooth to={link}>
        <div className={styles.link}>{subtitle}</div>
      </HashLink>
    </div>
  );
}
