import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import app_here_bg from '../../assets/public_img/app-hero-bg.jpg';
import app_hand_top from '../../assets/public_img/app-hand-top.png';

import { TfiAndroid, TfiApple } from 'react-icons/tfi';

function Cta() {
  const { t } = useTranslation();
  return (
    <section
      className="download-section pt-100 background-img"
      style={{
        background: `url(${app_here_bg}) no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-7">
            <div className="download-content text-white pb-100">
              <h2 className="text-white">{t('엑스클래스, 이제 모바일로 만나보세요.')}</h2>
              <p className="lead">
                {t(
                  'PC와 모바일 웹 브라우저에 이어 이제 모바일 앱으로 만나보세요. 중요한 일정 알림이나 회의 참석, 업무 공유 등 더욱 편리하게 할 수 있습니다. 모바일 앱만의 특별한 기능을 놓치지 마세요.',
                )}
              </p>
              {/* <div className="download-btn">
                <Link to="/" className="btn google-play-btn mr-3">
                  <span>
                    <TfiAndroid />
                  </span>{' '}
                  Google Play
                </Link>
                <Link to="/" className="btn app-store-btn">
                  <span>
                    <TfiApple />
                  </span>{' '}
                  App Store
                </Link>
              </div> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="download-img d-flex align-items-end">
              <img src={app_hand_top} alt="download" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
