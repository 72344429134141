/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { alertStore } from '../../stores/modal/alertStore';
import { confirmStore } from '../../stores/modal/confirmStore';
import { loginStore, useLogout } from '../../stores/user/loginStore';
import { accountStore, useAccount } from '../../stores/mypage/accountStore';
import { subscribeInfoStore, useSubscribeInfo } from '../../stores/mypage/subscribeInfoStore';

import Address from '../../modal/customer/address';

import styles from '../../assets/scss/mypage/account.module.scss';

import icSetting from '../../assets/img/icons/ic_setting.svg';

function Account() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { alertShow } = alertStore();
  const { confirmShow } = confirmStore();
  const { getSubscribeInfo } = useSubscribeInfo();
  const { subscribeInfo } = subscribeInfoStore();
  const { logout } = useLogout();
  const [cookies] = useCookies(['xclass-access-token', 'XHOME']);

  const {
    code,
    setCode,
    codeCheck,
    setCodeCheck,
    name,
    setName,
    address,
    setAddress,
    setAddressState,
    adminName,
    setAdminName,
    adminEmail,
    setAdminEmail,
    adminPhone,
    setAdminPhone,
  } = accountStore();
  const { onCheckCode, onCustomerMake, reset } = useAccount();

  const { xName } = loginStore();

  useEffect(() => {
    const fetchData = async () => {
      await getSubscribeInfo();
    };

    if (!cookies['xclass-access-token'] || !cookies.XHOME) {
      logout();
      return;
    }
    fetchData();

    // eslint-disable-next-line consistent-return
    return () => reset();
  }, []);

  useEffect(() => {
    if (subscribeInfo?.subscribe?.sb_customer_code) {
      // TODO 리다이렉트 처리 깔끔하게,,,
      // window.location.replace('/mypage/subscribe');
      // window.location.href = '/mypage/subscribe';
      alertShow(t('이미 기업계정 등록이 완료되었습니다.\n구독 정보 페이지로 이동합니다.'), () =>
        navigate('/mypage/subscribe'),
      );
    }
  }, [subscribeInfo]);

  const onClickCheckCode = () => {
    const codeReg = /^[A-Z0-9]*$/; // 공백 없이 대문자 및 숫자만
    if (!codeReg.test(code)) {
      alertShow(t('기업코드는 공백 없이 영문 대문자와 숫자만 사용할 수 있습니다.'));
      return;
    }
    onCheckCode(code);
  };

  const onClickCustomerMake = () => {
    if (!code) {
      alertShow(t('기업코드를 입력해주세요.'));
      return;
    }
    if (!codeCheck) {
      alertShow(t('기업코드 중복확인을 해주세요.'));
      return;
    }

    if (!name) {
      alertShow(t('기업명을 입력해주세요.'));
      return;
    }
    if (!adminName) {
      alertShow(t('이름을 입력해주세요.'));
      return;
    }

    if (!adminEmail) {
      alertShow(t('이메일을 입력해주세요.'));
      return;
    }
    const emailReg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!emailReg.test(adminEmail)) {
      alertShow(t('올바른 이메일 주소를 입력해주세요.'));
      return;
    }

    if (!adminPhone) {
      alertShow(t('연락처를 입력해주세요.'));
      return;
    }
    const phoneReg = /^[0-9]/;
    if (!phoneReg.test(adminPhone)) {
      alertShow(t('올바른 연락처를 입력해주세요.'));
      return;
    }

    const data = {
      code,
      name,
      address: {
        postCode: address.postCode,
        address: address.address,
        addressDetail: address.addressDetail || '',
      },
      adminName,
      adminEmail,
      adminPhone,
    };
    confirmShow(t('기업코드는 한번 등록하면 변경이 불가능합니다.\n등록하시겠습니까?'), () => onCustomerMake(data));
  };

  return (
    <div className={styles.container}>
      <div className={styles.account}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={icSetting} alt="info" />
          </div>
          <div className={styles.title}>
            <span>{xName}</span> {t('고객님의 기업계정을 등록하세요!')}
          </div>
          <div className={styles.plan}>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>
                  {t('기업코드')} <span className={styles.required}>*</span>
                </span>
              </div>
              <div className={classNames(styles.input, styles.isCheck)}>
                <input
                  type="text"
                  maxLength={24}
                  placeholder={t('기업코드 입력')}
                  value={code}
                  onChange={e => {
                    setCode(e.target.value.toUpperCase());
                    setCodeCheck(false);
                  }}
                />
                <div
                  className={classNames(
                    styles.check,
                    codeCheck && styles.checked,
                    !code ? styles.disabled : styles.active,
                  )}
                  onClick={onClickCheckCode}
                >
                  {t('중복확인')}
                </div>
              </div>
            </div>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>
                  {t('기업명')} <span className={styles.required}>*</span>
                </span>
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder={t('기업명 입력')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>
                  {t('성함')} <span className={styles.required}>*</span>
                </span>
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder={t('성함 입력')}
                  value={adminName}
                  onChange={e => setAdminName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>
                  {t('이메일')}
                  <span className={styles.required}>*</span>
                </span>
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder={t('이메일 입력')}
                  value={adminEmail}
                  onChange={e => setAdminEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>
                  {t('연락처')}
                  <span className={styles.required}>*</span>
                </span>
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder={t('연락처 입력')}
                  value={adminPhone}
                  onChange={e => setAdminPhone(e.target.value.replace(/[^0-9]/g, ''))}
                />
              </div>
            </div>
            <div className={styles.planItem}>
              <div className={styles.subject}>
                <span>{t('주소')}</span>
              </div>
              <div className={styles.address}>
                <div className={styles.input}>
                  <input
                    type="text"
                    placeholder={t('우편번호')}
                    readOnly
                    value={address.postCode}
                    onClick={() => setAddressState(true)}
                  />
                </div>
                <div className={styles.input}>
                  <input
                    type="text"
                    placeholder={t('주소')}
                    readOnly
                    value={address.address}
                    onClick={() => setAddressState(true)}
                  />
                </div>
                <div className={styles.input}>
                  <input
                    type="text"
                    placeholder={t('상세주소')}
                    value={address.addressDetail}
                    onChange={e => setAddress({ ...address, addressDetail: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.btn} onClick={onClickCustomerMake}>
            {t('등록')}
          </div>
        </div>
      </div>
      <Address />
    </div>
  );
}

export default Account;
