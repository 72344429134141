import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { alertStore } from '../stores/modal/alertStore';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

import icAlert from '../assets/img/icons/ic_alert.svg';

function Alert() {
  const { t } = useTranslation();
  const { state, alertClose, message, messageList } = alertStore();

  return (
    <AnimatePresence>
      {state && (
        <Modal close={() => alertClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={classNames(styles.modal, styles.lang)}
          >
            <div className={styles.circle}>
              <img src={icAlert} alt="img" />
            </div>
            {messageList.length === 0 ? (
              // eslint-disable-next-line react/no-danger
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }}></div>
            ) : (
              <div
                className={styles.message}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messageList
                    .map(item => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const obj = {};
                      if (item.messageKey && item.messageVar) {
                        obj[item.messageKey] = item.messageVar;
                        return t(item.message, obj);
                      }
                      return t(item.message);
                    })
                    .join('\n'),
                }}
              ></div>
            )}
            <div className={styles.btns}>
              <div className={styles.btn} onClick={() => alertClose()}>
                {t('확인')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Alert;
